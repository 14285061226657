export default {
  new(data) {
    if (data && data.elements) {
      const configResult = {};

      data.elements.forEach((element) => {
        if (element.name == "minInstallment") {
          element.value = element.value ? parseFloat(element.value) : null;
        }
        configResult[element.name] = element.value;
      });

      this.config = configResult;

      this.rates = data.rates.filter((item) => item.rate);
      this.rates = this.rates.map((item) => {
        if (typeof item.rate == "string") {
          if (!isNaN(Number(item.rate))) {
            item.rate = Number(item.rate);
          } else {
            item.rate = "-";
          }
        }
        return item;
      });
      // .map((item) => {
      //   const result = {};
      //   result[item.installment] = item.rate;

      //   return result;
      // });

      this.extra_installment = data.installment;
      this.is_active = data.is_active === true ? 1 : 0;
      this.is_test = data.is_test === true ? 1 : 0;
      this.is_default = data.is_default === true ? 1 : 0;
      this.use_installment = data.use_installment === true ? 1 : 0;
      this.min_installment_amount =
        (data.use_installment && data.min_installment_amount) || null;
    }
    return this;
  },
};
