import api from "../../shared/api";

export default {
  namespaced: true,
  actions: {
    upload({ state }, data) {
      let formData = new FormData();
      formData.append("upload", data.file);
      // formData.append("name", "logo"); logo-logo olmaması için kaldırıldı.

      api.post(
        "upload/logo",
        formData,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
