import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    countries: [],
    cities: [],
    districts: [],
    neighborhoods: [],
  }),
  mutations: {
    clearCountries(state) {
      state.countries = [];
      state.cities = [];
      state.districts = [];
      state.neighborhoods = [];
    },
    clearCities(state) {
      state.cities = [];
      state.districts = [];
      state.neighborhoods = [];
    },
    clearDistricts(state) {
      state.districts = [];
      state.neighborhoods = [];
    },
    clearNeighborhoods(state) {
      state.neighborhoods = [];
    },
  },
  actions: {
    getCountries({ state }, data) {
      api.get(
        "region/countries",
        (result) => {
          state.countries = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCities({ state }, data) {
      const countryId = data.id;
      if (countryId) {
        api.get(
          "region/cities/" + countryId,
          (result) => {
            state.cities = result.data.items;
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getDistricts({ state }, data) {
      const cityId = data.id;
      if (cityId) {
        api.get(
          "region/districts/" + cityId,
          (result) => {
            state.districts = result.data.items;
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getNeighborhoods({ state }, data) {
      const districtId = data.id;
      if (districtId) {
        api.get(
          "region/neighborhoods/" + districtId,
          (result) => {
            state.neighborhoods = result.data.items;
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    createAddress({ state }, data) {
      api.post(
        "contacts",
        state.newAddress,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
