export default {
  new(data) {
    let result = {};

    if (data && data.elements) {
      data.elements.map((element) => {
        if(element.form == 'select') {
          result[element.name] = element.value.id ?? element.value;
        } else {
          result[element.name] = element.value;
        }
      });
    }
    return result;
  },
};
