export default {
  new(form) {
    let result = {};
    if (form.name) {
      result.name = form.name;
    }
    if (form.rootElement) {
      result.rootElement =  form.rootElement
    }
    if (form.address) {
      result.address = form.address;
    }

    if (form.template) {
      result.template = JSON.stringify(form.template);
    }

    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    result = { ...result };
    return result;
  },
  image: null,
};
