import api from "../../shared/api";

export default {
  namespaced: true,
  state: () => ({
    // Detail TAB
    detail: {
      id: null,
      order_no: null,
      payment_type: null,
      ref_no: null,

      gift: {
        wrap: false,
        note: null,
      },

      coupon: {
        code: null,
        discount: 0,
      },

      campaign: {
        code: null,
        discount: 0,
      },

      products: [],

      price: {
        total: 0,
        sub_total: 0,
        tax: 0,
        cargo: 0,
        last_total: 0,
        currency_code: "TRY",
      },
      
      // Attachment
      attachment: {
        delivery_date: null,
        delivery_start_time: null,
        delivery_due_time: null,
        note_text: null,
        extra_note: null,
        note_paper: null,
        image: null,
      },
    },


    // Invoice
    invoice: {
      country: null,
      city: null,
      district: null,
      neighborhood: null,
      postal_code: null,
      address: null,
    },

    // Shipment
    shipment: {
      country: null,
      city: null,
      district: null,
      neighborhood: null,
      postal_code: null,
      address: null,
    },

    // ERP
    erp: {
      status: null,
      message: null,
      order_type: null,
    },

    // Bank Result
    bank_result: {
      data: null,
      bank: null,
    },

    // Cargo Firm
    cargo: {
      id: null,
      is_insurance: false,
      is_integration: false,
      name: null,
      cargo_barcode_url: null,
      cargo_tracking_number: null,
      cargo_barcode_number: null
    },

    // Customer
    customer: {
      id: 1, // Static
      type: 0, // Static
      name: "",
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      gsm: null,
      tcn: null,
      vkn: null,
      tax_office: null,
      company: null,
    },

    member: null
  }),
  mutations: {
    setOrderDetail(state, item) {
      const currencyCode = item.currency_code ? item.currency_code.code : "TRY";
      const attachmentData = item.attachment;

      // Detail TAB
      state.detail = {
        id: item.id,
        order_no: item.order_no,
        payment_type: item.payment_type,
        ref_no: item.ref_no,

        gift: {
          wrap: item.gift_wrap,
          note: item.gift_note,
        },

        coupon: {
          code: item.coupon_code || null,
          discount: item.coupon_discount,
        },

        campaign: {
          code: item.campaign_code || null,
          discount: item.campaign_discount,
        },

        products: item.products,

        price: {
          total: localization.getMoneyFormat(currencyCode, item.total_price),
          sub_total: localization.getMoneyFormat(currencyCode, parseFloat(item.total_price) + parseFloat(item.total_tax)),
          tax: localization.getMoneyFormat(currencyCode, item.total_tax || 0),
          cargo: localization.getMoneyFormat(
            currencyCode,
            item.total_cargo || 0
          ),
          last_total: localization.getMoneyFormat(
            currencyCode,
            item.total_amount || 0
          ),
          currency_code: currencyCode,
        },

      };

      if(attachmentData) {
        state.detail.attachment = {
          delivery_date: attachmentData.delivery_date ? new Date(attachmentData.delivery_date) : null,
          delivery_start_time: attachmentData.delivery_start_time,
          delivery_due_time: attachmentData.delivery_due_time,
          note_text: attachmentData.note_text,
          extra_note: attachmentData.extra_note,
          note_paper: attachmentData.note_paper,
          image: attachmentData.image && attachmentData.image.path ? attachmentData.image.path : null,
        }
      }

      // Invoice
      state.invoice = {
        country: item.invoice_country ? item.invoice_country.name : null,
        city: item.invoice_city ? item.invoice_city.name : null,
        district: item.invoice_district ? item.invoice_district.name : null,
        neighborhood: item.invoice_neighborhood
          ? item.invoice_neighborhood.name
          : null,
        postal_code: item.invoice_postal_code,
        address: item.invoice_address,
      };

      // Shipment
      state.shipment = {
        country: item.shipment_country ? item.shipment_country.name : null,
        city: item.shipment_city ? item.shipment_city.name : null,
        district: item.shipment_district ? item.shipment_district.name : null,
        neighborhood: item.shipment_neighborhood
          ? item.shipment_neighborhood.name
          : null,
        postal_code: item.shipment_postal_code,
        address: item.shipment_address,
      };

      // ERP
      state.erp = {
        status: item.erp_status,
        message: item.erp_message,
        order_type: item.order_type,
      };

      // Bank Result
      if (item.payment_bank) {
        state.bank_result = {
          data: item.bank_result ? JSON.parse(item.bank_result) : null,
          bank: item.payment_bank.name,
        };
      }

      // Cargo Firm
      if (item.cargo) {
        state.cargo = {
          id: item.cargo.id,
          is_insurance: item.cargo.is_insurance,
          is_integration: item.cargo.is_integration,
          name: item.cargo.name,
          cargo_barcode_url: item.cargo_barcode_url,
          cargo_tracking_number: item.cargo_tracking_number,
          cargo_barcode_number: item.cargo_barcode_number
        };
      }

      // Customer
      state.customer = {
        id: 1, // Static
        type: 0, // Static
        name: item.invoice_first_name
          ? item.invoice_first_name + " " + item.invoice_last_name
          : "",
        first_name: item.invoice_first_name,
        last_name: item.invoice_last_name,
        email: item.invoice_email,
        phone:
          item.invoice_phone && item.invoice_phone ? item.invoice_phone : null,
        gsm:
          item.invoice_gsm && item.invoice_gsm.length ? item.invoice_gsm : null,
        tcn:
          item.invoice_tcn && item.invoice_tcn.length ? item.invoice_tcn : null,
        vkn:
          item.invoice_vkn && item.invoice_vkn.length ? item.invoice_vkn : null,
        tax_office: item.invoice_tax_office,
        company: item.invoice_company,
      };

      state.member = item.customer;
    },
    clearOrder(state) {
      // Detail TAB
      state.detail = {
        id: null,
        order_no: null,
        payment_type: null,
        ref_no: null,

        gift: {
          wrap: false,
          note: null,
        },

        coupon: {
          code: null,
          discount: 0,
        },

        campaign: {
          code: null,
          discount: 0,
        },

        products: [],

        price: {
          total: 0,
          sub_total: 0,
          tax: 0,
          cargo: 0,
          last_total: 0,
          currency_code: "TRY",
        },

        // Attachment
        attachment: {
          delivery_date: null,
          delivery_start_time: null,
          delivery_due_time: null,
          note_text: null,
          extra_note: null,
          note_paper: null,
          image: null,
        },
      };

      // Invoice
      state.invoice = {
        country: null,
        city: null,
        district: null,
        neighborhood: null,
        postal_code: null,
        address: null,
      };

      // Shipment
      state.shipment = {
        country: null,
        city: null,
        district: null,
        neighborhood: null,
        postal_code: null,
        address: null,
      };

      // ERP
      state.erp = {
        status: null,
        message: null,
        order_type: null,
      };

      // Bank Result
      state.bank_result = {
        data: null,
        bank: null,
      };

      // Cargo Firm
      state.cargo = {
        id: null,
        is_insurance: false,
        is_integration: false,
        name: null,
        cargo_barcode_url: null,
        cargo_tracking_number: null,
        cargo_barcode_number: null,
        package_quantity: 1,
        package_desi: 1
      };

      // Customer
      state.customer = {
        id: 1, // Static
        type: 0, // Static
        name: "",
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        gsm: null,
        tcn: null,
        vkn: null,
        tax_office: null,
        company: null,
      };

      state.member = null;
    },
  },
  actions: {
    getById({ commit }, data) {
      commit("clearOrder");
      api.get(
        "orders/" + data.id,
        (result) => {
          this.dispatch("orderDetailModal/setOrderData", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    setOrderData({ commit }, result) {
      if (result && result.data && result.data.status !== "error") {
        const item = result.data.item;
        commit("setOrderDetail", item);
      }
    },
  },
};
