export default {
  new(item) {
    this.title = item.title;
    this.content = item.content;
    this.slug = item.slug;
    this.is_approvable = item.is_approvable === 1 ? true : false;
    this.is_emailable = item.is_emailable === 1 ? true : false;
    this.is_contact = item.is_contact === 1 ? true : false;
    this.is_stock_notify = item.is_stock_notify === 1 ? true : false;
    this.confirm_on_register = item.confirm_on_register === 1 ? true : false;
    this.meta = {
      title: item.meta_title,
      keywords: item.meta_keywords,
      description: item.meta_description,
      robots: ["index", "follow"], //item.meta_robots ? item.meta_robots.split(", ") : [],
      canonicalUrl: item.canonical_url,
    };

    return this;
  },
};
