import api from "../../shared/api/index";
import requestCargo from "../../models/request/cargo";
import responseCargo from "../../models/response/cargo";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      let result = [];

      if (payload && payload.length) {
        result = payload.map((c) => {
          c.cargo_company = c.cargo_company ? c.cargo_company.name : null;
          return c;
        });
      }

      state.list = result;
    },
  },
  actions: {
    create({}, data) {
      // requestCargo.new(data.form);
      api.post(
        "cargos",
        requestCargo.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({}, data) {
      api.put(
        "cargos/" + data.id,
        requestCargo.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({}, data) {
      api.delete(
        "cargos/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "cargos/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "cargos/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");

      let route = "all/cargos";

      if (data && data.deleted) {
        route += "?deleted_at=1";
      }

      api.get(
        route,
        (result) => {
          const items =
            result && result.data && result.data.items ? result.data.items : [];
          commit("updateList", items);

          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "cargos/" + data.id,
        (result) => {
          result.data.item = responseCargo.clone(result.data.item);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "cargos/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
