export default {
  new(source, item) {
    this.roles = [];
    this.name = item.name;
    if (source) {
      source.map((role) => {
        const itemRole = item.roles.find((a) => a.model == role.model);
        const data = {
          title: role.title,
          model: role.model,
          code: itemRole ? itemRole.code.split(",") : ["n"],
        };

        this.roles.push(data);
      });
    }
    return this;
  },

  name: null,
  roles: [],
};
