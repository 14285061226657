import api from "../../shared/api";
const basePath = "report/";

export default {
  namespaced: true,
  state: () => ({
    routeList: {
      orders: {
        route: "orders/{id}",
      },
      dailyOrderDetail: {
        route: "daily-order-detail/{id}",
      },
      dailyOrders: {
        route: "daily-orders",
      },
      members: {
        route: "members",
      },
      memberSale: {
        route: "members/sale",
      },
      memberReturn: {
        route: "members/returns",
      },
      memberReturnOrder: {
        route: "member-returns/{id}/orders",
      },
      productSale: {
        route: "products/sale",
      },
      productReturn: {
        route: "products/{id}/returns",
      },
      productReturnOrder: {
        route: "products/{id}/return-orders",
      },
      productOrder: {
        route: "products/{id}/orders",
      },
      campaigns: {
        route: "campaigns",
      },
      campaignProducts: {
        route: "campaigns/{id}/products",
      },
      campaignUsers: {
        route: "campaigns/{id}/users",
      },
      discountCoupons: {
        route: "discount-coupons",
      },
      discountCouponOrders: {
        route: "discount-coupons/{id}/orders",
      },
      discountCouponUsers: {
        route: "discount-coupons/{id}/users",
      },
      stockNotification: {
        route: "stock-notifications",
      },
      stockNotificationMember: {
        route: "stock-notifications/{id}/members",
      },
    },
  }),
  getters: {
    getRouteDetail: (state) => (data, show = false) => {
      const routeKey = data.routeKey;

      if (state.routeList.hasOwnProperty(routeKey)) {
        let routePath = state.routeList[routeKey];
        let route = helper.clone(routePath.route);

        if (show) {
          if (data.hasOwnProperty("id")) {
            route = route.replace("{id}", data.id);
          }
        } else {
          route = route.replace("/{id}", "");
        }

        let queryString = null;

        if (data.hasOwnProperty("table")) {
          queryString = helper.makeQueryString({
            ...data.filter,
            ...data.table,
          });
        } else {
          queryString = helper.makeQueryString(data.filter);
        }

        if (queryString && queryString.length) {
          route += "?" + queryString;
        }

        return basePath + route;
      }
    },
  },
  actions: {
    makeSearch({ getters }, data) {
      let routePath = getters.getRouteDetail(data);
      if (routePath) {
        api.get(
          routePath,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    show({ getters }, data) {
      let routePath = getters.getRouteDetail(data, true);
      if (routePath) {
        api.get(
          routePath,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
