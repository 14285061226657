export default {
  new(data) {
    const result = {};

    result.title = data.title.trim();
    result.file = data.file?.files[0]?.file;

    return result;
  },
};
