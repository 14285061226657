import api from "../../shared/api";

export default {
  actions: {
    getMarketplaceCategory({}, data) {
      const namePath = data.marketplace.name + "/categories";
      const path = data.marketplace.code
        ? namePath + "/" + data.marketplace.code
        : namePath;
      api.get(
        path,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getMarketplaceCargoCompanies({}, data) {
      const namePath = data.marketplace.name + "/cargo-companies";
      const path = data.marketplace.code
        ? namePath + "/" + data.marketplace.code
        : namePath;
      api.get(
        path,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getMarketplaceCategoryFeatures({}, data) {
      const namePath = "marketplace-category-attributes/" + data.marketplace.id;
      var path = namePath + "/" + data.marketplace.category_code;
      if(data.marketplace.isFetch) { path = path + "/" + data.marketplace.isFetch}
      api.get(
        path,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
