import api from "../../shared/api/index";
import requestSlider from "../../models/request/slider";
import responseSlider from "../../models/response/slider";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      state.lang = "tr";
      let result = [];

      if (payload && payload.length) {
        result = payload;
      }

      state.list = result;
    },
  },
  actions: {
    create({ state }, data) {
      api.post(
        `slides?lang=${state.lang}`,
        requestSlider.new(data.data),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        `slides/${data.id}?lang=${state.lang}`,
        requestSlider.new(data.data),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({}, data) {
      api.delete(
        "slides/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "slides/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "slides/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let route = "all/slides";

      if (data && data.deleted) {
        route += "?deleted_at=1";
      }

      api.get(
        route,
        (result) => {
          const items =
            result && result.data && result.data.items ? result.data.items : [];
          commit("updateList", items);

          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `slides/${data.id}?lang=${state.lang}`,
        (result) => {
          if (result && result.data && result.data.item) {
            result = responseSlider.new(result.data.item);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
