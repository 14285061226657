import collectionJson from "../../models/json/collection";

export default {
  new(data) {
    let source = {};

    if (data) {
      const collectionIndex = helper.arrayFind(
        collectionJson.items,
        "name",
        data.prefix
      );

      const config = data.config;
      if (collectionIndex >= 0) {
        let collection = collectionJson.items[collectionIndex];

        if (collection) {
          source = helper.clone(collection);
          source.title = data.name ?? collection.name;

          if (config && Object.keys(config).length) {
            Object.entries(config).forEach(([key, value]) => {
              let sourceElement = source.elements.findIndex(
                (elem) => elem.name === key
              );
              if (sourceElement !== -1) {
                source.elements[sourceElement].value = value;
              }
            });
          }
        }
      }
      //source.rates = this.getRates(JSON.parse(data.rates ?? "{}"));
      source.is_active = data.is_active === 1;
      source.is_test = data.is_test === 1;
      source.installment = data.installment
        ? JSON.stringify(data.installment)
        : "{}";
      source.is_default = data.is_default === 1;
      source.use_installment = data.use_installment === 1;
      source.min_installment_amount =
        (data.use_installment && data.min_installment_amount) || null;
      source.rates = this.getRates(data.rates);
    }

    return source;
  },
  getRates(data) {
    let result = this.defaultRates();

    if (data && data.length) {
      data.forEach((item) => {
        const index = result.findIndex(
          (rd) => rd.installment === item.installment
        );

        if (index >= 0) {
          result[index] = item;
        }
      });
    }

    return result;
  },
  defaultRates() {
    let result = [];
    for (let i = 1; i <= 12; i++) {
      const data = {
        installment: helper.zeroPad(i),
        rate: "-", //0,
      };
      result.push(data);
    }

    return result;
  },
};
