import helper from "../../shared/helper";
import imageRequest from "../../models/request/image";

export default {
  new(state) {
    const info = state.info;
    this.id = info.id;
    this.name = info.name;
    this.slug = info.slug;
    this.currency_code_id = info.selected.currency;
    this.price = info.normalPrice.unmask;
    this.discount_price = info.discountedPrice.unmask;
    this.tax = info.taxAmount;
    this.quantity = info.stock;
    this.quantity_type_id = info.selected.stockType;
    this.sku_no = info.stockCode;
    this.categories = helper.arrayPluck(info.categories, "id");
    this.is_new_product = info.setting.new === true ? 1 : 0;
    this.is_featured_product = info.setting.featured === true ? 1 : 0;
    this.is_active = info.setting.is_active === true ? 1 : 0;
    this.is_store_product = info.setting.is_store_product === true ? 1 : 0;
    this.sell_out_stock = info.setting.sell_out_stock === true ? 1 : 0;
    this.show_in_merchant_xml =
      info.setting.show_in_merchant_xml === true ? 1 : 0;
      this.is_free_cargo =
      info.setting.is_free_cargo === true ? 1 : 0;
    this.use_installment = info.setting.use_installment === true ? 1 : 0;
    this.marketplacePrices =
      info.marketplacePrices && info.marketplacePrices.length
        ? info.marketplacePrices.map((item) => {
            const newItem = {};

            if (item.hasOwnProperty("id")) {
              newItem.id = item.id;
            }

            newItem.competitor_id = item?.competitor_id;
            newItem.marketplace_id = item.marketplace.id;
            newItem.price = item.price;
            newItem.url = item.url;
            newItem.status = item.status;

            return newItem;
          })
        : [];
    this.media = imageRequest(info.image.files);
    this.mobileMedia = imageRequest(info.mobile_image.files);

    const detail = state.detail;
    if (detail.selected.brand != -1) {
      this.brand_id = detail.selected.brand;
    }
    if (detail.selected.supplier != -1) {
      this.supplier_id = detail.selected.supplier;
    }
    if (detail.selected.model != -1) {
      this.model_id = detail.selected.model;
    }

    this.mpn_no = detail.mpn;
    // this.gtin_no = detail.gtin;
    this.package_barcode = detail.packageBarcode;
    this.parcel_barcode = detail.parcelCode;
    this.product_api_code = detail.productApiCode;
    this.workmanship_api_code = detail.workmanshipApiCode;
    this.insurance_multiplier = detail.insuranceMultiplier;
    this.milyem = detail.milyem || 0;
    this.gram = detail.gram || 0;
    this.profit = detail.profit || 0;
    this.reduction = detail.reduction || 0;
    this.purchase_price = detail.purchasePrice.unmask;
    this.warranty_period = detail.warranty;
    this.preparation_time = detail.preparation_time;
    this.cargo_width = detail.cargoPackage.width;
    this.cargo_height = detail.cargoPackage.height;
    this.cargo_depth = detail.cargoPackage.depth;
    this.cargo_weight = detail.cargoPackage.weight;
    this.cargo_desi = detail.cargoPackage.desi;
    this.cargo_price = detail.cargoPackage.price.unmask;
    this.product_type_id = detail.product_type ? detail.product_type : null;
    this.product_template_id = detail.product_template
      ? detail.product_template
      : null;
    this.descriptions = detail.descriptions;

    this.meta_title = detail.meta.title;
    this.meta_description = detail.meta.description;
    this.meta_keywords = detail.meta.keywords;
    // this.meta_robots = detail.meta.robots ? detail.meta.robots.join(", ") : "";
    this.features = helper.arrayPluck(detail.features, ["id", "feature_id"]);
    this.canonical_url = detail.meta.canonicalUrl;

    this.attachment = imageRequest(detail.attachment.files);

    const productMarketplaces = this.marketplaceRequest(
      detail.marketplaces,
      detail.marketplace_initial
    );

    if (productMarketplaces && productMarketplaces.length) {
      this.marketplaces = productMarketplaces;
    }

    this.packageProducts = []; //helper.arrayPluck(detail.packageProducts, "id");
    this.recommendedProducts = helper.arrayPluck(
      detail.recommendedProducts,
      "id"
    );
    this.compatibleProducts = helper.arrayPluck(
      detail.compatibleProducts,
      "id"
    );

    this.brands = helper.arrayPluck(detail.brands, "id");

    this.combineProducts = helper.arrayPluck(detail.combineProducts, "id");

    this.cargoCompanies = [];

    // Cross Products
    this.crossProducts = {};

    if (detail.removedCrossProducts && detail.removedCrossProducts.length) {
      this.crossProducts.deleted = detail.removedCrossProducts;
    }

    if (detail.crossProducts && detail.crossProducts.length) {
      this.crossProducts.created = [];
      this.crossProducts.updated = [];

      for (let i = 0; i < detail.crossProducts.length; i++) {
        const cp = detail.crossProducts[i];
        const data = {};

        if (cp.id) {
          data.id = cp.id;

          if (cp.update) {
            // Updated
            data.code = cp.code;
            data.name = cp.name;
            this.crossProducts.updated.push(data);
          } else if (cp.new) {
            // Selected
            this.crossProducts.created.push(data);
          }
        } else if (cp.code && cp.name) {
          // Created
          data.code = cp.code;
          data.name = cp.name;
          this.crossProducts.created.push(data);
        }
      }
    }
    // Cross Products

    this.variants = this.variantRequest(state);
    this.variantFeatures =
      state.variantFeatures && state.variantFeatures.length
        ? state.variantFeatures
            .map((vf) => {
              return vf.value.map((fv) => {
                return { id: fv.id };
              });
            })
            .flat()
        : [];
    this.variantMedia = state.variantMedia;

    return this;
  },
  variantRequest(state) {
    let result = {
      created: [],
      updated: [],
      deleted: helper.arrayPluck(state.removedVariants, ["id"]),
    };

    const variants = [...state.variants, ...state.availableVariants];

    const staticKeys = [
      "id",
      "sku_no",
      "price",
      "discount_price",
      "quantity",
      "features",
      "barcode",
      "name",
      "is_active",
    ];

    if (variants && variants.length) {
      for (let i = 0; i < variants.length; i++) {
        const variant = helper.clone(variants[i]);
        const keys = Object.keys(variant);

        // Set feature ids
        if (variant.features && variant.features.length) {
          variant.features = helper.arrayPluck(variant.features, "id");
        }

        // Price unmask
        if (variant.price && variant.price.hasOwnProperty("unmask")) {
          variant.price = variant.price.unmask || variant.price.price;
        }

        // Discounted Price unmask
        if (
          variant.discount_price &&
          variant.discount_price.hasOwnProperty("unmask")
        ) {
          variant.discount_price =
            variant.discount_price.unmask || variant.discount_price.price;
        }

        // Set if quantity exists
        if (variant.hasOwnProperty("id")) {
          result.updated.push(variant);
        } else {
          result.created.push(variant);
        }

        // Set Media Array
        if (variant.hasOwnProperty("media") && variant.id) {
          if (variant.media.files && variant.media.files.length) {
            const mediaFile = imageRequest(variant.media.files, false);
            mediaFile.id = variant.id;
            mediaFile.isMain = 1;
            state.variantMedia.updated.push(mediaFile);
          }
        }

        // Delete unrequested keys
        keys.forEach((key) => {
          if (!staticKeys.includes(key)) {
            delete variant[key];
          }
        });
      }
    }
    return JSON.stringify(result);
  },
  marketplaceRequest(marketplaces, marketplace_initial) {
    let diff = [];

    for (let i = 0; i < marketplaces.length; i++) {
      const currentMarketplace = marketplaces[i];
      const initialMarketplace = marketplace_initial.find(
        (mi) => mi.market.id == currentMarketplace.market.id
      );

      if (initialMarketplace) {
        Object.entries(currentMarketplace).map(([key, value]) => {
          // Eğer başlangıç state'i güncel pazaryeri bilgilerine eşit değil ise diziye ekle
          if (
            !["categories"].includes(key) &&
            !_.isEqual(initialMarketplace[key], value) &&
            !_.includes(diff, currentMarketplace)
          ) {
            diff.push(currentMarketplace);
          }
        });
      }
    }

    if (diff && diff.length) {
      diff = diff.map((item) => {
        const data = {};
        data.id = item.market.id; // Marketplace id
        data.active = item.active ? 1 : 0; // Active set to integer
        data.attributes = item.category_attributes; // Categories
        data.category_id = item.category ? item.category.id : null; // Panel Category ID
        // Selected Marketplac Category
        data.category_code = item.selectedCategory
          ? item.selectedCategory.id
          : null;
        data.category_name = item.selectedCategory
          ? item.selectedCategory.name
          : null;
        if (item.brand) {
          data.brand_id = item.brand.id;
          data.brand_name = item.brand.name;
        }
        if (item.supplier) {
          data.supplier_id = item.supplier.id;
          data.supplier_name = item.supplier.name;
        }
        if (item.delivery_type) {
          data.delivery_type = item.delivery_type;
        }
        if (item.delivery_message) {
          data.delivery_message = item.delivery_message;
        }
        if (item.cargoCompany) {
          data.cargoCompany_id = item.cargoCompany.id;
          data.cargoCompany_name = item.cargoCompany.name;
        }
        if (item.alternativeCargoCompany) {
          data.alternativeCargoCompany_id = item.alternativeCargoCompany.id;
          data.alternativeCargoCompany_name = item.alternativeCargoCompany.name;
        }
        if (item.market.name == "n11") {
          data.shipmentTemplate =
            item.shipment_template ||
            (item.shipment_templates && item.shipment_templates.length)
              ? item.shipment_templates[0]
              : null;
          data.n11CatalogId = item.catalog_id || null;
          data.deliveryDay = item.n11_deliveryday || null;
        }
        if (item.market.name == "hepsiburada") {
          data.hepsiburadaSku = item.sku_no;
          data.deliveryDay = item.hepsiburada_deliveryday;
        }
        if (item.market.name == "vodafone") {
          data.deliveryDay = item.vodafone_deliveryday;
        }
        // Category Filter & Map
        if (data.attributes) {
          const filteredCategories = data.attributes.filter(
            (category) => category.value
          );
          data.attributes =
            filteredCategories && filteredCategories.length
              ? filteredCategories.map((cat) => {
                  const categoryResult = {};
                  categoryResult.code = cat.code;
                  categoryResult.value = cat.value ? cat.value.code : null;
                  return categoryResult;
                })
              : [];
        }

        // Liste ve Satış Fiyatı
        if (item.fixed_price) {
          let listingPrice = item.listing_price_unmask;
          let salePrice = item.sale_price_unmask;

          listingPrice = item.listing_price_unmask;
          salePrice = item.listing_price_unmask;

          data.listing_price = listingPrice;
          if (item.market.name !== "hepsiburada") {
            data.price = salePrice;
          }
        } else {
          data.listing_price_type = item.listing_price_type;
          data.listing_percent_type = item.listing_percent_type;
          data.listing_percent =
            item.listing_percent_type == "equal" ||
            item.listing_percent_type == "deactive"
              ? null
              : item.listing_percent;

          if (item.market.name !== "hepsiburada") {
            data.sale_price_type = item.sale_price_type;
            data.sale_percent_type = item.sale_percent_type;
            data.sale_percent =
              item.sale_percent_type == "equal" ? null : item.sale_percent;
          }

          data.extra_cost = item.extra_cost_unmask || 0;
        }

        if (data.active) {
          data.fixed_price = item.fixed_price ? 1 : 0;
          data.fixed_quantity = item.fixed_quantity ? 1 : 0;
          data.quantity = item.quantity;
        }

        return data;
      });
    }

    return diff;
  },
  id: null,
  name: null,
  slug: null,
  is_new_product: false,
  is_featured_product: false,
  is_active: false,
  is_store_product: false,
  sell_out_stock: false,
  show_in_merchant_xml: true,
  is_free_cargo: false,
  use_installment: false,
  currency_code_id: null,
  price: null,
  discount_price: null,
  quantity: null,
  quantity_type_id: null,
  categories: [],
  media: [],
  mobileMedia: [],
  attachment: null,
  marketplacePrices: [],

  sku_no: null,
  mpn_no: null,
  gtin_no: null,
  package_barcode: null,
  parcel_barcode: null,
  product_api_code: null,
  workmanship_api_code: null,
  insurance_multiplier: null,
  tax: null,
  purchase_price: null,
  warranty_period: null,
  preparation_time: null,

  cargo_width: null,
  cargo_height: null,
  cargo_depth: null,
  cargo_weight: null,
  cargo_desi: null,
  cargo_price: 0,
  product_type_id: null,
  product_template_id: null,
  descriptions: [],
  meta_title: null,
  meta_description: null,
  meta_keywords: null,
  // meta_robots: null,
  canonical_url: null,
  features: null,

  packageProducts: [],
  recommendedProducts: [],
  compatibleProducts: [],
  combineProducts: [],
  cargoCompanies: [],
  crossProducts: {},
  variants: [],
  brands: [],
  variantFeatures: [],
};
