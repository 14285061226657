import responseImage from "../../models/response/image";

export default {
  new(item) {
    this.title = item.title;
    this.slug = item.slug;
    this.content = item.content;
    this.is_active = item.is_active;
    this.published_at = item.published_at;

    this.meta = {
      title: item.meta_title,
      keywords: item.meta_keywords,
      description: item.meta_description,
      robots: ["index", "follow"], //item.meta_robots ? item.meta_robots.split(", ") : [],
    };

    if (item.image && item.image.path !== null) {
      this.image.files = [responseImage.clone(item.image, false)];
    }
    if (item.rolex_desktop && item.rolex_desktop.path !== null) {
      this.rolex_desktop.files = [
        responseImage.clone(item.rolex_desktop, false),
      ];
    }
    if (item.rolex_mobile && item.rolex_mobile.path !== null) {
      this.rolex_mobile.files = [responseImage.clone(item.rolex_mobile, false)];
    }
    return this;
  },
  title: null,
  slug: null,
  content: null,
  is_active: true,
  published_at: null,
  meta: {
    title: null,
    keywords: null,
    description: null,
    robots: ["index", "follow"],
  },
  image: {
    files: [],
    filesData: null,
  },
  rolex_desktop: {
    files: [],
    filesData: null,
  },
  rolex_mobile: {
    files: [],
    filesData: null,
  },
};
