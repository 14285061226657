import api from "../../shared/api";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  actions: {
    getHomeBlocks({ state }, data) {
      let route = "showcases";
      if (data.id) {
        route += `/${data.id}`;
      }
      route += `?lang=${state.lang}`;

      api.get(
        route,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    setHomeBlocks({ state }, data) {
      api.post(
        `showcases?lang=${state.lang}`,
        {
          items: data.request,
        },
        (result) => {
          //this.dispatch("cacheClear/clear");
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    saveShowcase({ state }, data) {
      if (data.id) {
        let route = `showcases/${data.id}?lang=${state.lang}`;
        const requestProducts = data.products.sort((a, b) => a.index - b.index);
        const requestBanners = data.banners.sort((a, b) => a.index - b.index);
        const showcase = data.showcase;

        api.put(
          route,
          {
            title: showcase.title,
            type: showcase.type,
            products: helper.arrayPluck(requestProducts, ["id"]),
            banners: helper.arrayPluck(requestBanners, ["id"]),
          },
          (result) => {
            //this.dispatch("cacheClear/clear");
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getCategoryBlocks({}, data) {
      let route = "showcase-categories";
      if (data.id) {
        route += `/${data.id}`;
      }

      api.get(
        route,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    setCategoryBlocks({}, data) {
      api.post(
        "showcase-categories",
        {
          items: data.request,
        },
        (result) => {
          //this.dispatch("cacheClear/clear");
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    saveCategoryProducts({}, data) {
      const category = data.form;
      api.put(
        "showcase-categories/" + category.id,
        {
          category_id: category.category_id,
          products: helper.arrayPluck(category.products, ["id"]),
        },
        (result) => {
          //this.dispatch("cacheClear/clear");
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
