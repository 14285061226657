export default {
  new(item) {
    this.title = item.title;
    this.slug = item.slug;
    this.is_active = item.is_active === 1;
    this.show_page_list = item.show_page_list === 1;
    this.show_page_footer = item.show_page_footer === 1;
    this.show_page_sidebar = item.show_page_sidebar === 1;
    this.contents =
      item.contents && item.contents.length
        ? item.contents.map((block) => {
            const newData = {};
            newData.id = block.id;
            newData.block = {
              title: block.title,
              ...block.style,
            };
            newData.columns = block.columns;

            return newData;
          })
        : [];

    this.meta = {
      title: item.meta_title,
      keywords: item.meta_keywords,
      description: item.meta_description,
      robots: ["index", "follow"], // item.meta_robots ? item.meta_robots.split(", ") : [],
      canonicalUrl: item.canonical_url,
    };
    return this;
  },
};
