import api from "../../shared/api";
import imageRequest from "../../models/request/image";
import requestBlog from "../../models/request/blog";
import responseBlog from "../../models/response/blog";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      state.list = [];
      helper.copy(result.data.items, state.list);
    },
    updateLang(state, lang) {
      state.lang = lang;
    },
    clearList(state) {
      state.lang = "tr";
      state.list = [];
    },
  },
  actions: {
    create({ state }, data) {
      api.post(
        `blogs?lang=${state.lang}`,
        requestBlog.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      if (data && data.id) {
        api.put(
          `blogs/${data.id}?lang=${state.lang}`,
          requestBlog.new(data.form),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    delete({ state }, data) {
      if (data && data.id) {
        api.delete(
          "blogs/" + data.id,
          (result) => {
            if (result && result.data && result.data.status !== "error") {
              helper.arrayRemove(state.list, "id", data.id);
            }
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    restore({}, data) {
      api.restore(
        "blogs/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "blogs/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      commit("clearList");
      let route = "all/blogs";

      if (data.lang) commit("updateLang", data.lang);

      route += `?lang=${state.lang}`;

      if (data && data.deleted) {
        route += "&deleted_at=1";
      }

      api.get(
        route,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `blogs/${data.id}?lang=${state.lang}`,
        (result) => {
          let responseData = responseBlog.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(responseData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    saveRolexBlogImage({}, data) {
      let routePath = "blogs/" + data.id + "/rolex-images";
      api.post(
        routePath,
        {
          rolex_desktop: imageRequest(data.desktop.files, false),
          rolex_mobile: imageRequest(data.mobile.files, false),
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "blogs/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
