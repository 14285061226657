import api from "../../shared/api";

export default {
  actions: {
    searchSlug({}, data) {
      if (data) {
        const requestData = {};
        requestData.slug = data.form.slug;
        if (data.form.id) requestData.id = data.form.id;

        api.post(
          "unique/" + data.form.type,
          requestData,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
