import api from "../../shared/api";
import requestSupplier from "../../models/request/supplier";
import responseSupplier from "../../models/response/supplier";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
    defaultFilter: [
      'name',
      'id',
      'username',
      'password',
      'address',
      'type',
      'lastLog',
      'is_active',
      'is_main'
    ],
    currencies: [],
    types: [
      {"id": 1, "name": "XML"},
      {"id": 2, "name": "Excel"},
      {"id": 3, "name": "JSON"}
    ],
    reduction_types: [],
    filter_types: []
  }),
  mutations: {
    updateList(state, payload) {
      state.lang = "tr";
      let items = payload.data ? payload.data.items : payload;
      state.list = items;
    },
    updateLang(state,lang){
      state.lang = lang
    },
    clearList(state){
      state.lang = "tr"
      state.list = []
    }
  },
  actions: {
    create({ state }, data) {
      if (data.form) {
        api.post(
          `supplier-integration?lang=${state.lang}`,
          requestSupplier.new(data.form),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    saveCategories({ state }, data) {
      if (data) {
        api.post(
          `supplier-integration/saveCategory`,
          { 
            categories: data.categories,
            supplier: data.supplier
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    saveReductAndProfit({ state }, data) {
      if (data) {
        api.post(
          `supplier-integration/saveReductAndProfit`,
          { 
            type: data.type,
            reductions: data.reductions,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    saveFilters({ state }, data) {
      if (data) {
        api.post(
          `supplier-integration/saveFilters`,
          { 
            type: data.type,
            filter_data: data.filter_data,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    update({ state }, data) {
      api.put(
        `supplier-integration/${data.id}?lang=${state.lang}`,
        requestSupplier.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "supplier-integration/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          this.dispatch("supplier-integration/getList");
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "supplier-integration/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "supplier-integration/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      commit("clearList")
      let route = "all/supplier-integration";

      if (data && data.deleted) {
        route += "&deleted_at=1";
      }
      
      if(data && data.is_filter) {
        route += '?is_filter='+ data.is_filter;
      } else {
        
        route += '?is_filter=' + state.defaultFilter;
      }

      api.get(
        route,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getSupplierCategory({ commit, state }, data) {
      let route = "all/supplier-integration-category";
      api.get(
        route+"?supplier=" + data.supplier,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getSupplierReduction({ commit, state }, data) {
      let route = "all/supplier-integration-reduction";
      api.get(
        route+"?typeName=" + data.typeName,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getSupplierFilter({ commit, state }, data) {
      let route = "all/supplier-integration-filter";
      api.get(
        route+"?typeName=" + data.typeName,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `supplier-integration/${data.id}`,
        (result) => {
          let resultData = responseSupplier.new(result.data.item);
          if(resultData.type) {
            resultData.types = state.types.filter(A => A.id == resultData.type)[0]
          }
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "supplier-integration/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    loadSupplierCategory({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "supplier-integration/category/" + requestData.id,
          {},
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    checkMain({}, data) {
        api.post(
          "supplier-integration/checkMain",
          {},
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      loadXmlCategory({}, data) {
        api.post(
          "supplier-integration/loadXmlCategory",
          { 
            address: data.address,
            attributeKey: data.attributeKey,
            supplierId: data.supplierId,
            brace: data.brace
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      loadJsonCategory({}, data) {
        api.post(
          "supplier-integration/loadJsonCategory",
          { 
            name: data.name,
            supplier_id: data.supplier_id
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      loadXmlBrand({}, data) {
        api.post(
          "supplier-integration/loadXmlBrand",
          { 
            address: data.address,
            attributes: data.attributes
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      loadJsonBrand({}, data) {
        api.post(
          "supplier-integration/loadJsonBrand",
          { 
            name: data.name,
            filter: data.filter,
            supplier_id: data.supplier_id,
            attributes: data.attributes
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      getCurrencyList({ state, commit }, data) {
        api.get(
          "all/currency-codes",
          (result) => {
            state.currencies = result.data.items;
            if (data && data.onSuccess) data.onSuccess(result);
          },
  
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      },
      getBrandTypeList({ commit, state }, data) {
        let route = "all/brand-types";
       
        route += `?lang=${state.lang}`;

        api.get(
          route,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
  },
};
