import api from "../../shared/api";

export default {
  namespaced: true,
  actions: {
    create({}, data) {
      api.post(
        "setup",
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
