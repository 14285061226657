import api from "../../shared/api";
import requestRole from "../../models/request/role";
import responseRole from "../../models/response/role";
import roleJson from "../../models/json/role";

export default {
  namespaced: true,
  state: () => ({
    source: [],
    roleType: [
      { code: "v", name: "Görüntüleme" },
      { code: "c", name: "Ekleme" },
      { code: "u", name: "Güncelleme" },
      { code: "d", name: "Silme" },
      { code: "r", name: "Geri Getirme" },
      { code: "f", name: "Kalıcı Silme" },
      // { code: "n", name: "Yetkisiz" },
    ],
  }),
  mutations: {
    setSource(state, data) {
      if (data) {
        data.forEach((role) => {
          let result = {
            name: helper.getRandomInteger(1, 99999),
            model: role.value,
            title: role.title,
            code: ["n"],
          };

          state.source.push(result);
        });
      } else {
        state.source = [];
      }
    },
  },
  actions: {
    create({}, data) {
      if (data && data.source) {
        api.post(
          "role-groups",
          requestRole.new(data.source, data.name),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    update({}, data) {
      api.put(
        "role-groups/" + data.id,
        requestRole.new(data.source, data.name),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({}, data) {
      api.delete(
        "role-groups/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      api.get(
        "role-groups",
        (result) => {
          const items =
            result && result.data && result.data.items ? result.data.items : [];
          if (data && data.onSuccess) data.onSuccess(items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        "role-groups/" + data.id,
        (result) => {
          let item;
          if (result && result.data && result.data.item) {
            item = responseRole.new(state.source, result.data.item);
            state.source = item.roles;
          } else {
            item = {};
          }

          if (data && data.onSuccess) data.onSuccess(item);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getRoleModels({ commit }, data) {
      commit("setSource");
      api.get(
        "all/role-models",
        (result) => {
          commit("setSource", result.data);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
