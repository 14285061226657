import api from "../../shared/api/index";
import nodeApi from "../../shared/node-api/index";
import constants from "../../shared/constant/index";

export default {
  namespaced: true,
  state: () => ({
    userId: null,
    userFullName: null,
    userAvatar: null,
    accessToken: null,
    priceAnalysis: null,
    expires: null,
    refreshToken: null,
    config: null,
    roles: null,
    contactTypes: [],
  }),
  mutations: {
    setSession(state, value) {
      if (value) {
        state.userId = value.userId;
        state.userFullName = value.userFullName;
        state.userAvatar = null;
        state.accessToken = value.accessToken;
        state.priceAnalysis = value.priceAnalysis;
        state.expires = value.expires;
        state.refreshToken = value.refreshToken;
        state.config = value.config;
        state.contactTypes = value.contactTypes;
        state.url = constants.API_URL;
        state.roles = value.roles;
        localStorage.setItem("session", JSON.stringify(state));

        api.setHeaders(state);
        nodeApi.setHeaders(state.priceAnalysis);
      } else {
        state.userId = null;
        state.userFullName = null;
        state.userAvatar = null;
        state.accessToken = null;
        state.priceAnalysis = null;
        state.expires = null;
        state.url = null;
        state.roles = null;
        state.config = null;
        state.refreshToken = null;

        localStorage.removeItem("session");

        api.setHeaders();
        nodeApi.setHeaders();
      }
    },
    setConfigData(state, config) {
      // Update Localstorage Config Object
      let sessionStorage = JSON.parse(localStorage.session);
      // sessionStorage.config = {};
      helper.copy(config, sessionStorage.config);
      localStorage.setItem("session", JSON.stringify(sessionStorage));
    },
  },
  actions: {
    load({ commit }, data) {
      const stored = JSON.parse(localStorage.getItem("session"));
      if (stored) {
        commit("setSession", stored);
      } else {
        commit("setSession");
      }

      if (data && data.onFinish) data.onFinish();
    },
    login({ commit }, data) {
      commit("setSession");

      const session = {};

      api.login(
        data.requestLogin.email,
        data.requestLogin.password,
        (result) => {
          if (data && data.onSuccess) {
            session.accessToken = result.accessToken;
            session.expires = result.expires;
            session.refreshToken = result.refreshToken;
            session.userId = result.user.id;
            session.userFullName = result.user.full_name;
            session.config = result.config;
            session.userAvatar = null;
            session.contactTypes = result.contactTypes;
            session.roles = result.roles;
            // sonraki get isteğinin gerçekleşmesi için token gerekli
            commit("setSession", session);
            data.onSuccess();
          }
        },
        () => {
          if (data && data.onFail) data.onFail();
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    logout({ commit }, data) {
      commit("setSession");

      api.logout(
        () => {
          if (data && data.onSuccess) data.onSuccess();
        },
        () => {
          if (data && data.onError) data.onError();
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getConfig({ commit, state }, data) {
      api.get(
        "configs",
        (result) => {
          state.config = result.data;
          commit("setConfigData", result.data);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        () => {
          if (data && data.onFail) data.onFail();
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    updateConfig({ commit, state }, data) {
      api.post(
        "configs",
        state.config,
        (result) => {
          if (result && result.data && result.data.status == "success") {
            commit("setConfigData", state.config);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        () => {
          if (data && data.onFail) data.onFail();
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
