export default {
  items: [
    {
      name: "canias",
      logo: "https://www.gesu.com.tr/wp-content/uploads/canias-erp-736x414.jpg",
      title: "Canias",
      elements: [
        {
          name: "serviceUrl",
          label: "Servis URL",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "serviceId",
          label: "Servis ID",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "appServer",
          label: "Uygulama Sunucusu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "dbServer",
          label: "Veritabanı Sunucusu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "dbName",
          label: "Veritabanı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "username",
          label: "Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "entegra",
      logo: "https://www.entegrabilisim.com/uploads/logo.png",
      title: "Entegra",
      elements: [
        {
          name: "email",
          label: "Email",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "logo",
      logo: "https://dip.dto.org.tr/wp-content/uploads/2019/10/yeni_LOGO_logosu_1.jpg",
      title: "Logo",
      elements: [
        {
          name: "url",
          label: "URL",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "username",
          label: "Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "grant_type",
          label: "Erişim Türü",
          form: "input",
          type: "text",
          placeholder: "Örn. password",
          value: "password",
          required: true,
        },
        {
          name: "firmno",
          label: "Firma Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "use_acc_firmno",
          label: "Resmi Muhasebe Firma Kodu Kullan",
          form: "boolean",
          ignore: true,
          value: null,
        },
        {
          name: "curr_firmno",
          label: "Mevcut Firma Numarası",
          form: "input",
          type: "text",
          value: null,
          parent: "use_acc_firmno",
        },
        {
          name: "acc_firmno",
          label: "Resmi Muhasebe Firma Kodu",
          form: "input",
          type: "text",
          value: null,
          parent: "use_acc_firmno",
        },
        {
          name: "price_table",
          label: "Fiyat Tablosu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "onhand_table",
          label: "Stok Tablosu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "cargo_code",
          label: "Kargo Kalem Kodu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
  ],
};