export default {
  new(email, password) {
    this.email = email;
    this.password = password;
    return this;
  },
  clone(value) {
    if (value) {
      this.email = value.email;
      this.password = value.password;
      return this;
    } else {
      return null;
    }
  },
  email: null,
  password: null,
};
