export default {
  namespaced: true,
  state: () => ({
    fields: {
      product: [
        {
          key: "images",
          value: "Görsel Adresleri (Maks. 3)",
        },
        {
          key: "sku_no",
          value: "Stok Kodu",
        },
        {
          key: "name",
          value: "Ürün Adı",
        },
        {
          key: "marketplaces",
          value: "Pazaryeri",
        },
        {
          key: "category",
          value: "Kategori",
        },
        {
          key: "brand",
          value: "Marka",
        },
        {
          key: "model",
          value: "Model",
        },
        {
          key: "quantity",
          value: "Miktar",
        },
        {
          key: "currency_code",
          value: "Para Birimi",
        },
        {
          key: "price",
          value: "Satış Fiyatı",
        },
        {
          key: "purchase_price",
          value: "Alış Fiyatı",
        },
        {
          key: "discount_price",
          value: "İndirimli Fiyat",
        },
        {
          key: "profit",
          value: "Kâr",
        },
        {
          key: "tax",
          value: "KDV",
        },
        {
          key: "package_barcode",
          value: "Paket Barkodu (GTIN)",
        },
        {
          key: "is_active",
          value: "Durum",
        },
        {
          key: "descriptions",
          value: "Açıklamalar",
        },
        {
          key: "cargo_desi",
          value: "Kargo - Desi",
        },
        {
          key: "cargo_weight",
          value: "Kargo - Ağırlık",
        },
        {
          key: "warranty_period",
          value: "Garanti Süresi (Yıl)",
        },
        {
          key: "variant_name",
          value: "Varyasyon Adı",
        },
        {
          key: "variant_price",
          value: "Varyasyon Satış Fiyatı",
        },
        {
          key: "variant_discount_price",
          value: "Varyasyon İndirimli Fiyat",
        },
        {
          key: "supplier",
          value: "Tedarikçi",
        },
        {
          key: "reduction",
          value: "İskonto",
        },
      ],
      order: [
        { key: "productDetail", value: "Ürün Detayı" },
        { key: "created_at", value: "Sipariş Tarihi" },
        { key: "source", value: "Platform" },
        { key: "order_no", value: "Sipariş No" },
        { key: "name", value: "Müşteri Adı" },
        { key: "payment_type", value: "Ödeme" },
        { key: "cargo_company_name", value: "Kargo" },
        { key: "shipment_city", value: "Şehir" },
        { key: "order_type", value: "Durum" },
        { key: "price", value: "Tutar" },
        { key: "invoice_number", value: "Fatura No" },
        { key: "ref_no", value: "Referans Numarası" },
        { key: "shipment_info", value: "Teslimat Bilgileri" },
        { key: "invoice_info", value: "Fatura Bilgileri" },
      ],
    },
  }),
};
