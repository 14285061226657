import api from "../../shared/api";
import invoiceRequest from "../../models/request/invoiceIntegration";
import invoiceResponse from "../../models/response/invoiceIntegration";
import invoiceJson from "../../models/json/invoiceIntegration";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, items = []) {
      state.list = [];
      if (items && items.length) {
        items = items.map((item) => {
          const invoiceItem = invoiceJson.items.find(
            (invoiceIntegration) => invoiceIntegration.name === item.name
          );
          const newItem = {
            id: item.id,
            is_active: item.is_active,
            is_test: item.is_test,
            is_default: item.is_default,
            ...invoiceItem,
          };

          return newItem;
        });
      }
      state.list = items;
    },
  },
  actions: {
    update({}, data) {
      api.put(
        "invoice-integration/" + data.invoice.id,
        invoiceRequest.new(data.invoice),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "invoice-integration/" + data.id,
        (result) => {
          const resultData =
            result && result.data && result.data.item
              ? invoiceResponse.new(result.data.item)
              : null;
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      api.get(
        "invoice-integration",
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "invoice-integration/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    isDefault({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "invoice-integration/isDefault/" + requestData.id,
          {
            is_default: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
