import api from "../../shared/api";

export default {
  state: () => ({
    roles: [],
    roleGroups: [],
  }),
  actions: {
    getRoleGroups({ state }, data) {
      state.roleGroups = [];
      api.get(
        "role-groups",
        (result) => {
          helper.copy(result.data.items, state.roleGroups);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getRoles({ state }, data) {
      if (data && data.id) {
        api.get(
          "roles",
          (result) => {
            helper.copy(result.data.items, state.roles);
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
