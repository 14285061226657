import api from "../../shared/api";
import requestMarketplace from "../../models/request/marketplace";
import responseMarketplace from "../../models/response/marketplace";
import marketplaceCategory from "./category";
import marketplaceBrand from "./brand";
import shipmentTemplate from "./shipmenttemplate";

export default {
  namespaced: true,
  modules: {
    marketplaceCategory,
    marketplaceBrand,
    shipmentTemplate,
  },
  state: () => ({
    detail: {},
    list: [],
  }),
  mutations: {
    setInformation(state, item) {
      if (item) {
        state.detail = responseMarketplace.new(item);
      } else {
        state.detail = {};
      }
    },
    updateList(state, result) {
      state.list = [];
      if (result && result.data) {
        helper.copy(result.data.items, state.list);
      }
    },
  },
  actions: {
    load({ commit }) {
      // commit("setInformation");
    },
    update({ state }, data) {
      api.post(
        "marketplaces/config/" + state.detail.name,
        requestMarketplace.new(state.detail),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      commit("setInformation");
      if (data && data.id) {
        api.get(
          "marketplaces/" + data.id,
          (result) => {
            if (result && result.data && result.data.item) {
              commit("setInformation", result.data.item);
            }
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "marketplaces",
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "marketplaces/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
