export default {
  namespaced: true,
  state: () => ({
    item: {
      id: null,
      name: null,
      code: null,
      index: null,
      update: 0,
    },
  }),
  mutations: {
    clearCrossItem(state) {
      state.item = {
        id: null,
        name: null,
        code: null,
        index: null,
        update: 0,
      };
    },
  },
};
