export default {
  new(data) {
    this.payment_bank_id = data.bank_id;
    this.account_name = data.account_name;
    this.account_number = data.account_number;
    this.account_type = data.account_type;
    this.branch_name = data.branch;
    this.branch_code = data.branch_code;
    this.iban = data.iban ? "TR" + data.iban : null;

    return this;
  },
  payment_bank_id: null,
  account_name: null,
  account_number: null,
  account_type: null,
  branch_name: null,
  branch_code: null,
  iban: null,
};
