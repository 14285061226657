export default {
  items: [
    {
      name: "paytr",
      elements: [
        {
          name: "merchantId",
          label: "Mağaza Numarası",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "merchantKey",
          label: "Mağaza Şifresi",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "merchantSalt",
          label: "Mağaza Gizli Anahtarı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "iyzico",
      elements: [
        {
          name: "username",
          label: "API Anahtarı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Güvenlik Anahtarı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "pos3dUrl",
          label: "pos3dUrl",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "edenred",
      elements: [
        {
          name: "MerchantNo",
          label: "Merchant No",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "TerminalNo",
          label: "Terminal No",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "Password",
          label: "Password",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "HashKey",
          label: "HashKey",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "mokapos",
      elements: [
        {
          name: "dealerCode",
          label: "Bayi Kodu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "username",
          label: "API Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "API Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "parampos",
      use_installment: false,
      min_installment_amount: 0,
      rates: [],
      elements: [
        {
          name: "clientCode",
          label: "Müşteri Kodu",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "guid",
          label: "Üye İş Yeri Anahtarı (GUID)",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "clientUsername",
          label: "Web Servis Kullanıcı Adı (CLIENT_USERNAME)",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "clientPassword",
          label: "Web Servis Şifre (CLIENT_PASSWORD)",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "sodexo",
      use_installment: false,
      min_installment_amount: 0,
      rates: [],
      elements: [
        {
          name: "username",
          label: "Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "merchantno",
          label: "Merchant No",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "terminalno",
          label: "Terminal No",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
    {
      name: "klarna",
      use_installment: false,
      min_installment_amount: 0,
      rates: [],
      elements: [
        {
          name: "username",
          label: "Kullanıcı Adı",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
        {
          name: "password",
          label: "Şifre",
          form: "input",
          type: "text",
          value: null,
          required: true,
        },
      ],
    },
  ],
};
