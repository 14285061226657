export default {
  formatSize(value, decimals = 2) {
    if (value === 0 || (typeof value == "string" && value.split("x").length)) {
      return "1 KB";
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Byte", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(value) / Math.log(k));

    return parseFloat((value / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  },
  setMimeTypes(sourceArray, targetArray) {
    if (sourceArray["mimeBmp"]) targetArray.push("image/bmp");
    if (sourceArray["mimeJpeg"]) targetArray.push("image/jpeg");
    if (sourceArray["mimePng"]) targetArray.push("image/png");
    if (sourceArray["mimeGif"]) targetArray.push("image/gif");
    if (sourceArray["mimeWebp"]) targetArray.push("image/webp");
    if (sourceArray["mimeTiff"]) targetArray.push("image/tiff");
    if (sourceArray["mimeSvg"]) targetArray.push("image/svg+xml");
    if (sourceArray["mimeMp4"]) targetArray.push("video/mp4");
    if (sourceArray["mimeMpeg"]) targetArray.push("video/mpeg");
    if (sourceArray["mimeOgg"]) targetArray.push("video/ogg");
    if (sourceArray["mimeWebm"]) targetArray.push("video/webm");
  },
  getAcceptedMimeTypes(sourceArray) {
    let result = "";

    if (sourceArray["mimeBmp"]) result += "image/bmp,";
    if (sourceArray["mimeJpeg"]) result += "image/jpeg,";
    if (sourceArray["mimePng"]) result += "image/png,";
    if (sourceArray["mimeGif"]) result += "image/gif,";
    if (sourceArray["mimeWebp"]) result += "image/webp,";
    if (sourceArray["mimeTiff"]) result += "image/tiff,";
    if (sourceArray["mimeSvg"]) result += "image/svg+xml,";
    if (sourceArray["mimeMp4"]) result += "video/mp4,";
    if (sourceArray["mimeMpeg"]) result += "video/mpeg,";
    if (sourceArray["mimeOgg"]) result += "video/ogg,";
    if (sourceArray["mimeWebm"]) result += "video/webm,";

    return result;
  },
  mimeTypeIsImage(mimeType) {
    let result = false;

    if (mimeType) {
      const types = [
        "image/bmp",
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/webp",
        "image/tiff",
        "image/svg+xml",
      ];
      result = types.indexOf(mimeType) >= 0;
    }

    return result;
  },
  mimeTypeIsVideo(mimeType) {
    let result = false;

    if (mimeType) {
      const types = ["video/mp4", "video/mpeg", "video/ogg", "video/webm"];
      result = types.indexOf(mimeType) >= 0;
    }

    return result;
  },
  arrayPush(array, member, newItem) {
    let exists = false;
    let index = -1;

    if (newItem.hasOwnProperty(member)) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];

        if (item[member] == newItem[member]) {
          exists = true;
          index = i;
          break;
        }
      }
    }

    if (exists && index >= 0) {
      array[index] = Object.assign({}, newItem);
    } else {
      array.push(newItem);
    }
  },
  arrayFind(array, member, value) {
    let exists = false;
    let index = -1;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];

      if (item.hasOwnProperty(member)) {
        if (item[member] == value) {
          exists = true;
          index = i;
          break;
        }
      }
    }
    return index;
  },
  arrayRemove(array, member, value) {
    let exists = false;
    let index = -1;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (item.hasOwnProperty(member)) {
        if (item[member] == value) {
          exists = true;
          index = i;
          break;
        }
      }
    }

    if (exists && index >= 0) {
      array.splice(index, 1);
    }
  },
  arrayChange(array, member, memberValue, setMember, setValue) {
    let exists = false;
    let index = -1;

    for (let i = 0; i < array.length; i++) {
      const item = array[i];
      if (item.hasOwnProperty(member)) {
        if (item[member] == memberValue) {
          exists = true;
          index = i;
          break;
        }
      }
    }

    if (exists && index >= 0) {
      array[index][setMember] = setValue;
    }
  },
  arrayEquals(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every((value, index) => value === array2[index])
    );
  },
  arrayPluck(array, key, useKey = false) {
    let result = [];

    if (array && array.length) {
      result = array.map((obj) => {
        // Use Specific Key
        if (useKey) {
          return { [key]: obj[key] };
        }

        let result = {};

        // Check whether key is object array
        if (typeof key == "object" && Array.isArray(key)) {
          for (let i = 0; i < key.length; i++) {
            const element = key[i];
            result[element] = obj[element];
          }
        } else {
          result = obj[key];
        }

        return result;
      });
    }

    return result;
  },
  arraySum(array, key) {
    let sum = 0;

    if (array && Array.isArray(array)) {
      array.forEach((el) => {
        if (el.hasOwnProperty(key)) {
          const element = el[key];
          sum += Number(element);
        }
      });
    }

    return sum;
  },
  getRandomInteger(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  clone(source) {
    let result = null;
    let type = typeof source;

    if (type !== "boolean" && !source && type !== "number") {
      return null;
    }
    if (type === "object" && Array.isArray(source)) {
      type = "array";
    }

    switch (type) {
      case "object":
        result = {};
        const members = Object.keys(source);
        for (let i = 0; i < members.length; i++) {
          const member = members[i];
          if (member == "file") {
            result = source;
          } else {
            if (source[member] !== null) {
              result[member] = this.clone(source[member]);
            } else {
              result[member] = null;
            }
          }
        }
        break;
      case "array":
        result = [];
        for (let i = 0; i < source.length; i++) {
          const item = source[i];
          result.push(this.clone(item));
        }
        break;
      default:
        result = source;
        break;
    }

    return result;
  },
  copy(source, target, except = []) {
    let sourceType = typeof source;
    if (sourceType === "object" && Array.isArray(source)) {
      sourceType = "array";
    }

    let targetType = typeof target;
    if (targetType === "object" && Array.isArray(target)) {
      targetType = "array";
    }

    if (sourceType !== targetType) {
      return;
    }

    switch (sourceType) {
      case "object":
        const members = Object.keys(source);
        for (let i = 0; i < members.length; i++) {
          const member = members[i];
          if (except && except.length) {
            if (!except.includes(member)) {
              target[member] = this.clone(source[member]);
            }
          } else {
            target[member] = this.clone(source[member]);
          }
        }
        break;
      case "array":
        for (let i = 0; i < source.length; i++) {
          const item = source[i];
          target.push(this.clone(item));
        }
        break;
      default:
        return source;
        break;
    }
  },
  arrayReduce(source, target, keys) {
    if (source && Array.isArray(source)) {
      for (let i = 0; i < source.length; i++) {
        const item = source[i];

        if (item) {
          const objArray = {
            data: [],
          };
          for (let index = 0; index < keys.length; index++) {
            const keyMember = keys[index];

            if (item.hasOwnProperty(keyMember)) {
              objArray.data.push(item[keyMember]);
            }
          }
          target.push(objArray);
        }
      }
    }
  },
  loadLocalFilter(filter, localFilter) {
    var result = filter;
    if (localFilter) {
      result = JSON.parse(localFilter);
    }
    return result;
  },

  deliveryMessageOption() {
    return [
      {
        id: "1",
        name: "Çiçek_Servis",
      },
      {
        id: "4",
        name: "Hediye Kargo Aynı Gün",
      },
      {
        id: "5",
        name: "Hediye Kargo 1-3 İş Günü",
      },
      {
        id: "18",
        name: "Hediye Kargo 1-2 İş Günü",
      },
    ];
  },
  deliveryTypeOption() {
    return [
      {
        id: "1",
        name: "Servis Aracı İle Gönderim",
      },
      {
        id: "2",
        name: "Kargo İle Gönderim",
      },
      {
        id: "3",
        name: "Kargo+Servis Aracı İle Gönderim",
      },
    ];
  },
  addCacheCurrentFilter(copyFilter, componentName) {
    localStorage.setItem(
      `last_${componentName}_filter`,
      JSON.stringify(copyFilter)
    );
    localStorage.setItem(`last_${componentName}_current_page`, 1);
  },
  removeCacheCurrentFilter(componentName) {
    localStorage.removeItem(`last_${componentName}_filter`);
    localStorage.removeItem(`last_${componentName}_current_page`);
  },
  checkFilterStorage(componentName) {
    return localStorage.getItem(`last_${componentName}_filter`) ? true : false;
  },
  generatePassword(length) {
    let charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  },
  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  objectToFormData(obj, rootName, ignoreList) {
    var formData = new FormData();

    function appendFormData(data, root) {
      if (!ignore(root)) {
        root = root || "";
        if (data instanceof File) {
          formData.append(root, data);
        } else if (Array.isArray(data)) {
          if (data.length) {
            for (var i = 0; i < data.length; i++) {
              appendFormData(data[i], root + "[" + i + "]");
            }
          } else {
            formData.append(root, []);
          }
        } else if (typeof data === "object" && data) {
          for (var key in data) {
            if (
              data.hasOwnProperty(key) &&
              !ignore(key) &&
              typeof data[key] !== "function"
            ) {
              if (root === "") {
                appendFormData(data[key], key);
              } else {
                appendFormData(data[key], root + "[" + key + "]");
              }
            }
          }
        } else {
          if (typeof data !== "undefined") {
            if (data === null) data = "";
            formData.append(root, data);
          }
        }
      }
    }

    function ignore(root) {
      return (
        Array.isArray(ignoreList) &&
        ignoreList.some(function(x) {
          return x === root;
        })
      );
    }

    appendFormData(obj, rootName);

    return formData;
  },
  slugify(text) {
    if (text) {
      var trMap = {
        çÇ: "c",
        ğĞ: "g",
        şŞ: "s",
        üÜ: "u",
        ıİ: "i",
        öÖ: "o",
      };
      for (var key in trMap) {
        text = text.replace(new RegExp("[" + key + "]", "g"), trMap[key]);
      }
      return text
        .trim()
        .replace(/[^-a-zA-Z0-9\s]+/gi, "") // remove non-alphanumeric chars
        .replace(/\s/gi, "-") // convert spaces to dashes
        .replace(/[-]+/gi, "-") // trim repeated dashes
        .toLowerCase();
    }
  },
  dateToString(date, options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric'   }) {
    return new Date(date).toLocaleDateString("tr-tr", options)
  },
  paginateLinks(pages, currentPage) {
    var links = [];
    var approved = [];
    let center = Math.round(pages / 2) - 1;
    for (var i = 0; i < pages; i++) {
      if (pages > 6) {
        let difference = currentPage - i;
        let centerDifference = center - i;
        // around the current page
        if (!(difference < 0) && !(difference > 2)) {
          // around the center
        } else if (i === center) {
          // at the start or end
        } else if (pages - i <= 2 || i <= 1) {
          // everywhere else
        } else {
          continue;
        }
      }
      links.push({ page: i + 1 });
    }
    return links;
  },
  vatAmount(vat, price) {
    return price * (vat / 100);
  },
  vatExcludedPrice(vat, price) {
    return price / (1 + vat / 100);
  },
  /*
   * example: input = "1abcd" -> numberInput(input) -> input = 1
   */
  numberInput(value, decimal = true, options) {
    let result = "";
    const chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

    for (let i = 0; i < value.length; i++) {
      const char = value[i];
      const lastChar = result.charAt(result.length - 1);
      if (
        chars.includes(char) ||
        (decimal && char == "." && lastChar !== ".")
      ) {
        result += char;
      }
    }

    if (options) {
      if (options.max !== null) {
        // If result > max -> return max
        if (parseFloat(result) > options.max) {
          return options.max;
        }
      }
      if (options.min !== null) {
        // If result < min -> return min
        if (parseFloat(result) < options.min) {
          return options.min;
        }
      }
    }

    return result;
  },
  /*
   * examples:
   * ucfirst("TITLE", false) = Title
   * ucfirst("EXAMPLE TITLE", true) = Example Title
   */
  ucfirst(s, titleMode = false) {
    if (typeof s !== "string") return "";
    if (!titleMode) {
      return s.charAt(0).toLocaleUpperCase() + s.slice(1).toLocaleLowerCase();
    }
    return s
      .toLocaleLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toLocaleUpperCase() + word.substring(1))
      .join(" ");
  },
  /*
   * example: 000001
   */
  zeroPad(num, size) {
    var s = String(num);
    while (s.length < (size || 2)) {
      s = "0" + s;
    }
    return s;
  },
  /*
   * example: 1_String, 2_String, 3_String -> sort
   */
  sortNumericString(array, key) {
    const collator = new Intl.Collator("tr", {
      numeric: true,
      sensitivity: "base",
    });

    const sorted = array.sort((a, b) => collator.compare(a[key], b[key]));

    return sorted;
  },

  getTimerDiff(first, second, type = "minute") {
    let difference = new Date(first).getTime() - new Date(second).getTime();

    let division = 60000;

    return Math.round(difference / division);
  },
  addOrRemove(arr, val, key = null) {
    const index = arr.findIndex((A) => (key ? A[key] : A) == val);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(val);
    }
    return arr;
  },

  /**
   *
   * @param {*} object
   * @param {*} prefix for recursive
   * @returns
   */
  makeQueryString(obj, prefix) {
    let str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        let k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        if (Array.isArray(v) && !v.length) continue;
        if (v !== null || (typeof v == "string" && v.length)) {
          str.push(
            typeof v === "object"
              ? this.makeQueryString(v, k)
              : encodeURIComponent(k) + "=" + encodeURIComponent(v)
          );
        }
      }
    }
    return str && str.length ? str.join("&") : null;
  },

  clearItem(item, except = []) {
    let itemType = typeof item;

    if (except.includes(item)) {
      return;
    }

    switch (itemType) {
      case "object":
        Object.entries(item).forEach(([key, value]) => {
          if (!except.includes(key)) {
            if (value !== null) {
              item[key] = this.clearItem(item[key]);
            } else {
              item[key] = null;
            }
          }
        });
        break;

      case "array":
        item = [];
        break;

      case "string":
        item = null;
        break;
    }
  },

  exportSettings(name) {
    const exportData = [
      {
        name: "excel",
        responseType: "arraybuffer",
        contentType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      },
      {
        name: "csv",
        responseType: "arraybuffer",
        contentType: "text/csv;charset=UTF-8",
      },
      {
        name: "pdf",
        responseType: "arraybuffer",
        contentType: "application/pdf",
      },
      {
        name: "bulk-print",
        responseType: "blob",
        // contentType: "application/pdf"
      },
    ];

    if (name && name.length) {
      let foundExport = exportData.find((item) => item.name == name);
      return foundExport;
    }
  },

  /**
   *
   * @param {*} value
   * @returns
   */
  numberFormat(value) {
    return new Intl.NumberFormat().format(value);
  },

  /**
   * Trailing Slash
   * @param {string} url
   */
  formatUrl(url) {
    let lastCharSlash = url.slice(-1) == "/";
    let urlParts = url.split("/");
    let result = "";
    for (let i = 0; i < urlParts.length; i++) {
      if (i == urlParts.length - 1 && !lastCharSlash) {
        if (urlParts[i].indexOf(".") > -1) {
          // trailing slash not required
          result = url;
        } else {
          if (urlParts[i].indexOf("#") > -1) {
            // trailing slash not required
            result = url;
          } else {
            //Assume this is a folder and add a slash
            result = url + "/";
          }
        }
      }
    }
    return result;
  },
  setPrice(value = 0) {
    return {
      price: value,
      unmask: 0,
    };
  },
  difference(object, base, except = []) {
    function changes(object, base, except = []) {
      return _.transform(object, function(result, value, key) {
        if (_.includes(except, key)) {
          return (result[key] = value);
        }
        if (!_.isEqual(value, base[key]) || _.includes(except, key)) {
          result[key] =
            _.isObject(value) && _.isObject(base[key])
              ? changes(value, base[key])
              : value;
        }
      });
    }
    return changes(object, base, except);
  },
  isURL(string) {
    let url;

    try {
      url = new URL(string);
    } catch (_) {
      return false;
    }

    return url.protocol === "http:" || url.protocol === "https:";
  },
  hashCode(str) {
    let hash = 0;
    for (let i = 0, len = str.length; i < len; i++) {
      let chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  },
  toText(value) {
    let div = document.createElement("div");
    div.innerHTML = value;

    let text = div.textContent || div.innerText || "";
    return text.replace(/(\r\n|\n|\r|\s\s+)/gm, " ");
  },
  frequencyChecker(string) {
    const matchedWords = string.split(" ");

    const result = matchedWords.reduce(function(stats, word) {
      if (word && word.length) {
        if (stats.hasOwnProperty(word)) {
          stats[word] = stats[word] + 1;
        } else {
          stats[word] = 1;
        }
        return stats;
      }
      return stats;
    }, {});

    return result;
  },
  priceToNumber(v) {
    if (!v) {
      return 0;
    }
    v = v.split(".").join("");
    v = v.split(",").join(".");
    return Number(v.replace(/[^0-9.]/g, ""));
  },
  timestampToDate(timestamp) {
    var d = new Date(timestamp);
    let result =
      d.getFullYear() +
      "-" +
      ("00" + (d.getMonth() + 1)).slice(-2) +
      "-" +
      ("00" + d.getDate()).slice(-2) +
      " " +
      ("00" + d.getHours()).slice(-2) +
      ":" +
      ("00" + d.getMinutes()).slice(-2) +
      ":" +
      ("00" + d.getSeconds()).slice(-2);
    return result;
  },
  getFavicon(url) {
    return `https://www.google.com/s2/favicons?domain=${url}`;
  },
  urlParser(url) {
    if (url && this.isURL(url)) {
      var parser = document.createElement("a");
      parser.href = url;

      return {
        protocol: parser.protocol, // => "http:"
        host: parser.host, // => "example.com:3000"
        hostname: parser.hostname, // => "example.com"
        port: parser.port, // => "3000"
        pathname: parser.pathname, // => "/pathname/"
        hash: parser.hash, // => "#hash"
        search: parser.search, // => "?search=test"
        origin: parser.origin, // => "http://example.com:3000"
      };
    }
  },
};
