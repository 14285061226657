import api from "../../shared/api";
import requestPage from "../../models/request/page";
import responsePage from "../../models/response/page";
export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      state.lang = "tr";
      state.list = [];
      helper.copy(result.data.items, state.list);
    },
    updateLang(state,lang){
      state.lang = lang
    },
    clearList(state){
      state.lang = "tr"
      state.list = []
    }
  },
  actions: {
    load({ commit }) {
      commit("setInformation");
    },
    create({ state }, data) {
      api.post(
        `pages?lang=${state.lang}`,
        requestPage.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      if (data && data.id) {
        api.put(
          `pages/${data.id}?lang=${state.lang}`,
          requestPage.new(data.form),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    delete({ state }, data) {
      if (data && data.id) {
        api.delete(
          "pages/" + data.id,
          (result) => {
            if (result && result.data && result.data.status !== "error") {
              helper.arrayRemove(state.list, "id", data.id);
            }
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    restore({}, data) {
      api.restore(
        "pages/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "pages/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit,state }, data) {
      commit("clearList")
      let route = "all/pages";

      if(data.lang) commit("updateLang",data.lang)

      route += `?lang=${state.lang}`;

      if (data && data.deleted) {
        route += "&deleted_at=1";
      }

      api.get(
        route,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `pages/${data.id}?lang=${state.lang}`,
        (result) => {
          let resultData = responsePage.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
