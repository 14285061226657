
export default {
  new(item) {
    this.name = item.name;
    this.slug = item.slug;
    this.is_active = item.is_active === 1;
    this.description = {
      text: item.description,
      show: item.show_description === 1,
    };
    return this;
  },

  image: {
    files: [],
    filesData: null,
  },
};
