import helper from "../../shared/helper";
import localization from "../../shared/localization";
import { numberFormat } from "./_numberFormat";

let localeFormatter = () => localStorage.getItem("_lang_code") || "tr-TR";

const globalFilters = {
  ucfirst: (value) => helper.ucfirst(value),
  numberFormat: (value) => numberFormat(value, localeFormatter()),
  toLowerCase: (value) => value.toLocaleLowerCase(localeFormatter()),
  toUpperCase: (value) => value.toLocaleUpperCase(localeFormatter()),
  dateFormat: (value) => new Date(value).toLocaleString(localeFormatter()),
  moneyFormat: (value, currency) =>
    localization.getMoneyFormat(currency || null, value),
  capitalizeFirstLetter: (value) => helper.capitalizeFirstLetter(value),
  toFixed: (value, digits = 2) => Number(value).toFixed(digits),
};

export default {
  install(Vue) {
    Object.entries(globalFilters).forEach(([name, filter]) =>
      Vue.filter(name, filter)
    );
    Vue.prototype.$filters = globalFilters;
  },
};
