import imageRequest from "../../models/request/image";
import metaRequest from "../../models/request/meta";
export default {
  new(item) {
    let result = {};

    if (item.title) result.title = item.title;
    if (item.slug) result.slug = item.slug;
    if (item.content) result.content = item.content;
    if (item.hasOwnProperty("is_active"))
      result.is_active = item.is_active ? 1 : 0;
    if (item.hasOwnProperty("published_at"))
      result.published_at = item.published_at;

    result.image = imageRequest(item.image.files, false);
    result = { ...result, ...metaRequest(item.meta) };

    return result;
  },
};
