import api from "../../shared/api";

export default {
  state: () => ({
    list: [],
    firmList: [],
    insuranceList: [],
  }),
  actions: {
    getCargoCompanies({ state }, data) {
      api.get(
        "all/cargo-companies",
        (result) => {
          state.list = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCargoFirm({ state }, data) {
      let route = "all/cargos";
      api.get(
        route,
        (result) => {
          state.firmList = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCargoInsurances({ state }, data) {
      api.get(
        "cargo-insurances",
        (result) => {
          state.insuranceList = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getAvailableCargoFirm({}, data) {
      let route = "all/cargos";

      if (data && data.hasOwnProperty("order_id")) {
        route += `?order_id=${data.order_id}`;
      }

      api.get(
        route,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
