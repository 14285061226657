import api from "../../shared/api";
import helper from "../../shared/helper";
import requestOrder from "../../models/request/order";

export default {
  namespaced: true,
  state: () => ({
    orderTypes: [],

    paymentTypes: [],

    new: {
      searchCustomer: "",
      searchProduct: "",

      customer: {},

      address: {
        shipment: "",
        invoice: "",
        same: false,
      },

      gift: {
        status: false,
        text: null,
      },

      type: {
        customer: -1,
        payment: -1,
        order: -1,
      },

      sendLink: false,
      products: [],
      totalPrice: 0,
    },
    list: [],
  }),
  mutations: {
    setPaymentTypes(state, payload) {
      const items = payload.data.items;
      state.paymentTypes = items;
      state.new.type.payment = items.findIndex(
        (pt) => pt.id == payload.config["site.payment_type"]
      );
    },
    setOrderTypes(state, payload) {
      const items = payload.data.items;
      state.orderTypes = items;
      state.new.type.order = state.new.type.order
        ? state.new.type.order
        : state.orderTypes[0].id;
    },
    selectCustomer(state, payload) {
      const newState = state.new;

      newState.customer = payload;
      newState.searchCustomer = "";
    },
    removeCustomer(state) {
      state.new.customer = {};
      state.new.address = {
        shipment: {
          contact_type: null,
          country: null,
          city: null,
          district: null,
          neighborhood: null,
          postal_code: null,
          address: null,

          // info
          email: null,
          first_name: null,
          last_name: null,
          phone: null,
          gsm: null,
        },
        invoice: {
          contact_type: null,
          country: null,
          city: null,
          district: null,
          neighborhood: null,
          postal_code: null,
          address: null,

          // info
          email: null,
          first_name: null,
          last_name: null,
          phone: null,
          gsm: null,
          tcn: null,
          vkn: null,
          tax_office: null,
          company: null,
        },
        same: false,
      };
    },
    setInformation(state, payload) {
      const item = payload.data.item;
      const config = payload.config;
      const vatAmount = config["site.vat"] || 18;

      let newState = state.new;

      newState.customer = {
        id: 1,
        type: 0,
        full_name: item.invoice_first_name + " " + item.invoice_last_name,
        first_name: item.invoice_first_name,
        last_name: item.invoice_last_name,
        email: item.invoice_email,
        gsm: item.invoice_gsm,
        tcn: item.invoice_tcn,
        vkn: item.invoice_vkn,
        tax_office: item.invoice_tax_office,
        company: item.invoice_company,
      };

      newState.address = {
        shipment: {
          contact_type: null,
          country: item.shipment_country,
          city: item.shipment_city,
          district: item.shipment_district,
          neighborhood: item.shipment_neighborhood,
          postal_code: item.shipment_postal_code,
          address: item.shipment_address,

          email: item.shipment_email,
          first_name: item.shipment_first_name,
          last_name: item.shipment_last_name,
          phone: item.shipment_phone,
          gsm: item.shipment_gsm,
        },
        invoice: {
          country: item.invoice_country,
          city: item.invoice_city,
          district: item.invoice_district,
          neighborhood: item.invoice_neighborhood,
          postal_code: item.invoice_postal_code,
          address: item.invoice_address,

          email: item.invoice_email,
          first_name: item.invoice_first_name,
          last_name: item.invoice_last_name,
          phone: item.invoice_phone,
          gsm: item.invoice_gsm,
          tcn: item.invoice_tcn,
          vkn: item.invoice_vkn,
          tax_office: item.invoice_tax_office,
          company: item.invoice_company,
        },
        same: item.invoice_address == item.shipment_address,
      };

      newState.cargoCompany = item.cargo ? item.cargo.id : null;

      newState.gift = {
        status: item.gift_wrap === 1,
        text: item.gift_note,
      };

      newState.type = {
        customer: item.orderable_type,
        payment: state.paymentTypes.findIndex(
          (pt) => pt.id == item.payment_type_id
        ),
        order: item.order_type_id,
      };

      newState.sendLink = false;
      newState.products = item.products.map((product) => {
        product.category =
          product.categories && product.categories.length
            ? product.categories[0].name
            : null;
        delete product.categories;
        // if (config["site.vat_type"] == "included") {
        //   product.price = helper.vatExcludedPrice(vatAmount, product.price);
        // }
        product.quantity_type = product.quantity_type
          ? product.quantity_type.name
          : null;
        return product;
      });
      newState.order_no = item.order_no;
      newState.totalPrice = item.total_price;
    },
    clearInformation(state) {
      state.new = {
        searchCustomer: "",
        searchProduct: "",

        customer: {},

        address: {
          shipment: {
            contact_type: null,
            country: null,
            city: null,
            district: null,
            neighborhood: null,
            postal_code: null,
            address: null,

            // info
            email: null,
            first_name: null,
            last_name: null,
            phone: null,
            gsm: null,
          },
          invoice: {
            contact_type: null,
            country: null,
            city: null,
            district: null,
            neighborhood: null,
            postal_code: null,
            address: null,

            // info
            email: null,
            first_name: null,
            last_name: null,
            phone: null,
            gsm: null,
            tcn: null,
            vkn: null,
            tax_office: null,
            company: null,
          },
          same: false,
        },

        gift: {
          status: false,
          text: null,
        },

        type: {
          customer: 0,
          payment: -1,
          order: "",
        },
        cargoCompany: null,
        sendLink: false,
        products: [],
        currency: {},
        totalPrice: 0,
        order_no: helper.getRandomInteger(1, 999999),
      };
    },

    selectShipmentAddress(state, member) {
      const customerAddress = state.new.customer.contacts;
      if (customerAddress && customerAddress.length) {
        if (customerAddress.hasOwnProperty(member)) {
          state.new.address.shipment.postal_code =
            customerAddress[member].neighborhood.code;
          helper.copy(customerAddress[member], state.new.address.shipment);
        }
      }
    },
    selectInvoiceAddress(state, member) {
      const customerAddress = state.new.customer.contacts;

      if (customerAddress && customerAddress.length) {
        if (customerAddress.hasOwnProperty(member)) {
          state.new.address.invoice.postal_code =
            customerAddress[member].neighborhood.code;
          helper.copy(customerAddress[member], state.new.address.invoice);
        }
      }
    },
    updateList(state, items) {
      if (items) {
        items.forEach((element) => {
          element.note_count = element.notes && element.notes.length;
          element.name = element.member_name || null;
          element.payment_type = element.payment_type
            ? element.payment_type.name
            : null;
          element.status = element.status?.name || "Belirsiz";
          element.cargo_name = element.cargo?.name;

          const cargo = element.cargo;
          let cargoCompanyName = cargo?.name;
          if (cargo && cargo.cargo_company) {
            cargoCompanyName = cargo.cargo_company.name;
          }

          element.cargo_company_name = cargoCompanyName;
          element.shipment_city = element.shipment_city?.name;

          if (element.invoice_status == 1 && element.invoice_message) {
            element.invoice_message = "Fatura Oluşturuldu";
          }
          if (element.hasOwnProperty("source")) {
            element.source = element.source.logo;
          } else {
            element.source =
              JSON.parse(localStorage.getItem("session")).config["site.logo"] ||
              null;
          }
        });

        state.list = items;
      } else {
        state.list = [];
      }
    },
  },
  actions: {
    load({ commit, rootState }) {
      this.dispatch("shared/getCargoFirm");
      this.dispatch("order/getPaymentTypes");
      this.dispatch("order/getOrderTypes");
      commit("clearInformation");
    },
    create({ state }, data) {
      api.post(
        "orders",
        requestOrder.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    createWithOutOrder({ state }, data) {
      api.post(
        "with-out-order",
        { ...data },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        "orders/" + data.id,
        requestOrder.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    setCargoOrder({}, data) {
      api.post(
        "orders/cargo-set-order/" + data.id,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getTrackingNumber({}, data) {
      api.post(
        "orders/cargo-tracking-number/" + data.id,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    updateCargoInformation({}, data) {
      if (data.id) {
        api.post(
          "orders/update-cargo-info/" + data.id,
          data.form,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    delete({ state }, data) {
      api.delete(
        "orders/" + data.id,
        (result) => {
          helper.arrayRemove(state.list, "id", data.id);
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit, rootState, state }, data) {
      api.get(
        "orders/" + data.id,
        (result) => {
          // result.config = rootState.session.config;
          // commit("setInformation", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/orders?";
      let queryString = { ...data.filter, ...data.table };

      if (data.hasOwnProperty("filter_start")) {
        routePath += `${data.filter_start}&`;
      }

      routePath += helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getPaymentTypes({ commit, rootState }, data) {
      api.get(
        "all/payment-types",
        (result) => {
          result.config = rootState.session.config;
          commit("setPaymentTypes", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getOrderTypes({ commit }, data) {
      api.get(
        "all/order-types",
        (result) => {
          commit("setOrderTypes", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    selectCustomerAndAddress({ commit, state }, data) {
      api.get(
        "members/" + data.id,
        (result) => {
          commit("selectCustomer", result.data.item);
          commit("shared/updateContactable", result.data.item, { root: true });
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    removeCustomerAndAddress({ commit }) {
      commit("removeCustomer");
      commit("shared/updateContactable", null, { root: true });
    },
    updateOrderStatus({}, data) {
      const requestData = data.request;
      api.post(
        "orders/update-type/" + requestData.order,
        {
          order_type_id: requestData.status,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    sendERP({}, data) {
      api.post(
        "order-erp/" + data.order_id,
        null, // request data
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    sendInvoice({}, data) {
      api.post(
        "send-invoice/" + data.order_id,
        null, // request data
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    printInvoice({}, data) {
      api.download(
        "invoice-pdf/" + data.order_id,
        {
          responseType: "arraybuffer",
          contentType: "application/pdf",
        },
        (result) => {
          let blob = new Blob([result.data], {
            encoding: "UTF-8",
            type: "arraybuffer",
          });

          saveAs(blob, `${data.fileName}.pdf`);

          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    updateIsOk({}, data) {
      api.post(
        "orders/update-is-ok/" + data.order_id,
        null, // request data
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    createOrderNote({}, data) {
      api.post(
        "order-notes",
        {
          note: data.note,
          order_id: data.order_id,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    deleteOrderNote({}, data) {
      api.post(
        "delete-order-notes",
        { ids: data.selected },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
