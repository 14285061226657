import imageRequest from "../../models/request/image";
import metaRequest from "./meta";

export default {
  new(form) {
    let result = {};
    if (form.name) {
      result.name = form.name;
    }
    if (form.slug) {
      result.slug = form.slug;
    }
    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    if (form.selected && form.selected.hasOwnProperty("brand")) {
      result.brand_id = form.selected.brand;
    }
    if (form.description && form.description.text) {
      result.description = form.description.text;
    }
    if (form.description && form.description.hasOwnProperty("show")) {
      result.show_description = form.description.show === true ? 1 : 0;
    }
    if (form.description && form.description.place) {
      result.description_place = form.description.place;
    }

    if (form.title) {
      result.title = form.title;
    }
    if (form.sub_title) {
      result.sub_title = form.sub_title;
    }
    if (form.embed_url) {
      result.embed_url = form.embed_url;
    }

    result.image = imageRequest(form.image.files, false);
    if(form.mobile_image) {
      result.mobile_image = imageRequest(form.mobile_image.files, false);
    }
    if(form.cover_image) {
      result.cover_image = imageRequest(form.cover_image.files, false);
    }

    result = { ...result, ...metaRequest(form.meta) };

    return result;
  },
  image: {
    files: [],
    filesData: null,
  },
  mobile_image: {
    files: [],
    filesData: null,
  },
  cover_image: {
    files: [],
    filesData: null,
  },
};
