import Vue from "vue";
import Vuex from "vuex";
import address from "./address/index";
import bankaccount from "./payment/bankaccount";
import banner from "./banner/index";
import blog from "./blog/index";
import brand from "./brand/index";
import brandType from "./brandType/index";
import bulkSeo from "./bulkseo/index";
import cacheClear from "./cacheClear/index";
import campaign from "./campaign/index";
import cargo from "./cargo/index";
import cargoinsurance from "./cargoinsurance/index";
import category from "./category/index";
import collection from "./payment/collection";
import comment from "./comment/index";
import contract from "./contract/index";
import coupon from "./coupon/index";
import crossproduct from "./crossproduct/index";
import deliveryTime from "./deliverytime/index";
import deliveryZone from "./deliveryzone/index";
import emailList from "./emailList/index";
import emailTemplate from "./emailTemplate/index";
import equipment from "./equipment/index";
import erp from "./erp/index";
import errorpage from "./errorpage/index";
import feature from "./feature/index";
import homeSeo from "./homeSeo/index";
import imageUploader from "./imageUploader/index";
import invoiceIntegration from "./invoiceIntegration/index";
import listexport from "./listexport/index";
import listimport from "./listimport/index";
import live from "./live/index";
import logo from "./logo/index";
import marketplace from "./marketplace/index";
import member from "./member";
import membergroup from "./membergroup";
import membertype from "./membertype";
import menu from "./menu/index";
import model from "./model/index";
import order from "./order/index";
import orderDetailModal from "./orderdetail/index";
import packages from "./package/index";
import page from "./page/index";
import popup from "./popup/index";
import pos from "./payment/pos";
import priceAnalysis from "./priceAnalysis/index";
import product from "./product/index";
import productType from "./producttype/index";
import reports from "./reports/index.js";
import role from "./role/index";
import serviceRegion from "./serviceRegion/index";
import session from "./session/index";
import setup from "./setup/index";
import shared from "./shared/index";
import shop from "./shop/index";
import showcaseManagement from "./showcaseManagement";
import slider from "./slider/index";
import supplier from "./supplier/index";
import tableExport from "./export/index";
import themes from "./themes/index";
import toptitle from "./toptitle/index";
import translationSetting from "./translationSetting/index";
import user from "./user/index";
import widget from "./widget/index";
import xmlTemplate from "./xmlTemplate/index";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    shared,
    session,
    menu,
    brand,
    brandType,
    blog,
    banner,
    contract,
    product,
    crossproduct,
    packages,
    equipment,
    order,
    member,
    membertype,
    membergroup,
    category,
    campaign,
    page,
    feature,
    model,
    marketplace,
    user,
    role,
    slider,
    widget,
    coupon,
    comment,
    address,
    bankaccount,
    pos,
    collection,
    cargo,
    logo,
    errorpage,
    erp,
    toptitle,
    popup,
    cargoinsurance,
    reports,
    productType,
    deliveryZone,
    orderDetailModal,
    tableExport,
    deliveryTime,
    bulkSeo,
    listexport,
    listimport,
    priceAnalysis,
    invoiceIntegration,
    shop,
    showcaseManagement,
    translationSetting,
    setup,
    themes,
    live,
    cacheClear,
    homeSeo,
    serviceRegion,
    supplier,
    xmlTemplate,
    emailTemplate,
    emailList,
    imageUploader,
  },
});
