export default {
  new(data) {
    this.name = data.name;
    this.code = data.code;
    this.is_active = data.is_active === true ? 1 : 0;
    this.only_member = data.only_member === true ? 1 : 0;
    this.startDate = data.startDate;
    this.dueDate = data.dueDate;
    this.discount_type_id = data.discount.type;
    this.discount_amount = data.discount.amount;

    if (data.limit.type_id && data.limit.type !== "-1" && data.limit.amount) {
      this.limit_type_id = data.limit.type_id;
      this.limit_amount = data.limit.amount;
    } else {
      this.limit_type_id = null;
    }

    this.currency_code_id =
      data.limit.currency !== -1 ? data.limit.currency : null;
    this.cart_price = data.cart_price.unmask;

    return this;
  },
  name: null,
  code: null,
  is_active: 0,
  only_member: 0,
  cart_price: 0,
  startDate: null,
  dueDate: null,
  discount_type_id: null,
  discount_amount: null,
  currency_code_id: null,
};
