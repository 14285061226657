import api from "../../shared/api";

export default {
  namespaced: true,
  state: () => ({}),
  actions: {
    createZone({ state }, data) {
      api.post(
        `live/create-zone/${data.domain}`,
        {},
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    completedNameServer({ state }, data) {
      api.post(
        `live/completed-ns`,
        {},
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
