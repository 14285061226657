export default {
  new(data) {
    this.name = data.name; // Feature Name
    this.values = helper.sortNumericString(data.values, "name"); // Added values
    this.value = {
      name: null,
      code: null,
    };
    return this;
  },
};
