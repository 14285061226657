import imageRequest from "../../models/request/image";

export default {
  format(date) {
    try {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const hours = date
        .getHours()
        .toString()
        .padStart(2, "0");
      const minutes = date
        .getMinutes()
        .toString()
        .padStart(2, "0");
      return `${year}-${month}-${day} ${hours}:${minutes}`;
    } catch (ex) {
      return date;
    }
  },
  new(form) {
    let result = {};

    if (form.name) {
      result.name = form.name;
    }
    if (form.slug) {
      result.slug = form.slug;
    }
    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    if (form.email) {
      result.email = form.email;
    }
    if (form.phone) {
      result.phone = form.phone;
    }
    if (form.city) {
      result.city_id = form.city.id;
    }
    if (form.district) {
      result.district_id = form.district.id;
    }
    if (form.address) {
      result.address = form.address;
    }
    if (form.location) {
      result.location = form.location;
    }
    if (form.working_dates) {
      result.working_dates = form.working_dates.map((item) => {
        item.open = this.format(item.open);
        item.close = this.format(item.close);
        return item;
      });
    }

    result.image = imageRequest(form.image.files, false);
    result.cover_image = imageRequest(form.cover_image.files, false);

    return result;
  },
};
