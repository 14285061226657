import constants from "../constant/index";

import axios from "axios";
export default {
  setHeaders(data) {
    if (data && data.token && this.getConfigUser()) {
      this.headers = {
        Authorization: "Bearer " + data.token,
      };
    } else {
      this.headers = null;
    }
  },
  login(username, password, onSuccess, onFail, onError, onFinish) {
    axios
      .post(constants.NODE_API_URL + "auth", {
        username: username,
        password: password,
      })
      .then((response) => {
        if (response.data && !response.data.error) {
          if (onSuccess) onSuccess(response.data);
        } else {
          if (onFail) onFail(response);
        }
      })
      .catch((error) => {
        this.processErrors(error, "post", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  logout(onSuccess, onError, onFinish) {
    if (onFinish) onFinish();
    //   axios
    // .post(constants.API_URL + "logout", null, {
    //   headers: this.headers,
    // })
    // .then((response) => {
    //   if (onSuccess) onSuccess(response);
    // })
    // .catch((error) => {
    //   this.processErrors(error, "logout", onError);
    // })
    // .then(() => {
    //   if (onFinish) onFinish();
    // });
  },
  get(address, onSuccess, onError, onFinish) {
    axios
      .get(constants.NODE_API_URL + address, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "post", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  post(address, data, onSuccess, onError, onFinish) {
    const requestData = data;
    axios
      .post(constants.NODE_API_URL + address, requestData, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "post", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  delete(address, data, onSuccess, onError, onFinish) {
    axios
      .delete(constants.NODE_API_URL + address, {
        data: { id: data },
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "delete", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  put(address, data, onSuccess, onError, onFinish) {
    axios
      .put(constants.NODE_API_URL + address, data, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "put", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  processErrors(error, sender, onError) {
    if (error) {
      if (error.response) {
        let status = error.response.status;
        switch (status) {
          case 401:
            // if (this.getConfigUser()) {
            //   this.refreshLogin();
            // }
            break;

          case 403:
            break;

          case 404:
            break;

          default:
            console.warn("Bilinmeyen Hata - Hata Kodu: " + status);
            break;
        }
      } else {
        console.warn(error, "İÇ HATA: node_api=>" + sender);
      }
    } else {
      console.warn("Bilinmeyen Hata");
    }

    if (onError) onError(error.response);
  },
  getConfigUser() {
    let result = null;

    const stored = JSON.parse(localStorage.getItem("session"));
    if (stored) {
      const config = stored.config;
      if (config) {
        const nodeUsername = config["competitor_price_analysis.username"];
        const nodePassword = config["competitor_price_analysis.password"];
        if (nodeUsername && nodePassword) {
          result = {
            username: nodeUsername,
            password: nodePassword,
          };
        }
      }
    }
    return result;
  },
  refreshLogin() {
    const userData = this.getConfigUser();
    const stored = JSON.parse(localStorage.getItem("session"));
    if (userData) {
      this.login(userData.username, userData.password, (result) => {
        stored.priceAnalysis = {
          token: result.token,
          email: result.notificationEmail,
          productLimit: result.productLimit,
          competitorLimit: result.competitorLimit,
        };
        this.setHeaders(stored.priceAnalysis);
        localStorage.setItem("session", JSON.stringify(stored));
      });
    }
  },
};
