import axios from "axios";
import constants from "../constant/index";
import requestLogin from "../../models/request/login";
import router from "../../router/index";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("_lang") || "tr";

export default {
  headers: null,
  setHeaders(data) {
    if (data && data.accessToken) {
      this.headers = {
        Authorization: "Bearer " + data.accessToken,
      };
    } else {
      this.headers = null;
    }
  },
  login(email, password, onSuccess, onFail, onError, onFinish) {
    axios
      .post(constants.API_URL + "login", requestLogin.new(email, password))
      .then((response) => {
        if (response.data && !response.data.error) {
          let currentDateTime = new Date().getTime();
          let expireDateTime = new Date(
            currentDateTime + response.data.expires_in * 1000
          ).getTime();

          let result = {};
          result.accessToken = response.data.access_token;
          result.expires = expireDateTime;
          result.refreshToken = response.data.refresh_token;
          result.user = response.data.user;
          result.config = response.data.config;
          result.contactTypes = response.data.contactTypes;
          result.roles = response.data.roleGroups
            ? response.data.roleGroups.roles
            : [];

          if (onSuccess) onSuccess(result);
        } else {
          if (onFail) onFail(response);
        }
      })
      .catch((error) => {
        this.processErrors(error, "login", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  logout(onSuccess, onError, onFinish) {
    if (onFinish) onFinish();
    //   axios
    // .post(constants.API_URL + "logout", null, {
    //   headers: this.headers,
    // })
    // .then((response) => {
    //   if (onSuccess) onSuccess(response);
    // })
    // .catch((error) => {
    //   this.processErrors(error, "logout", onError);
    // })
    // .then(() => {
    //   if (onFinish) onFinish();
    // });
  },
  get(address, onSuccess, onError, onFinish) {
    axios
      .get(constants.API_URL + address, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "get", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  post(address, data, onSuccess, onError, onFinish) {
    const requestData = this.getRequestData(data);
    axios
      .post(constants.API_URL + address, requestData, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "post", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  put(address, data, onSuccess, onError, onFinish) {
    const requestData = this.getRequestData(data, true);
    axios
      .post(constants.API_URL + address, requestData, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "put", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  delete(address, onSuccess, onError, onFinish) {
    axios
      .delete(constants.API_URL + address, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "delete", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  restore(address, onSuccess, onError, onFinish) {
    axios
      .post(constants.API_URL + "restore/" + address, null, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "restore", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  remove(address, onSuccess, onError, onFinish) {
    axios
      .delete(constants.API_URL + "remove/" + address, {
        headers: this.headers,
      })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "remove", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
  processErrors(error, sender, onError) {
    if (error) {
      if (error.response) {
        let status = error.response.status;
        switch (status) {
          case 401:
            router.push("/logout");
            break;

          case 403:
            router.push("/access-denied");
            break;

          case 404:
            router.push("/not-found");
            break;

          default:
            console.warn("Bilinmeyen Hata - Hata Kodu: " + status);
            break;
        }
      } else {
        console.warn(error, "İÇ HATA: api=>" + sender);
      }
    } else {
      console.warn("Bilinmeyen Hata");
    }

    if (onError) onError(error);
  },
  getRequestData(data, update = false) {
    if (data) {
      if (update) {
        Object.assign(data, { _method: "PUT" });
      } else {
        if (data["_method"]) {
          delete data["_method"];
        }
      }

      const imageKeys = [
        "image",
        "images",
        "media",
        "icon",
        "variantMedia",
        "file",
        'tr_desktop',
        'en_desktop',
        'tr_mobile',
        'en_mobile',
        'rolex_desktop',
        'rolex_mobile',
      ];
      let postFormData = false;

      imageKeys.forEach((key) => {
        if (data.hasOwnProperty(key) && data[key] && data[key] !== null) {
          postFormData = true;
        }
      });

      if (postFormData) {
        return helper.objectToFormData(data, null, ["fileData", "imageData"]);
      } else {
        return data;
      }
    } else {
      return "";
    }
  },
  download(address, config, onSuccess, onError, onFinish) {
    axios({
      url: constants.API_URL + address,
      method: "GET",
      responseType: config.responseType,
      headers: {
        ...this.headers,
        "Content-Type": config.contentType,
      },
    })
      .then((response) => {
        if (onSuccess) onSuccess(response);
      })
      .catch((error) => {
        this.processErrors(error, "download", onError);
      })
      .then(() => {
        if (onFinish) onFinish();
      });
  },
};
