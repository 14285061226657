import api from "../../shared/api";

export default {
  state: () => ({
    currencies: [],
  }),
  actions: {
    getCurrencyList({ state }, data) {
      api.get(
        "all/currency-codes",
        (result) => {
          state.currencies = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
