import globalComponents from "./globalComponents.js";
import globalDirectives from "./globalDirectives.js";
import globalFilters from "./filter";

export default {
  install(Vue) {
    Vue.use(globalComponents);
    Vue.use(globalDirectives);
    Vue.use(globalFilters);
  },
};
