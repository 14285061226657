const components = {
  WeAppContainer: () => import("../components/layout/WeAppContainer/Index"),
  WeAppHeader: () => import("../components/layout/WeAppHeader/Index"),
  WeAppFooter: () => import("../components/layout/WeAppFooter/Index"),
  WeAppSupport: () => import("../components/layout/WeAppSupport/Index"),
  WeContainer: () => import("../components/layout/WeContainer/Index"),
  WeContent: () => import("../components/layout/WeContent/Index"),
  WeCard: () => import("../components/layout/WeCard/Index"),
  WeCenter: () => import("../components/layout/WeCenter/Index"),
  WeSeoBar: () => import("../components/layout/WeSeoBar/Index"),
  WeDescription: () => import("../components/layout/WeDescription/Index"),
  WeMetaData: () => import("../components/layout/WeMetaData/Index"),
  WeMentionData: () => import("../components/layout/WeMentionData/Index"),
  WeCategory: () => import("../components/layout/WeCategory/Index"),
  WeProduct: () => import("../components/layout/WeProduct/Index"),
  WeTimeLine: () => import("../components/layout/WeTimeLine/Index"),
  WeBrand: () => import("../components/layout/WeBrand/Index"),
  WeInput: () => import("../components/layout/WeForm/WeInput/Index"),
  WePriceInput: () => import("../components/layout/WeForm/WePriceInput/Index"),
  WeSwitch: () => import("../components/layout/WeForm/WeSwitch/Index"),
  WeRadioButton: () =>
    import("../components/layout/WeForm/WeRadioButton/Index"),
  WeSearchInput: () =>
    import("../components/layout/WeForm/WeSearchInput/Index"),
  WeLimitInput: () => import("../components/layout/WeForm/LimitInput/Index"),
  WeSlugCheck: () => import("../components/layout/WeForm/WeSlugCheck/Index"),
  WePhone: () => import("../components/layout/WeForm/WePhone/Index"),
  WeRowInput: () => import("../components/layout/WeForm/WeRowInput/Index"),
  WeCargoForm: () => import("../components/layout/WeForm/WeCargoForm/Index"),
  WeSubmitButton: () =>
    import("../components/layout/WeForm/WeSubmitButton/Index"),
  WeSelect: () => import("../components/layout/WeForm/WeSelect/Index"),
  WeCondition: () => import("../components/layout/WeCondition/Index"),
  WeSettingCard: () => import("../components/layout/WeSettingCard/Index"),
  WePicture: () => import("../components/layout/WePicture/Index"),
  WeWidget: () => import("../components/layout/WeWidget/Index"),
  WeSeoFilter: () => import("../components/layout/WeSeoFilter/Index"),
  WeDivSeperator: () => import("../components/layout/WeDivSeperator/Index"),
  WeStatsCard: () => import("../components/layout/WeStatsCard/Index"),
  WeFailedOrderBadge: () =>
    import("../components/layout/WeFailedOrderBadge/Index"),
  WeMultipleDescription: () =>
    import("../components/layout/WeMultipleDescription/Index"),
  WeFileUploader: () => import("../components/shared/WeFileUploader/Index"),
  WeImageUploader: () => import("../components/shared/WeImageUploader/Index"),
  WeLoading: () => import("../components/shared/WeLoading/Index"),
  WeModal: () => import("../components/shared/WeModal/Index"),
  WeTable: () => import("../components/shared/WeTable/Index"),
  WeTableSearch: () => import("../components/shared/WeTableSearch/Index"),
  WeFeature: () => import("../components/shared/WeFeature/Index"),
  ConditionButton: () => import("../components/shared/ConditionButton/Index"),
  WeEditor: () => import("../components/shared/WeEditor/Index"),
  WePhoneMaskInput: () => import("../components/shared/WePhoneMaskInput/Index"),
  WeOrderDetail: () => import("../components/shared/WeOrderDetail/Index"),
  WeDeliveryTime: () => import("../components/shared/WeDeliveryTime/Index"),
  WeListExport: () => import("../components/shared/WeListExport/Index"),
  WeBulkImport: () => import("../components/shared/WeBulkImport/Index"),
  WeBulkEquipmentCross: () => import("../components/shared/WeBulkEquipmentCross/Index"),
  WeLanguageSwitcher: () =>
    import("../components/shared/WeLanguageSwitcher/Index"),
  WeMarketplacePrice: () =>
    import("../components/shared/WeMarketplacePrice/Index"),
  WeSetup: () => import("../components/shared/WeSetup/Index"),
  WeAppLicenseBar: () => import("../components/layout/WeAppLicenseBar/Index"),
  WeLiveForm: () => import("../components/layout/WeLiveForm/Index"),
  WePageEditor: () => import("../components/layout/WePageEditor/Index"),
  WeSidebarMenu: () => import("../components/layout/WeSidebarMenu/Index"),
};

const customPackages = {
  WeDatePicker: () => import("vue-ctk-date-time-picker"),
  SkeletonLoader: () => import("skeleton-loader-vue"),
  StarRating: () => import("vue-star-rating"),
  vSelect: () => import("vue-select"),
};

const globalComponents = { ...components, ...customPackages };

// CSS Files

import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
import "vue-select/src/scss/vue-select.scss";

export default {
  install(Vue) {
    Object.entries(globalComponents).forEach(([name, component]) =>
      Vue.component(name, component)
    );
  },
};
