export default {
  new(state) {
    const newState = state.new;
    this.name = newState.name;
    this.slug = newState.slug;
    this.link = newState.link;
    this.start_date = newState.datetime.start;
    this.due_date = newState.datetime.end;
    this.is_active = newState.is_active === true ? 1 : 0;
    this.limit = newState.limit.amount;
    this.description = newState.description;

    if (newState.limit.type !== "-1") {
      this.limit_type_id = state.limitTypes[newState.limit.type].id;
    }
    if (newState.limit.currency !== "-1") {
      this.currency_code_id = newState.limit.currency;
    }

    this.image =
      newState.image && newState.image.files.length
        ? newState.image.files[0]
        : null;

    this.use_coupon_code = newState.use_coupon_code ? 1 : 0;
    this.combine_campaigns = newState.combine_campaigns ? 1 : 0;

    if (
      newState.show_product_detail &&
      newState.product_detail_banner &&
      newState.product_detail_banner.files.length
    ) {
      this.product_detail_banner = newState.product_detail_banner.files[0];
    }

    this.conditions = this.getMapItems(newState.conditions);

    // If template selected
    if (newState.conditions.length && newState.conditions[0].result) {
      const template = newState.conditions[0];
      this.template = template.template_id;

      this.operations = this.getTemplateResult(template.result);
    } else {
      this.template = null;
      this.operations = this.getResult(
        newState.result,
        this.conditions && this.conditions.length
      );
    }
    return this;
  },
  getMapItems(conditions) {
    let result = [];
    conditions.forEach((condition) => {
      // Filter attributes these have values
      let attribute = condition.attributes.filter(
        (attr) => attr.value && attr.active
      );

      if (attribute && attribute.length) {
        attribute.forEach((key) => {
          let data = {
            code: condition.code,
            attribute: key.name,
            value: key.value,
          };

          if (condition.template_id) {
            delete data.code;
          }

          // If !empty key.operator
          if (key.operator) {
            data.operator = key.operator.id || key.operator;
          } else if (condition.code == "cart") {
            data.operator = "many_or_equal";
          } else {
            data.operator = "equal";
          }

          if (Array.isArray(key.value)) {
            // Create an array of id values
            data.value = helper.arrayPluck(key.value, "id");
          } else if (typeof key.value == "object") {
            data.value = data.value.id;
          }

          result.push(data);
        });
      } else {
        result = [];
      }
    });
    return result && result.length ? result : null;
  },
  getResult(result, conditions = false) {
    let arr = [];
    if (conditions) {
      let obj = {};

      obj.code = result.value.id;
      obj.type = result.discount.value.id;

      if (result.discount.value.id !== "free") {
        obj.value = result.discount.amount;
      }

      if (result.value.id == "promotion") {
        obj.products = helper.arrayPluck(result.discount.products, "id");
      }

      arr.push(obj);
    }
    return arr && arr.length ? arr : null;
  },
  getTemplateResult(result) {
    const notAllowed = [
      "options",
      "type_options",
      "search_route",
      "placeholder",
    ];
    const data = {};

    Object.entries(result).forEach(([key, value]) => {
      if (!notAllowed.includes(key)) {
        data[key] = value;
        if (key == "product" || (key == "type" && typeof value == "object")) {
          data[key] = Array.isArray(value)
            ? helper.arrayPluck(data[key], "id")
            : result[key].id;
        }
      }
    });

    return [data];
  },

  name: null,
  slug: null,
  link: null,
  start_date: null,
  due_date: null,
  limit: 0,
  description: null,
  limit_type_id: null,
  is_active: false,
  image: null,
  product_detail_banner: null,
  currency_code_id: null,
  use_coupon_code: false,
  combine_campaigns: false,
  conditions: [],
  operations: [],
};
