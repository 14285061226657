import marketplaceJson from "../../models/json/marketplace";

export default {
  new(data) {
    let source = {};
    if (data) {
      const marketplaceName = data.name;
      const config = data.config;

      if (marketplaceJson.hasOwnProperty(marketplaceName)) {
        let marketplace = marketplaceJson[marketplaceName];

        if (marketplace) {
          source = helper.clone(marketplace);
          source.logo = data.logo;

          if (config && Object.keys(config).length) {
            Object.entries(config).forEach(([configKey, configValue]) => {
              let sourceElement = source.elements.findIndex(
                (elem) => elem.name === configKey
              );
              if (sourceElement !== -1) {
                if(source.elements[sourceElement].form == 'select') {
                 if(configValue) {
                    let value =  source.elements[sourceElement].options.find(A => A.id == configValue)
                    source.elements[sourceElement].value = value;
                 } 
                } else {
                  source.elements[sourceElement].value = configValue;
                }
              }
            });
          }
        }
      }
    }

    return source;
  },
};
