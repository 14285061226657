export default {
  new(item) {
    this.name = item.name;
    this.code = item.code;
    this.created_by = item.created_by
    this.created_at = item.created_at
    this.is_active = item.is_active === 1;
    this.only_member = item.only_member === 1;
    this.discount.amount = item.discount_amount;
    this.discount.type = item.discount_type ? item.discount_type.id : null;
    this.startDate = item.startDate;
    this.dueDate = item.dueDate;
    this.limit.type_id = item.limit_type ? item.limit_type.id : 0;
    this.limit.amount = item.limit_amount;
    this.limit.currency = item.currency_code ? item.currency_code.id : -1;
    this.cart_price = helper.setPrice(item.cart_price || 0);
    return this;
  },
  name: null,
  code: null,
  created_by:null,
  created_at:null,
  is_active: false,
  only_member: false,
  cart_price: 0,
  discount: {
    amount: 0,
    type: null,
  },
  startDate: null,
  dueDate: null,
  limit: {
    type: -1,
    type_id: 0,
    amount: 0,
    currency: -1,
  },
};
