export default {
  check(key, code) {
    // Static data --- TODO: Sync with API
    const items = [
      { key: "user", model: "App\\Models\\User" },
      { key: "memberType", model: "App\\Models\\Statics\\MemberType" },
      { key: "memberGroup", model: "App\\Models\\Statics\\MemberGroup" },
      { key: "member", model: "App\\Models\\Member" },
      { key: "customer", model: "App\\Models\\Customer" },
      { key: "category", model: "App\\Models\\Category" },
      { key: "brand", model: "App\\Models\\Brand" },
      { key: "model", model: "App\\Models\\Model" },
      { key: "product", model: "App\\Models\\Product" },
      { key: "feature", model: "App\\Models\\Feature" },
      { key: "campaign", model: "App\\Models\\Campaign" },
      { key: "coupon", model: "App\\Models\\DiscountCoupon" },
      { key: "order", model: "App\\Models\\Order" },
      { key: "setting", model: "App\\Models\\Setting" },
      { key: "roleGroup", model: "App\\Models\\RoleGroup" },
      { key: "tag", model: "App\\Models\\Tag" },
      { key: "contract", model: "App\\Models\\Contract" },
      { key: "page", model: "App\\Models\\Page" },
      { key: "blog", model: "App\\Models\\Blog" },
      { key: "homeSeo", model: "App\\Models\\HomeSeo" },
      { key: "slider", model: "App\\Models\\Slide" },
      { key: "popup", model: "App\\Models\\Popup" },
      { key: "banner", model: "App\\Models\\Banner" },
      { key: "cargo", model: "App\\Models\\Cargo" },
      { key: "deliveryZone", model: "App\\Models\\DeliveryZone" },
      { key: "erpIntegration", model: "App\\Models\\ErpIntegration" },
      { key: "invoiceIntegration", model: "App\\Models\\InvoiceIntegration" },
      { key: "shop", model: "App\\Models\\Shop" },
      { key: "emailTemplate", model: "App\\Models\\EmailTemplate" },
    ];

    let result = false;
    let sessionStorage = JSON.parse(localStorage.session); // Get LocalStorage as Object
    const sessionRoles = sessionStorage.roles; // LocalStorage roles
    const index = items.findIndex((s) => s.key == key); // Find index in items by key

    // Permission Status
    if (index >= 0 && sessionRoles) {
      const modelData = items[index].model; // Model namespace
      const sessionModel = sessionRoles.find((item) => item.model == modelData); // Find in LocalStorage by Model namespace

      // Permission Result
      if (sessionModel && sessionModel.code) {
        const permissions = sessionModel.code.split(",");

        if (typeof code == "object" && Array.isArray(code) && code.length) {
          code.forEach((c) => {
            const checkResult = permissions.includes(c);

            // Once if result is true then return true
            if (checkResult) result = true;
          });
        } else {
          result = permissions.indexOf(code) >= 0; // Check if LocalStorage Roles Models have action/permission code
        }
      } else {
        // Permission Result
        result = false;
      }

      // Check Super Admin Code
      if (!result) {
        const checkSuperAdmin = sessionRoles.filter(
          (role) => role.model === "*"
        );
        result = checkSuperAdmin.length >= 1;
      }

      return result;
    }
  },
  alert(vm) {
    vm.$toast.error("Bu işlem için yetkiniz bulunmamaktadır");
  },
};
