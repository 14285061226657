export default {
  clone(items) {
    this.currencies = [];
    if (items && items.length) {
      for (let i = 0; i < items.length; i++) {
        const curr = items[i];

        const data = {};
        data.id = curr.id;
        data.code = curr.code.toUpperCase();
        data.buying = curr.currency_buying;
        data.selling = curr.currency_selling;
        data.tmp = {
          buying: curr.currency_buying,
          selling: curr.currency_selling,
        };

        this.currencies.push(data);
        this.lastUpdate = curr.updated_at;
      }
    }

    return this;
  },
  currencies: [],
  lastUpdate: null,
};
