import imageRequest from "../../models/request/image";
import metaRequest from "./meta";

export default {
  new(item) {
    let result = {};
    if (item.parentCategory) {
      result.category_id = item.parentCategory.length
        ? item.parentCategory[0].id
        : null;
    }
    if (item.name) result.name = item.name;
    if (item.slug) result.slug = item.slug;
    if (item.hasOwnProperty("taxAmount")) result.tax = item.taxAmount || 0;
    if (item.hasOwnProperty("is_active")) {
      result.is_active = item.is_active === true ? 1 : 0;
    }
    if (item.hasOwnProperty("showcase")) {
      result.is_showcase = item.showcase === true ? 1 : 0;
    }
    if (item.hasOwnProperty("showmenu")) {
      result.is_showmenu = item.showmenu === true ? 1 : 0;
    }
    // Kategori Açıklaması
    result.description = item.description ? item.description.text : null;

    if (item.description && item.description.hasOwnProperty("show")) {
      result.show_description = item.description.show == true ? 1 : 0;
    } else {
      result.show_description =
        item.description && item.description.hasOwnProperty("place") ? 1 : 0;
    }
    if (item.description && item.description.hasOwnProperty("place")) {
      result.description_place = item.description.place;
    }

    result.image = imageRequest(item.image.files, false);
    result.icon = imageRequest(item.icon.files, false);
    result = { ...result, ...metaRequest(item.meta) };

    if (item.marketplaces) result.marketplaces = item.marketplaces;
    if (item.google_category && item.google_category.hasOwnProperty("id")) {
      result.google_category_id = item.google_category.id;
    } else if (item.google_category == undefined) {
      result.google_category_id = null;
    }

    if (item.features) {
      result.features = helper.arrayPluck(item.features, "feature_id");
    }
    return result;
  },

  // category_id: null,
  // name: null,
  // slug: null,
  // tax: null,

  // description: null,
  // show_description: null,
  // description_place: null,

  // meta_title: null,
  // meta_description: null,
  // meta_keywords: null,
  // // meta_robots: null,

  // is_active: 1,
  // is_showcase: 0,
  // image: null,
  // features: null,
  // marketplaces: [],
  // google_category_id: null,
};
