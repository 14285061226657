import invoiceJson from "../../models/json/invoiceIntegration";
export default {
  new(data) {
    let source = {};

    if (data) {
      const invoiceIntegrationName = data.name;
      const config = data.config;

      let invoiceIntegration = invoiceJson.items.find(
        (invoiceJson) => invoiceJson.name === invoiceIntegrationName
      );
      if (invoiceIntegration) {
        source = helper.clone(invoiceIntegration);
        source.id = data.id;
        source.is_active = data.is_active ? 1 : 0;
        source.is_default = data.is_default ? 1 : 0;
        source.is_test = data.is_test ? 1 : 0;

        // source.logo = data.logo;

        if (config && Object.keys(config).length) {
          Object.entries(config).forEach(([configKey, configValue]) => {
            let sourceEnvIndex = source.environments.findIndex(
              (elem) => elem.name === configKey
            );
            if (sourceEnvIndex !== -1) {
              source.environments[sourceEnvIndex].elements.map(
                (item) => (item.value = configValue[item.name])
              );
            }
          });
        }
      }
    }

    return source;
  },
};
