import api from "../../shared/api";
import requestEquipment from "../../models/request/equipment";

import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    // Package -> Detail
    brands: [],

    // Package -> Information
    currencies: [],

    // Package -> Information // Dummy
    stockTypeOptions: [],

    // Package -> Detail
    models: [],

    // Global Currency Symbol
    currencySymbol: "₺",

    // Package -> Information
    info: {
      selected: {
        stockType: -1,
        currency: -1,
      },
      engine: {
        brand: null,
        model: null,
        year: null,
      },
      sku_no: null,
      manufacturer: null,
      products: [],
    },

    // Package -> Detail
    detail: {
      description: {
        text: null,
      },

      meta: {
        title: null,
        keywords: null,
        description: null,
        canonicalUrl: null,
      },
    },

    list: [],
  }),
  mutations: {
    setInformation(state, data) {
      if (data) {
        const info = state.info;
        info.id = data.id;
        info.sku_no = data.sku_no;
        info.manufacturer = data.manufacturer;
        info.engine.brand = data.engine_brand;
        info.engine.model = data.engine_model;
        info.engine.year = data.engine_year;

        info.products = [];
        data.packageProducts.forEach((product) => {
          product.cross_name = product.cross_code + " - " + product.cross_name;
          info.products.push(product);
        });

        const detail = state.detail;
        detail.description.text = data.description;
        detail.meta.title = data.meta_title;
        detail.meta.keywords = data.meta_keywords;
        detail.meta.description = data.meta_description;
        detail.meta.canonicalUrl = data.canonical_url;
      }
    },
    clearInformation(state) {
      state.info = {
        sku_no: null,
        manufacturer: null,

        selected: {
          stockType: -1,
          currency: -1,
        },

        engine: {
          brand: null,
          model: null,
          year: null,
        },

        products: [],
      };
      state.detail = {
        description: {
          text: null,
        },

        meta: {
          title: null,
          keywords: null,
          description: null,
        },
      };
    },

    changeCurrencySymbol(state) {
      let selected = state.info.selected.currency;
      let currencies = state.currencies;

      let index = helper.arrayFind(currencies, "id", selected);
      if (index !== -1) {
        state.currencySymbol = currencies[index].symbol;
      }
    },

    updateList(state, result) {
      state.list = result || [];
    },
  },
  actions: {
    load({ commit }) {
      commit("clearInformation");
      this.dispatch("equipment/getCurrencyList");
      this.dispatch("equipment/getQuantityTypes");
    },
    create({ state }, data) {
      api.post(
        "equipment",
        requestEquipment.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        "equipment/" + data.id,
        requestEquipment.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "equipment/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/equipment";
      let queryString = { ...data.filter, ...data.table };

      routePath += "?" + helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      if (data.id) {
        api.get(
          "equipment/" + data.id,
          (result) => {
            commit("setInformation", result.data.item);
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            setTimeout(() => {
              commit("changeCurrencySymbol");
            }, 1000);
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getCurrencyList({ state }, data) {
      api.get(
        "all/currency-codes",
        (result) => {
          state.currencies = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getQuantityTypes({ state }, data) {
      api.get(
        "all/quantity-types",
        (result) => {
          state.stockTypeOptions = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({ commit, state }, data) {
      api.post(
        "equipment/isActive/" + data.id,
        {
          is_active: data.is_active,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
