export default {
  clone(media, multiple = true) {
    if (media) {
      if (multiple) {
        this.result = [];

        media.forEach((file) => {
          let newFile = {};

          if (file.path) {
            newFile.id = file.id;
            newFile.temp_id = helper.getRandomInteger(1, 99999);
            newFile.fileName = file.path?.original || file.path;
            newFile.fileData = file.path?.original || file.path;
            newFile.fileSize = helper.formatSize(file.size);
            newFile.metaAlt = file.alt;
            newFile.metaTitle = file.title;
            newFile.fileSizeByte = file.size;
            newFile.isMain = parseInt(file.main);
            newFile.mimeType = file.type;
            newFile.isImage = helper.mimeTypeIsImage(file.type);
            newFile.isVideo = helper.mimeTypeIsVideo(file.type);
            this.result.push(newFile);
          }
        });
      } else {
        this.result = {};
        if (media.path) {
          this.result.temp_id = helper.getRandomInteger(1, 99999);
          this.result.fileName = media.path?.original || file.path;
          this.result.fileData = media.path?.original || file.path;
          this.result.fileSize = helper.formatSize(media.size);
          this.result.metaAlt = media.alt;
          this.result.metaTitle = media.title;
          this.result.fileSizeByte = media.size;
          this.result.isMain = true;
          this.result.mimeType = media.type;
          this.result.isImage = helper.mimeTypeIsImage(media.type);
          this.result.isVideo = helper.mimeTypeIsVideo(media.type);
        }
      }
    } else {
      this.result = multiple ? [] : {};
    }
    return this.result;
  },

  result: null,
};
