export default {
  new(source, name) {
    this.roles = [];
    this.name = name;

    if (source) {
      source.map((role) => {
        const data = {
          model: role.model,
          code: role.code.toString(),
        };

        this.roles.push(data);
      });
    }
    return this;
  },

  name: null,
  roles: [],
};
