export default {
  new(state) {
    const address = state.new.address;
    const shipment = address.shipment;
    const invoice = address.invoice;
    const customer = state.new.customer;

    this.shipment_country_id = shipment.country ? shipment.country.id : null;
    this.shipment_city_id = shipment.city ? shipment.city.id : null;
    this.shipment_district_id = shipment.district ? shipment.district.id : null;
    this.shipment_neighborhood_id = shipment.neighborhood
      ? shipment.neighborhood.id
      : null;
    this.shipment_postal_code = shipment.postal_code;
    this.shipment_address = shipment.address;
    this.shipment_email = customer.email;
    this.shipment_first_name = customer.first_name;
    this.shipment_last_name = customer.last_name;
    this.shipment_phone = customer.phone;
    this.shipment_gsm = customer.gsm;

    this.invoice_country_id = invoice.country ? invoice.country.id : null;
    this.invoice_city_id = invoice.city ? invoice.city.id : null;
    this.invoice_district_id = invoice.district ? invoice.district.id : null;
    this.invoice_neighborhood_id = invoice.neighborhood
      ? invoice.neighborhood.id
      : null;
    this.invoice_postal_code = invoice.postal_code;
    this.invoice_address = invoice.address;
    this.invoice_email = customer.email;
    this.invoice_first_name = customer.first_name;
    this.invoice_last_name = customer.last_name;
    this.invoice_phone = customer.phone;
    this.invoice_gsm = customer.gsm;
    this.invoice_tcn = customer.tcn;
    this.invoice_vkn = customer.vkn;
    this.invoice_tax_office = customer.tax_office;
    this.invoice_company = customer.firm_name;
    this.send_link = state.new.type.payment == 2 ? true : state.new.send_link;
    this.description = state.new.description ?? null;

    this.products = state.new.products.map((el) => {
      return {
        id: el.id,
        quantity: el.quantity,
        price: el.price,
      };
    });
    this.currency_code_id = state.new.currency.id;

    // this.orderable_id = customer.id;
    // this.orderable_type = 0;
    this.order_type_id = state.new.type.order;
    this.payment_type_id = state.paymentTypes[state.new.type.payment].id;
    this.total_price = state.new.totalPrice;

    this.cargo_id = state.new.cargoCompany;
    this.order_no = state.new.order_no;
    const gift = state.new.gift;
    this.gift_wrap = gift.status ? 1 : 0;
    this.gift_note = gift.status ? gift.text : null;
    this.member_id = customer?.id ?? null;

    return this;
  },

  order_type_id: 0,
  products: [],
  payment_type_id: 0,
  cargo_company_id: null,
  gift_wrap: false,
  gift_note: null,
  total_price: 0,
  shipment_country_id: null,
  shipment_city_id: null,
  shipment_district_id: null,
  shipment_neighborhood_id: null,
  shipment_postal_code: null,
  shipment_address: null,
  shipment_email: null,
  shipment_first_name: null,
  shipment_last_name: null,
  shipment_phone: null,
  shipment_gsm: null,
  invoice_country_id: null,
  invoice_city_id: null,
  invoice_district_id: null,
  invoice_neighborhood_id: null,
  invoice_postal_code: null,
  invoice_address: null,
  invoice_email: null,
  invoice_first_name: null,
  invoice_last_name: null,
  invoice_phone: null,
  invoice_gsm: null,
  invoice_tcn: null,
  invoice_vkn: null,
  invoice_tax_office: null,
  invoice_company: null,
  member_id: null,
  send_link: null,
  description: null,
};
