import api from "../../shared/api";

export default {
  namespaced: true,
  actions: {
    upload({ state }, data) {
      const route = `upload/${data.name}`;

      let formData = new FormData();
      formData.append("upload", data.file);

      api.post(
        route,
        formData,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
