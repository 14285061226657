import api from "../../shared/api";

export default {
  state: () => ({
    categoryList: [],
  }),
  mutations: {
    setCategoryList(state, data) {
      if (data) {
        helper.copy(data, state.categoryList);
      } else {
        state.categoryList = [];
      }
    },
  },
  actions: {
    getCategoryList({ commit }, data) {
      commit("setCategoryList");

      let route = "all/categories";
      if (data.hasOwnProperty("lang")) {
        route += `?lang=${data.lang}`;
      }

      api.get(
        route,
        (result) => {
          commit("setCategoryList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    createCategoryChildren({ commit }, data) {
      const path = "categories/" + data.category_id + "/children";
      api.post(
        path,
        {
          name: data.name,
          tax: data.tax || 0,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
