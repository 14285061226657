<template>
  <transition appear name="app-transition" mode="out-in">
    <router-view></router-view>
  </transition>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "App",
  methods: {
    ...mapActions("session", ["load"]),
  },
  computed: {
    ...mapState(["session"]),
  },
  mounted() {
    this.load({
      onFinish: () => {
        let currentDateTime = new Date().getTime();
        let expireDate = this.session.expires;
        if (currentDateTime >= expireDate) {
          this.$router.replace("/logout");
        }

        this.$i18n.locale = localStorage.getItem("_lang") || "tr";
      },
    });
  },
};
</script>

<style lang="scss">
// Colors
$blue: #3490dc;
$indigo: #6574cd;
$purple: #9561e2;
$pink: #f66d9b;
$red: #e3342f;
$orange: #f6993f;
$yellow: #ffed4a;
$green: #38c172;
$teal: #4dc0b5;
$cyan: #6cb2eb;
$muted: #6c757d;

.app-transition-enter,
.app-transition-leave-to {
  transform: scale(0.9);
  opacity: 0;
}

.app-transition-enter-active,
.app-transition-leave-active {
  transition: transform 500ms ease-in-out, opacity 750ms ease-in-out;
}

.nav-link,
.text-link {
  cursor: pointer;
}

.bg-indigo {
  background-color: #3f51b5;
}

.font-12 {
  font-size: 12px !important;
}

.font-13 {
  font-size: 12px !important;
}

.font-14 {
  font-size: 14px !important;
}

.font-15 {
  font-size: 15px !important;
}

* {
  font-family: "Roboto", sans-serif;
  outline: none;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  background-color: #dadada17;
  display: flex;
  flex-direction: column;
}

.app-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.btn-outline-indigo {
  border: 1px solid $indigo;
  color: $indigo;
  &:hover {
    background-color: $indigo;
    color: #ffffff;
  }
}
.btn-indigo {
  border: 1px solid $indigo;
  color: #ffffff;
  background-color: $indigo;
  &:hover {
    background-color: #5564ad;
    color: #ffffff;
  }
}

.nav-link,
.nav-top,
.dropdown-item,
.nav-second,
.btn-white {
  transition: 0.4s all;
}

/* Ürün Listeleme */
.img-popover {
  color: #2f3542;
}

.img-popover:hover {
  text-decoration: none;
  color: #535455;
}

/* Ürün Listeleme */

/* Paket Listeleme */
.count-minus,
.count-plus {
  color: #2f3542;
  transition: 0.4s all;
}

.count-plus:hover {
  color: #2ed573;
}

.count-minus:hover {
  color: #ff4757;
}

/* Paket Listeleme */

.dropdown-menu .btn {
  font-size: 0.9rem !important;
}

.dropdown-item:hover {
  background-color: #e6eaef;
}

.dropdown-item i {
  width: 18px;
  height: 16px;
}

.dropdown-item i::before {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.dropdown-toggle::after {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f078";
  border: none !important;
  transform: scale(0.7);
  vertical-align: top;
}

.dropdown-menu {
  z-index: 1030;
}

.btn-shadow {
  transition: 0.4s all;
}

.btn-shadow:hover {
  box-shadow: 0px 3px 5px rgba(78, 78, 78, 0.5);
}

.big-text {
  font-size: 800%;
}

.btn-white {
  background-color: #ffffff !important;
}

.btn-white:hover {
  background-color: #e6eaef !important;
}

.no-decoration {
  text-decoration: none !important;
}

.custom-label {
  margin: 0;
  font-weight: 500;
}

.valign-child-center td {
  vertical-align: middle;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}
.btn.btn-outline-info:hover {
  color: #ffffff;
}

.tooltip {
  display: block !important;
  z-index: 10000;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 6px;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[aria-hidden="true"] {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.15s, visibility 0.15s;
}

.tooltip[aria-hidden="false"] {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.15s;
}

.hover-shadow {
  transition: 0.2s all;
  &:hover {
    box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.1);
  }
}
.field.xs .field-input {
  min-height: 32px;
  height: 32px;
}
.print {
  display: none;
}
.v-select .vs__dropdown-toggle {
  min-height: 38px !important;
  border: 1px solid #ced4da;
}
@media print {
  .print {
    display: initial;
  }
  .print__page-break {
    page-break-before: always;
  }
  .data-table .table.straight {
    th {
      font-size: 14px;
      span {
        font-weight: bold;
      }
    }
    white-space: normal !important;
  }
  .data-table .table thead th.sortable {
    &::before,
    &::after {
      border: none !important;
    }
  }
  .hide-on-print {
    display: none;
  }
}
.btn-disabled {
  cursor: not-allowed !important;
}
.cursor-default {
  cursor: default !important;
}
.custom-control-label {
  line-height: 23.5px;
}
.ck-editor-seo {
  .ck-editor__editable_inline {
    min-height: 100px !important;
    border-radius: 0.25rem !important;
  }
  .ck-editor__top {
    display: none;
  }
}
.text-indigo {
  color: #3f51b5;
}
.border-left-2 {
  border-left: 2px solid gray;
}
.border-bottom-2 {
  border-bottom: 2px solid gray;
}
.border-orange {
  border-color: var(--orange);
}
.text-orange {
  color: var(--orange);
}
.border-pink {
  border-color: var(--pink);
}
.text-pink {
  color: var(--pink);
}
.form-control:focus,
.custom-select:focus {
  box-shadow: none !important;
}
.cursor-pointer {
  cursor: pointer;
}
.border-left-radius-0 {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.border-right-radius-0 {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.v-select--error .vs__dropdown-toggle,
.v-select--error .vs__dropdown-menu {
  border-color: #dc3545 !important;
}
.text-transparent-white-8 {
  color: rgba(255, 255, 255, 0.8);
}
.text-transparent-white-7 {
  color: rgba(255, 255, 255, 0.7);
}
.bg-white-transparent-5 {
  background: rgba(255, 255, 255, 0.5);
}
.bg-black-transparent-3 {
  background: rgba(0, 0, 0, 0.3);
}
.bg-black-transparent-2 {
  background: rgba(0, 0, 0, 0.2);
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
/* width */

.shortcuts-container::-webkit-scrollbar,
.vs__dropdown-menu::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */

.shortcuts-container::-webkit-scrollbar-track,
.vs__dropdown-menu::-webkit-scrollbar-track,
.custom-scrollbar::-webkit-scrollbar-track {
  background: #ced6e0;
}

/* Handle */

.shortcuts-container::-webkit-scrollbar-thumb,
.vs__dropdown-menu::-webkit-scrollbar-thumb,
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #a8a8a8;
}

/* Handle on hover */

.shortcuts-container::-webkit-scrollbar-thumb:hover,
.vs__dropdown-menu::-webkit-scrollbar-thumb:hover,
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #696969;
}

.vs__dropdown-menu {
  width: 100%;
  overflow: hidden;
}

@media (min-width: 768px) {
  .v-select__selected {
    width: 100%;
  }
}
@media (min-width: 992px) {
  .v-select__selected {
    max-width: 50px;
  }
}
@media (min-width: 1152px) {
  .v-select__selected {
    max-width: 120px;
  }
}
@media (min-width: 1200px) {
  .v-select__selected {
    max-width: 240px;
  }
}
.shake-it {
  animation: shake 3s infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
@keyframes shake {
  0%,
  100% {
    transform: translate3d(0, 0, 0);
  }

  10%,
  90% {
    transform: translate3d(0px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(1px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-1px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(1px, 0, 0);
  }
}
.glow-it--danger {
  animation: glow 10s infinite;
}
@keyframes glow {
  0%,
  100% {
    border-color: #dc3545;
    color: #dc3545;
  }

  10%,
  90% {
    color: black;
  }

  20%,
  80% {
    border-color: #dc3545;
    color: #dc3545;
  }

  30%,
  50%,
  70% {
    color: black;
  }

  40%,
  60% {
    border-color: #dc3545;
    color: #dc3545;
  }
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}
.btn-outline-orange {
  background-color: transparent;
  border: 1px solid rgb(255, 110, 26);
  color: rgb(255, 116, 26);
  &:hover {
    background-color: rgb(255, 110, 26);
    color: #ffffff;
  }
}
.btn-orange {
  background-color: rgb(255, 110, 26);
  border: 1px solid rgb(255, 110, 26);
  color: #ffffff;
  &:hover {
    background-color: rgb(223, 96, 22);
    border: 1px solid rgb(223, 96, 22);
    color: #ffffff;
  }
}
.text-orange {
  color: rgb(255, 110, 26);
}
#swal2-title {
  font-size: 1.175em !important;
}
#swal2-content {
  font-size: 15px !important;
}
.alert-orange {
  color: #854904;
  background-color: #ffdfcd;
  border-color: #ffdfba;
}
.alert-pink {
  color: var(--pink);
  background-color: #ffd4e2;
  border-color: var(--pink);
}
.custom-v-select-sm .vs__dropdown-menu {
  max-height: 220px;
  overflow: auto;
}
.custom-v-select-sm .vs__dropdown-toggle {
  min-height: 32px !important;
  .vs__spinner {
    width: 20px;
    height: 20px;
  }
}
.vs__dropdown-toggle {
  input::placeholder {
    color: #757575 !important;
  }
}
.custom-v-select .vs__dropdown-menu,
.custom-v-select .vs__selected {
  font-size: 14px;
}
.custom-v-select .vs__search {
  line-height: 1.68;
}
.no-select {
  user-select: none;
}
.cursor-move {
  cursor: all-scroll;
}
.up-down {
  -webkit-animation: upDown 500ms infinite alternate;
  animation: upDown 500ms infinite alternate;
}
@-webkit-keyframes upDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3px);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-3px);
  }
}
.bg-danger-light {
  background-color: rgba($color: $red, $alpha: 0.09);
}
.hover-background-indigo {
  transition: 250ms all;
  &:hover {
    background-color: rgba($indigo, 0.1);
  }
}
</style>
