import api from "../../shared/api";
import requestEmailTemplate from "../../models/request/emailTemplate";
import responseEmailTemplate from "../../models/response/emailTemplate";
import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      state.list = result?.data?.items || [];
    },
  },
  actions: {
    create({ state }, data) {
      api.post(
        `email-templates?lang=${state.lang}`,
        requestEmailTemplate.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        `email-templates/${data.id}?lang=${state.lang}`,
        requestEmailTemplate.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "email-templates/" + data.id,
        (result) => {
          if (result && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "email-templates/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "email-templates/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      let routePath = "all/email-templates";
      let queryString = { lang: state.lang, ...data?.filter, ...data?.table };

      routePath += "?" + helper.makeQueryString(queryString);

      if (data && data.deleted) {
        routePath += "&deleted_at=1";
      }
      
      api.get(
        routePath,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `email-templates/${data.id}?lang=${state.lang}`,
        (result) => {
          let resultData = responseEmailTemplate.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    send({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "bulk-actions/send-email",
          {
            title: requestData.title,
            template_id: requestData.template_id,
            email_lists: requestData?.email_lists || null,
            filter: requestData.filter,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
