export default {
  new(form) {
    let result = {};

    if (form.name) {
      result.name = form.name;
    }
    if (form.slug) {
        result.slug = form.slug;
      }
    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    if (form.description && form.description.text) {
      result.description = form.description.text;
    }
    result = { ...result };
    return result;
  },
  image: null,
};
