export default {
  clone(data) {
    this.name = data.name;
    this.cargo_company_id = data.cargoCompany ? data.cargoCompany.id : "-1";
    this.cargo_insurance = data.cargoInsurance || null;
    this.currency_code = data.currency_code;
    this.is_free = data.is_free;
    
    this.direct_payment = {
      min: helper.setPrice(data.min_cash_limit || 0),
      max: helper.setPrice(data.max_cash_limit || 0),
      cash: data.cash_status || 0,
      cash_price: helper.setPrice(data.cash_price || 0),
      credit_card: data.credit_card_status,
      credit_card_price: helper.setPrice(data.credit_card_price),
    };
    this.rates = {
      fixed_price: helper.setPrice(data.fixed_price || 0),
      min_free_shipping: helper.setPrice(data.free_cargo || 0),
      max_desi_limit: data.free_desi || 0,
    };
    this.desi = data.desi_info
      ? data.desi_info.map((desi) => {
          desi.temp_id = helper.getRandomInteger(1, 9999);
          return desi;
        })
      : [];
    this.over_desi_price = helper.setPrice(data.over_desi || 0);
    this.delivery_zones = data.delivery_zones;

    this.config = data.config;

    return this;
  },

  name: null,
  cargo_company_id: null,
  cargo_payment_id: null,
  cargo_insurance: null,
  direct_payment: {
    min: 0,
    max: 0,
    cash: false,
    cash_price: 0,
    credit_card: false,
    credit_card_price: 0,
  },
  rates: {
    fixed_price: 0,
    min_free_shipping: 0,
    max_desi_limit: 0,
  },
  desi: [],
  delivery_zones: [],
  // delivery_zone: {
  //   countries: [],
  //   cities: [],
  //   districts: [],
  // },
};
