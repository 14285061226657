import api from "../../shared/api/index";
import requestBanner from "../../models/request/banner";
import responseBanner from "../../models/response/banner";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      let result = [];

      if (payload && payload.length) {
        result = payload;
      }

      state.list = result;
    },
    updateLang(state,lang){
      state.lang = lang
    },
    clearList(state){
      state.lang = "tr"
      state.list = []
    }
  },
  actions: {
    create({ state }, data) {
      let route = `banners?lang=${state.lang}`;
      api.post(
        route,
        requestBanner.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      let route = `banners/${data.id}?lang=${state.lang}`;
      api.put(
        route,
        requestBanner.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({}, data) {
      api.delete(
        "banners/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "banners/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "banners/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit,state }, data) {
      commit("clearList")
      let route = "all/banners";

      if(data.lang) commit("updateLang",data.lang)

      route += `?lang=${state.lang}`;

      if (data && data.deleted) {
        route += "&deleted_at=1";
      }

      api.get(
        route,
        (result) => {
          const items =
            result && result.data && result.data.items ? result.data.items : [];

          commit("updateList", items);
          if (data && data.onSuccess) data.onSuccess(items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit, state }, data) {
      let route = `banners/${data.id}?lang=${state.lang}`;
      api.get(
        route,
        (result) => {
          if (result && result.data && result.data.item) {
            result = responseBanner.new(result.data.item);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "banners/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
