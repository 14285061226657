import api from "../../shared/api";
import requestCampaign from "../../models/request/campaign";
import responseCampaign from "../../models/response/campaign";
import campaignJson from "../../models/json/campaign";
import responseImage from "../../models/response/image";

export default {
  namespaced: true,
  state: () => ({
    limitTypes: [],
    data: {
      source: campaignJson.wizard.source,
      templates: campaignJson.templates,
      template_copy: null,
      result: campaignJson.wizard.result,
    },
    lang: "tr",
    new: {
      name: null,
      slug: null,
      link: null,
      is_active: true,
      datetime: {
        start: null,
        end: null,
      },
      limit: {
        type: "-1",
        amount: null,
        currency: "-1",
      },

      description: null,

      image: {
        files: [],
        filesData: null,
      },

      use_coupon_code: false,
      show_product_detail: false,
      combine_campaigns: false,
      product_detail_banner: {
        files: [],
        filesData: null,
      },

      // Condition
      conditions: [],
      result: {},
    },

    list: [],
  }),
  mutations: {
    setInformation(state, result) {
      state.data.template_copy = helper.clone(state.data.templates);
      if (result) {
        const item = result.data.item;
        if (item) {
          const newState = state.new;
          newState.name = item.name;
          newState.is_active = item.is_active === 1;
          newState.slug = item.slug;
          newState.link = item.link;
          newState.datetime = {
            start: item.start_date,
            end: item.due_date,
          };
          newState.limit = {
            type: item.limit_type
              ? state.limitTypes.findIndex(
                  (type) => type.name === item.limit_type.name
                )
              : "-1",
            amount: item.limit,
            currency: item.currency_code ? item.currency_code.id : null,
          };

          newState.description = item.description;

          newState.image = {
            files: [responseImage.clone(item.image, false)],
            filesData: null,
          };

          newState.product_detail_banner = {
            files: item.product_detail_banner
              ? [responseImage.clone(item.product_detail_banner, false)]
              : [],
            filesData: null,
          };

          newState.use_coupon_code = item.use_coupon_code;
          newState.show_product_detail =
            item.product_detail_banner &&
            item.product_detail_banner.path !== null;

          newState.combine_campaigns = item.combine_campaigns;

          newState.template = {
            id: item.template || null,
            based: null,
          };

          let campaignSourceData = state.data.source; // Campaign wizard source

          if (item.template) {
            const templateBase = item.template.split("-")[0];
            newState.template.based = templateBase;

            const templateData = state.data.template_copy[templateBase];
            const template = templateData.find(
              (t) => t.template_id == newState.template.id
            );

            campaignSourceData = template;
          }

          newState.conditions = responseCampaign.new(
            item,
            campaignSourceData,
            item.template
          );

          newState.result = responseCampaign.result(
            item.operations,
            state.data.result
          );
        }
      } else {
        state.new = {
          name: null,
          is_active: true,
          slug: null,
          link: null,
          datetime: {
            start: null,
            end: null,
          },
          limit: {
            type: "-1",
            amount: null,
            currency: "-1",
          },

          description: null,

          image: {
            files: [],
            filesData: null,
          },

          use_coupon_code: false,
          show_product_detail: false,
          combine_campaigns: false,
          product_detail_banner: {
            files: [],
            filesData: null,
          },

          template: {
            id: null,
            based: null,
          },

          // Condition
          conditions: [],
          result: campaignJson.wizard.result,
        };
      }
    },
    setLimitTypes(state, result) {
      state.limitTypes = [];
      if (result && result.data && result.data.items) {
        helper.copy(result.data.items, state.limitTypes);
      }
    },
    selectCondition(state, payload) {
      state.new.conditions.push(payload);

      let index = helper.arrayFind(state.conditionTypes, "name", payload.name);
      state.conditionTypes.splice(index, 1);
    },
    removeCondition(state, index) {
      let condition = state.new.conditions;
      condition.splice(index, 1);
    },
    updateList(state, result) {
      state.lang = "tr";
      if (result && result.data && result.data.items) {
        helper.copy(result.data.items, state.list);
      } else {
        state.list = [];
      }
    },
    updateLang(state,lang){
      state.lang = lang
    }
  },
  actions: {
    load({ commit }) {
      commit("setInformation");
      this.dispatch("shared/getCurrencyList");
    },
    create({ commit, state }, data) {
      api.post(
        `campaigns?lang=${state.lang}`,
        requestCampaign.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit, state }, data) {
      api.get(
        "campaigns/" + data.id,
        (result) => {
          commit("setInformation", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          let index = state.list.findIndex((item) => item.id === data.id);
          state.list.splice(index, 1);
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ commit, state }, data) {
      api.put(
        "campaigns/" + data.id + "/?lang=" + state.lang,
        requestCampaign.new(state),
        (result) => {
          commit("setInformation", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          let index = state.list.findIndex((item) => item.id === data.id);
          state.list.splice(index, 1);
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "campaigns/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          let index = state.list.findIndex((item) => item.id === data.id);
          state.list.splice(index, 1);
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "campaigns/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "campaigns/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit,state }, data) {
      let route = "all/campaigns";

      if(data.lang) commit("updateLang",data.lang)
      else commit("updateLang","tr")
      
      route += `?lang=${state.lang}`;

      if (data && data.deleted) {
        route += "&deleted_at=1";
      }

      commit("updateList");
      api.get(
        route,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          commit("updateList");
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "campaigns/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getLimitTypes({ commit }, data) {
      api.get(
        "all/limit-types",
        (result) => {
          commit("setLimitTypes", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    conditionGet({ commit }, data) {
      api.get(
        data.route,
        (result) => {
          // commit("setInformation", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    conditionSearch({ commit }, data) {
      api.post(
        data.route,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
