import api from "../../shared/api";

export default {
  state: () => ({}),
  mutations: {},
  actions: {
    searchProduct({}, data) {
      const path =
        data.type == "cross" ? "search/cross-products" : "search/products";

      let request = {
        query: data.query,
      };

      if (data.hasOwnProperty("sku_no")) {
        request = {
          sku_no: data.sku_no,
        };
      }

      api.post(
        path,
        request,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCrossProducts({}, data) {
      const id = data.id;
      const path = "all/cross-products/" + id;

      api.get(
        path,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
