import api from "../../shared/api";
import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    new: {
      name: null,
    },
    list: [],
  }),
  mutations: {
    setInformation(state, result) {
      if (result && result.data && result.data.item) {
        state.new.name = result.data.item.name;
      } else {
        state.new.name = null;
      }
    },
    updateList(state, result) {
      if (result && result.data && result.data.items) {
        state.list = result.data.items;
      } else {
        state.list = [];
      }
    },
  },
  actions: {
    load({ commit }) {
      commit("setInformation");
    },
    create({ state }, data) {
      api.post(
        "member-types",
        {
          name: state.new.name,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        "member-types/" + data.id,
        {
          name: state.new.name,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "member-types/" + data.id,
        (result) => {
          helper.arrayRemove(state.list, "id", data.id);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "member-types/" + data.id,
        (result) => {
          commit("setInformation", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "all/member-types",
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
