import api from "../../shared/api";
import requestMember from "../../models/request/member";
import responseMember from "../../models/response/member";
import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      if (result && result.data && result.data.items) {
        result.data.items.forEach((element) => {
          element.member_type = element.member_type
            ? element.member_type.name
            : null;
          element.member_group = element.member_group
            ? element.member_group.name
            : null;
        });
        state.list = result.data.items;
      } else {
        state.list = [];
      }
    },
  },
  actions: {
    create({}, data) {
      api.post(
        "members",
        requestMember.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({}, data) {
      api.put(
        "members/" + data.id,
        requestMember.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "members/" + data.id,
        (result) => {
          if (result && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "members/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "members/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/members";
      let queryString = { ...data.filter, ...data.table };

      routePath += "?" + helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ rootState }, data) {
      this.dispatch("membertype/getList");

      const contacTypes = rootState.session.contactTypes || [];
      const memberType = rootState.membertype;

      api.get(
        "members/" + data.id,
        (result) => {
          const responseData = responseMember.new(result.data.item, memberType);
          responseData.contacTypes = contacTypes;
          if (data && data.onSuccess) data.onSuccess(responseData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getMemberTypes({}, data) {
      api.get(
        "all/member-types",
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getMemberGroupList({}, data) {
      api.get(
        "all/member-groups/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCountries({}, data) {
      api.get(
        "region/countries",
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getCities({}, data) {
      api.get(
        "region/cities/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getDistricts({}, data) {
      api.get(
        "region/districts/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getNeighborhoods({}, data) {
      api.get(
        "region/neighborhoods/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result.data.items);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getRegions() {
      this.dispatch("member/getCountries");
      this.dispatch("member/getCities");
      this.dispatch("member/getDistricts");
      this.dispatch("member/getNeighborhoods");
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "members/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
