import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    globalMentions: [
      {
        id: "@SiteTitle",
        name: "Site Başlık",
      },
      {
        id: "@SiteUrl",
        name: "Site URL",
      },
    ],
    list: []
  }),
  mutations: {
    updateList(state, result) {
      const items = result 
                    && result.data 
                    && result.data.items
                    ? result.data.items
                    : [];

      state.list = items;
    }
  },
  getters: {
    requestData: () => (data) => {
      let result = null;

      if(data) {
        result = {};
        Object.entries(data).forEach(([key, value]) => {
          result[ "meta_" + key] = value;
        });
      }

      return result;
    }
  },
  actions: {
    load({ commit }) {
      commit("updateList");
    },
    getList({ commit }, data) {
      let path = data.route;
      let filter = data.filter;
      
      if(data.ajax) {
        filter = {...data.filter, ...data.table};
      }

      let filterQuery = helper.makeQueryString(filter);

      if(path) {
        if(filterQuery) {
          path += "?" + filterQuery;
        }

        api.get(
          "seo/" + path,
          (result) => {
            commit("updateList", result);
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    update({ getters }, data) {
      let path = data.route;
      let filterQuery = helper.makeQueryString(data.filter);
      let requestData = helper.clone(data.requestData);
      
      if(path) {
        if(filterQuery) {
          path += "?" + filterQuery;
        }

        api.post(
          "seo/" + path,
          getters.requestData(requestData),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    }
  }
}