import api from "../../shared/api";
import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    memberTypeList: [],
    new: {
      name: null,
      memberType: -1,
      is_open: false,
    },
    list: [],
  }),
  mutations: {
    setInformation(state, data) {
      if (data) {
        state.new = {
          name: data.name,
          memberType: data.member_type.id,
          is_open: data.is_open_account,
        };
      } else {
        state.new = {
          name: null,
          memberType: -1,
          is_open: false,
        };
      }
    },
    updateList(state, result) {
      if (result && result.data && result.data.items) {
        state.list = result.data.items;
      } else {
        state.list = [];
      }
    },
    setMemberTypes(state, result) {
      if (result) {
        const items = result.data.items;
        state.memberTypeList = items;
      } else {
        state.new.memberType = -1;
        state.memberTypeList = [];
      }
    },
  },
  actions: {
    load({ commit }) {
      commit("setInformation");
      this.dispatch("membergroup/getMemberTypes");
    },
    create({ state }, data) {
      api.post(
        "member-groups",
        {
          name: state.new.name,
          member_type_id: state.new.memberType,
          is_open_account: state.new.is_open === true ? 1 : 0,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        "member-groups/" + data.id,
        {
          name: state.new.name,
          member_type_id: state.new.memberType,
          is_open_account: state.new.is_open === true ? 1 : 0,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "member-groups/" + data.id,
        (result) => {
          helper.arrayRemove(state.list, "id", data.id);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "member-groups/" + data.id,
        (result) => {
          commit("setInformation", result.data.item);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "member-groups",
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getMemberTypes({ commit }, data) {
      commit("setMemberTypes");
      api.get(
        "all/member-types",
        (result) => {
          commit("setMemberTypes", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
