export const cart = [
  {
    template_id: "cart-1",
    template_based: "cart",
    template_description:
      "X TL ve üzeri alışverişlerde sepette %X veya X TL indirim",
    attributes: [
      {
        active: true,
        name: "price", // X adet
        placeholder: "X",
        operator: null,
        operators: [],
        type: "price",
        value: null,
        after_text: "TL ve üzeri alışverişlerde sepette",
      },
    ],
    result: {
      code: "discount",
      value: null,
      options: [],
      type: {
        id: "percent",
        name: "Yüzde",
      },
      type_options: [
        {
          id: "price",
          name: "Tutar",
        },
        {
          id: "percent",
          name: "Yüzde",
        },
      ],
    },
  },
  {
    template_id: "cart-2",
    template_based: "cart",
    template_description: "Seçili ürünlere sepette %X indirim",
    attributes: [
      {
        active: true,
        name: "product", // X Kategorisinden
        placeholder: "Seçili",
        operator: null,
        operators: [],
        type: "select",
        multiple: true,
        value: null,
        options: [],
        search_route: "search/products",
        after_text: "ürünlere sepette",
      },
    ],
    result: {
      code: "discount",
      value: null,
    },
  },
  {
    template_id: "cart-3",
    template_based: "cart",
    template_description: "Seçili markalara sepette %X indirim",
    attributes: [
      {
        active: true,
        name: "brand", // X Kategorisinden
        placeholder: "Seçili",
        operator: null,
        operators: [],
        type: "select",
        multiple: true,
        value: null,
        options: [],
        search_route: "search/brands",
        after_text: "markalara sepette",
      },
    ],
    result: {
      code: "discount",
      value: null,
    },
  },
  {
    template_id: "cart-4",
    template_based: "cart",
    template_description: "Seçili kategorilere sepette %X indirim",
    attributes: [
      {
        active: true,
        name: "category", // X Kategorisinden
        placeholder: "Seçili",
        operator: null,
        operators: [],
        type: "select",
        multiple: true,
        value: null,
        options: [],
        search_route: "search/categories",
        after_text: "kategorilere sepette",
      },
    ],
    result: {
      code: "discount",
      value: null,
    },
  },
];
