import responseImage from "../../models/response/image";

export default {
  new(item) {
    this.name = item.name;
    this.slug = item.slug;
    this.email = item.email;
    this.phone = item.phone;
    this.is_active = parseInt(item.is_active);
    this.city = item.city;
    this.district = item.district;
    this.address = item.address;
    this.location = item.location;
    this.image.files = [responseImage.clone(item.image, false)];
    this.cover_image.files = [responseImage.clone(item.cover_image, false)];
    if (item?.working_dates && item.working_dates.length) {
      this.working_dates = item.working_dates.map(result => {
        result.isOpen =  result.isOpen == "true"? true : false
        return result
      });
    }
    return this;
  },
  name: null,
  slug: null,
  email: null,
  phone: null,
  is_active: true,
  city: null,
  district: null,
  address: null,
  location: null,
  image: {
    files: [],
    filesData: null,
  },
  working_dates: [
    { name: "Pazartesi", open: new Date(), close: new Date(), isOpen: true },
    { name: "Salı", open: new Date(), close: new Date(), isOpen: true },
    { name: "Çarşamba", open: new Date(), close: new Date(), isOpen: true },
    { name: "Perşembe", open: new Date(), close: new Date(), isOpen: true },
    { name: "Cuma", open: new Date(), close: new Date(), isOpen: true },
    { name: "Cumartesi", open: new Date(), close: new Date(), isOpen: true },
    { name: "Pazar", open: new Date(), close: new Date(), isOpen: false },
  ],
  cover_image: {
    files: [],
    filesData: null,
  },
};
