import metaRequest from "../../models/request/meta";
export default {
  new(item) {
    let result = {};
    result.is_active = item.is_active ? 1 : 0;
    result.show_page_list = item.show_page_list ? 1 : 0;
    result.show_page_footer = item.show_page_footer ? 1 : 0;
    result.show_page_sidebar = item.show_page_sidebar ? 1 : 0;

    if (item.title) result.title = item.title.trim();
    if (item.slug) result.slug = item.slug.trim();
    if (item.contents)
      result.contents = helper.clone(item.contents).map((block) => {
        // Hashid değilse ID gönderme
        if (typeof block.id !== "string") {
          delete block.id;
        }

        if (block.columns && block.columns.length) {
          block.columns.map((col) => {
            if (col.title) col.title = col.title.trim();
            switch (col.type) {
              case "brands":
              case "products":
                col.content = helper.arrayPluck(col.content, ["id"]);
                break;
            }

            return col;
          });
        }
        return block;
      });

    result = { ...result, ...metaRequest(item.meta) };

    return result;
  },
};
