export default {
  n11: {
    logo: null,
    title: "n11",
    name: "n11",
    elements: [
      {
        name: "appKey",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appSecret",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "deliveryDay",
        value: null,
        form: "input",
        type: "text",
        title: "Teslimat Süresi",
        required: false,
        class: "col-12 col-md-6",
      },
      {
        name: "extraCost",
        value: null,
        form: "input",
        type: "text",
        title: "İlave Maliyet",
        required: false,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: null,
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: null,
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "appKey_cron_1",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appSecret_cron_1",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appKey_cron_2",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appSecret_cron_2",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appKey_cron_3",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "appSecret_cron_3",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
    ],
  },
  gittigidiyor: {
    logo: null,
    title: "Gitti Gidiyor",
    name: "gittigidiyor",
    elements: [
      {
        name: "developerId",
        value: null,
        form: "input",
        type: "text",
        title: "Geliştirici ID",
        required: true,
        class: "col-12",
      },
      {
        name: "roleName",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "rolePassword",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "apiKey",
        value: null,
        form: "input",
        type: "text",
        title: "API Anahtarı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "secretKey",
        value: null,
        form: "input",
        type: "text",
        title: "API Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
    ],
  },
  hepsiburada: {
    logo: null,
    title: "Hepsiburada",
    name: "hepsiburada",
    elements: [
      {
        name: "merchantId",
        value: null,
        form: "input",
        type: "text",
        title: "Merchant ID",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "deliveryDay",
        value: null,
        form: "input",
        type: "text",
        title: "Teslimat Süresi",
        required: false,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "extraCost",
        value: null,
        form: "input",
        type: "text",
        title: "İlave Maliyet",
        required: false,
        class: "col-12 col-md-12",
      },
    ],
  },
  trendyol: {
    logo: null,
    title: "Trendyol",
    name: "trendyol",
    elements: [
      {
        name: "supplierId",
        value: null,
        form: "input",
        type: "text",
        title: "Supplier ID",
        required: true,
        class: "col-12",
      },
      {
        name: "apiKey",
        value: null,
        form: "input",
        type: "text",
        title: "API Anahtarı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "apiSecret",
        value: null,
        form: "input",
        type: "text",
        title: "API Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "extraCost",
        value: null,
        form: "input",
        type: "text",
        title: "İlave Maliyet",
        required: false,
        class: "col-12 col-md-12",
      },
    ],
  },
  vodafone: {
    logo: null,
    title: "Vodafone",
    name: "vodafone",
    elements: [
      {
        name: "integratorCode",
        value: null,
        form: "input",
        type: "text",
        title: "Entegratör Kodu",
        required: true,
        class: "col-12",
      },
      {
        name: "username",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "password",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "extraCost",
        value: null,
        form: "input",
        type: "text",
        title: "İlave Maliyet",
        required: false,
        class: "col-12 col-md-12",
      },
    ],
  },
  ciceksepeti: {
    logo: null,
    title: "Çiçek Sepeti",
    name: "ciceksepeti",
    elements: [
      {
        name: "supplierId",
        value: null,
        form: "input",
        type: "text",
        title: "Supplier ID",
        required: true,
        class: "col-6",
      },
      {
        name: "apiKey",
        value: null,
        form: "input",
        type: "text",
        title: "API Key",
        required: true,
        class: "col-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "extraCost",
        value: null,
        form: "input",
        type: "text",
        title: "İlave Maliyet",
        required: false,
        class: "col-12 col-md-12",
      },
    ],
  },
  pazarama: {
    logo: null,
    title: "Pazarama",
    name: "pazarama",
    elements: [
      {
        name: "clientId",
        value: null,
        form: "input",
        type: "text",
        title: "API Key",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "clientSecret",
        value: null,
        form: "input",
        type: "text",
        title: "API Secret",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
    ],
  },
  epttavm: {
    logo: null,
    title: "e-Ptt AVM",
    name: "epttavm",
    elements: [
      {
        name: "shopId",
        value: null,
        form: "input",
        type: "text",
        title: "Mağaza Numarası",
        required: true,
        class: "col-12",
      },
      {
        name: "username",
        value: null,
        form: "input",
        type: "text",
        title: "Kullanıcı Adı",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "password",
        value: null,
        form: "input",
        type: "text",
        title: "Şifre",
        required: true,
        class: "col-12 col-md-6",
      },
      {
        name: "orderIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Sipariş Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
      {
        name: "productIntegration",
        value: {id: 1, name: 'Aktif'},
        form: "select",
        type: "text",
        title: "Ürün Entegrasyonu",
        required: false,
        class: "col-12 col-md-6",
        options: [
          {id: 1, name: 'Aktif'},
          {id: 0, name: 'Pasif'}
        ]
      },
    ],
  },
};
