import api from "../../shared/api";

export default {
  state: () => ({
    // TODO: Api tamamlanınca temizlenecek
    list: [],
  }),
  actions: {
    getBankList({ state }, data) {
      state.list = [];
      api.get(
        "all/payment-banks",
        (result) => {
          state.list = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
