import imageRequest from "../../models/request/image";
import metaRequest from "./meta";

export default {
  new(form) {
    let result = {};

    if (form.name) {
      result.name = form.name;
    }
    if (form.slug) {
      result.slug = form.slug;
    }
    if (form.types) {
        result.types = form.types;
    }
    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    if (form.hasOwnProperty("is_micro_site")) {
      result.is_micro_site = form.is_micro_site === true ? 1 : 0;
    }
    if (form.description && form.description.text) {
      result.description = form.description.text;
    }
    if (form.description && form.description.hasOwnProperty("show")) {
      result.show_description = form.description.show === true ? 1 : 0;
    }
    if (form.description && form.description.place) {
      result.description_place = form.description.place;
    }
    if (form.title) {
      result.title = form.title;
    }
    if (form.sub_title) {
      result.sub_title = form.sub_title;
    }
    if (form.embed_url) {
      result.embed_url = form.embed_url;
    }
    result.image = imageRequest(form.image.files, false);
    result.mobile_image = imageRequest(form.mobile_image.files, false);

    result = { ...result, ...metaRequest(form.meta) };
    return result;
  },
  image: null,
  mobile_image: null
};
