import api from "../../shared/api";
export default {
  namespaced: true,
  state:() =>({
    list: []
  }),
  actions: {
    crud({}, data) {
      if(data && data.form) {
        api.post(
          "redirect-urls",
          data.form,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getList({state}, data) {
      let routePath = "redirect-urls";
      let queryString = {...data.table };

      routePath += "?" + helper.makeQueryString(queryString);
      api.get(
        routePath,
        (result) => {
          state.list = result.data.items 
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    create({}, data) {
      api.post(
        `redirect-urls`,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({}, data) {
      api.put(
        `redirect-urls/${data.id}`,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        `redirect-urls/${data.id}`,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
