import api from "../../shared/api";
import reqHomeSeo from "../../models/request/homeSeo";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    id: null,
  }),
  mutations: {},
  actions: {
    create({ state }, data) {
      api.post(
        `homeSeo?lang=${state.lang}`,
        reqHomeSeo.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      if (data && data.id) {
        api.put(
          `homeSeo/${data.id}?lang=${state.lang}`,
          reqHomeSeo.new(data.form),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getHomeSeo({ state }, data) {
      api.get(
        `all/homeseos?lang=${state.lang}`,
        (result) => {
          let responseData = result.data.items;
          if (data && data.onSuccess) data.onSuccess(responseData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
