import api from "../../shared/api";
import requestBrand from "../../models/request/brand";
import responseBrand from "../../models/response/brand";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      // state.lang = "tr";
      let items = payload.data ? payload.data.items : payload;

      if (items && items.length) {
        items = items
          .map((item, key) => {
            item.order_no = item.order_no || key + 1;
            item.type_name = "Marka";
            if (item.types) {
              if (item.types.length > 0) {
                item.type_name = item.types[0].name;
              }
            }
            return item;
          })
          .sort((a, b) => a.order_no - b.order_no);
      }

      state.list = items;
    },
    updateLang(state, lang) {
      state.lang = lang;
    },
    clearList(state) {
      state.lang = "tr";
      state.list = [];
    },
  },
  actions: {
    create({ state }, data) {
      if (data.form) {
        api.post(
          `brands?lang=${state.lang}`,
          requestBrand.new(data.form),
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    update({ state }, data) {
      api.put(
        `brands/${data.id}?lang=${state.lang}`,
        requestBrand.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "brands/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          this.dispatch("brand/getList");
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "brands/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "brands/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      commit("clearList");
      if (data && data.lang) commit("updateLang", data.lang);
      let routePath = "all/brands";
      let queryString = { lang: state.lang, ...data.table, filter: {type: data?.type || null} };

      routePath += "?" + helper.makeQueryString(queryString);

      if (data && data.deleted) {
        routePath += "deleted_at=1";
      }

      api.get(
        routePath,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `brands/${data.id}?lang=${state.lang}`,
        (result) => {
          let resultData = responseBrand.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "brands/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    orderUpdate({ commit, state }, data) {
      api.post(
        "brands/sorting",
        data.form,
        (result) => {
          commit("updateList", state.list);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
