export default {
  new(data) {
    let result = {};
    result.config = {};
    result.is_active = data.is_active ? 1 : 0;
    result.is_test = data.is_test ? 1 : 0;
    result.is_default = data.is_default ? 1 : 0;

    if (data && data.environments) {
      data.environments.forEach((invoiceEnv) => {
        invoiceEnv.elements.forEach((invoiceElement) => {
          result.config[invoiceEnv.name] = {
            ...result.config[invoiceEnv.name],
          };

          let value = invoiceElement.value;
          switch (invoiceElement.name) {
            case "einvoice":
            case "earchive":
              value = Boolean(value);
              break;
          }

          result.config[invoiceEnv.name][invoiceElement.name] = value;
        });
      });
    }

    return result;
  },
};
