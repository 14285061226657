import metaRequest from "./meta";

export default {
  new(item) {
    let result = {};
    if (item.title) result.title = item.title;
    if (item.content) result.content = item.content;
    if (item.slug) result.slug = item.slug;
    if (item.hasOwnProperty("is_approvable")) {
      result.is_approvable = item.is_approvable === true ? 1 : 0;
    }
    if (item.hasOwnProperty("is_emailable")) {
      result.is_emailable = item.is_emailable === true ? 1 : 0;
    }
    if (item.hasOwnProperty("is_stock_notify")) {
      result.is_stock_notify = item.is_stock_notify === true ? 1 : 0;
    }
    if (item.hasOwnProperty("is_contact")) {
      result.is_contact = item.is_contact === true ? 1 : 0;
    }
    if (item.hasOwnProperty("confirm_on_register")) {
      result.confirm_on_register = item.confirm_on_register === true ? 1 : 0;
    }
    result = { ...result, ...metaRequest(item.meta) };

    return result;
  },
};
