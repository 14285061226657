import language from "../../shared/language/index";

export default {
  namespaced: true,
  state: () => ({
    isVisible: true,
    items: [
      {
        group: "header",
        key: "header-notifications",
        icon: "fas fa-bell",
      },
      {
        group: "header-account",
        key: "header-account-dropdown",
        items: [
          {
            key: "header-account-profile",
            icon: "fas fa-user",
            path: "account/profile",
          },
          {
            key: "header-account-credit",
            icon: "fas fa-coins",
            path: "account/credit",
          },
          {
            key: "header-account-settings",
            icon: "fas fa-cog",
            path: "account/settings",
          },
          {
            key: "header-account-signout",
            icon: "fas fa-sign-out-alt",
            path: "account/sign-out",
          },
        ],
      },
      {
        group: "header-menu",
        key: "header-menu-products-dropdown",
        icon: "fas fa-product",
        megamenu: true,
        items: [
          {
            group: "header-menu",
            key: "header-menu-products",
            icon: "fas fa-boxes",
            title: true,
            path: "products",
            items: [
              {
                key: "header-menu-products-new",
                icon: "fas fa-plus",
                path: "products/new",
              },
              {
                key: "header-menu-products-list",
                icon: "fas fa-bars",
                path: "products",
              },
            ],
          },
          {
            group: "header-menu",
            key: "header-menu-categories",
            icon: "fas fa-stream",
            items: [
              {
                key: "header-menu-categories-new",
                icon: "fas fa-plus",
                path: "categories/new",
              },
              {
                key: "header-menu-categories-list",
                icon: "fas fa-bars",
                path: "categories",
              },
            ],
          },
          {
            group: "header-menu",
            key: "header-menu-brands",
            icon: "far fa-copyright",
            items: [
              {
                key: "header-menu-brands-new",
                icon: "fas fa-plus",
                path: "brands/new",
              },
              {
                key: "header-menu-brands-list",
                icon: "fas fa-bars",
                path: "brands",
              },
            ],
          },
          {
            group: "header-menu",
            key: "header-menu-models",
            icon: "fas fa-code-branch",
            items: [
              {
                key: "header-menu-new",
                icon: "fas fa-plus",
                path: "models/new",
              },
              {
                key: "header-menu-list",
                icon: "fas fa-bars",
                path: "models",
              },
            ],
          },
          {
            group: "header-menu",
            key: "header-menu-specs",
            icon: "fas fa-network-wired",
            items: [
              {
                key: "header-menu-specs-new",
                icon: "fas fa-plus",
                path: "specs/new",
              },
              {
                key: "header-menu-specs-list",
                icon: "fas fa-bars",
                path: "specs",
              },
            ],
          },
          {
            group: "header-menu",
            key: "header-menu-settings",
            icon: "fas fa-cogs",
          },
        ],
      },
      {
        group: "footer",
        key: "footer-documents",
        icon: "fas fa-file-alt",
        path: "documents",
      },
    ],
    translatedItems: [],
  }),
  getters: {
    getTranslatedItems: (state, getters) => (parentItem) => {
      const result = [];

      let currentParent =
        parentItem && parentItem.items && parentItem.items.length
          ? parentItem
          : state;

      currentParent.items.forEach((item) => {
        const newItem = { ...item };

        const text = language.translate(item.key + "-text", "admin");
        const description = language.translate(
          item.key + "-description",
          "admin"
        );

        if (text) newItem.text = text;
        if (description) newItem.description = description;

        if (item.items && item.items.length) {
          newItem.items = getters.getTranslatedItems(item);
        }

        result.push(newItem);
      });

      return result;
    },
  },
  mutations: {
    setVisible(state, value) {
      state.isVisible = value;
    },
    setTranslatedItems(state, value) {
      state.translatedItems = value;
    },
  },
  actions: {
    updateVisible({ commit }, value) {
      commit("setVisible", value);
    },
    updateTranslatedItems({ commit, getters, state }) {
      commit("setTranslatedItems", getters.getTranslatedItems());
      //***console.log(JSON.stringify(state.translatedItems));
    },
  },
};
