export default {
  new(data) {
    const result = {};
    if (data.title) {
      result.title = data.title;
    }
    if (data.content) {
      result.content = data.content;
    }
    if (data.hasOwnProperty('url')) {
      result.url = data.url;
    }
    if (data.location) {
      result.location = data.location;
    }
    if (data.start_date) {
      result.start_date = data.start_date;
    }
    if (data.end_date) {
      result.end_date = data.end_date;
    }
    if (data.condition) {
      result.condition = data.condition;
    }
    if (data.hasOwnProperty("condition_value")) {
      result.condition_value = data.condition_value || 0;
    }
    if (data.hasOwnProperty("is_active")) {
      result.is_active = data.is_active === true ? 1 : 0;
    }

    return result;
  },
};
