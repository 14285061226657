export default {
  new(form) {
    let result = {};

    if (form.name) {
      result.name = form.name;
    }
    if (form.password) {
      result.password = form.password;
    }
    if (form.username) {
      result.username = form.username;
    }
    if (form.address) {
      result.address = form.address;
    }    
    if (form.type) {
        result.type = form.type;
    }
    if(form.stock_quantity) {
      result.stock_quantity = form.stock_quantity
    }
    if(form.is_save_category) {
      result.is_save_category = form.is_save_category
    }
    if(form.is_stock_track) {
      result.is_stock_track = form.is_stock_track
    }
    if (form.template) {
      if(form.template.id) {
        result.template_id = form.template.id;
      } else {
        result.template_id = form.template;
      }
    }
    if (form.currency) {
      if(form.currency.id) {
        result.currency_id = form.currency.id;
      } else {
        result.currency_id = form.currency;
      }
    }
    if (form.hasOwnProperty("is_main")) {
        result.is_main = form.is_main === true ? 1 : 0;
    }
    if (form.hasOwnProperty("is_active")) {
      result.is_active = form.is_active === true ? 1 : 0;
    }
    result = { ...result };
    return result;
  },
  image: null,
};
