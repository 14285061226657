import api from "../../shared/api";
import categoryRequest from "../../models/request/category";
import helper from "../../shared/helper";
import responseCategory from "../../models/response/category";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
    breadCrumb: [],
  }),
  getters: {
    getCategoryList: (state, getters) => (parent) => {
      const result = [];

      const currentParent =
        parent && parent.children && parent.children.length
          ? parent.children
          : state.list;

      if (currentParent && currentParent.length) {
        currentParent.forEach((item, key) => {
          const newItem = { ...item };

          newItem.order_no = item.order_no || key + 1;
          newItem.active_count = item.active_products_count || 0;
          newItem.passive_count = item.passive_products_count || 0;

          if (item.children && item.children.length) {
            newItem.children = getters.getCategoryList(item);
          }
          result.push(newItem);
        });
      }

      return result.sort((a, b) => a.order_no - b.order_no);
    },
  },
  mutations: {
    updateList(state, payload) {
      state.lang = "tr";
      state.list = payload;
    },
    clearList(state) {
      state.lang = "tr";
      state.list = [];
    },
    updateLang(state, lang) {
      state.lang = lang;
    },
  },
  actions: {
    create({ state }, data) {
      api.post(
        `categories?lang=${state.lang}`,
        categoryRequest.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        `categories/${data.id}?lang=${state.lang}`,
        categoryRequest.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      let routePath = "categories/" + data.id;

      if (data && data.hasOwnProperty("delete_child") && data.delete_child) {
        routePath += "?delete_child=1";
      }

      api.delete(
        routePath,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        `categories/${data.id}`,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        `categories/${data.id}`,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, getters, state }, data) {
      commit("clearList");
      let id = data.id ? data.id : null;
      let path = id ? "categories/" + id + "/children" : "all/categories";

      if (data.lang) commit("updateLang", data.lang);
      else commit("updateLang", "tr");

      path += `?lang=${state.lang}`;

      if (data.deleted) {
        path += "&deleted_at=1";
      }

      if (data.list) {
        path += "&list=" + data.list;
      }

      api.get(
        path,
        (result) => {
          commit("updateList", result.data.items);
          commit("updateList", getters.getCategoryList());
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `categories/${data.id}?lang=${state.lang}`,
        (result) => {
          let responseData = responseCategory.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(responseData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          `categories/isActive/${requestData.id}`,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    showCase({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          `categories/isShowcase/${requestData.id}`,
          {
            is_showcase: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    showMenu({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          `categories/isShowMenu/${requestData.id}`,
          {
            is_showmenu: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    orderUpdate({ commit, getters }, data) {
      api.post(
        "categories/sorting",
        data.form,
        (result) => {
          commit("updateList", getters.getCategoryList());
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getGoogleCategories({}, data) {
      let route = "google-categories";

      if (data.category_id) {
        route += "/" + data.category_id;
      }

      api.get(
        route,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
