import responseImage from "../../models/response/image";

export default {
  new(item) {
    this.name = item.name;
    this.is_active = item.is_active === 1;
    this.selected.brand = item.brand_id;
    this.slug = item.slug;
    this.description = {
      text: item.description,
      show: item.show_description === 1,
      place: item.description_place ? item.description_place : "before",
    };
    this.meta = {
      title: item.meta_title,
      keywords: item.meta_keywords,
      description: item.meta_description,
      robots: ["index", "follow"], //item.meta_robots ? item.meta_robots.split(", ") : [],
    };
    this.image.files = [responseImage.clone(item.image, false)];
    this.mobile_image.files = [responseImage.clone(item.mobile_image, false)];
    this.cover_image.files = [responseImage.clone(item.cover_image, false)];
    this.title = item.title;
    this.sub_title = item.sub_title;
    this.embed_url = item.embed_url;

    return this;
  },
  selected: {
    brand: -1,
  },
  image: {
    files: [],
    filesData: null,
  },
  mobile_image: {
    files: [],
    filesData: null,
  },
  cover_image: {
    files: [],
    filesData: null,
  },
};
