export default {
  new(data) {
    const result = {};
    if (data.first_name) result.first_name = data.first_name;
    if (data.last_name) result.last_name = data.last_name;
    if (data.phone) result.phone = data.phone;
    if (data.email) result.email = data.email;
    if (data.password && data.password_confirmation) {
      result.password = data.password;
      result.password_confirmation = data.password_confirmation;
    }
    if (data.role_group_id) result.role_group_id = data.role_group_id;
    return result;
  },
  clone(result) {
    if (result) {
      this.first_name = result.first_name;
      this.last_name = result.last_name;
      this.phone = result.phone;
      this.email = result.email;
      this.password = result.password;
      this.password_confirmation = result.password_confirmation;
      this.role_group_id = result.role_group_id;
      return this;
    } else {
      return null;
    }
  },
};
