import store from "../../store";

export default {
  acceptLanguages: ["tr", "en"],
  defaultLanguage: "tr",
  language: "tr",
  store,
  setLanguage(language) {
    if (language && this.acceptLanguages.indexOf(language) >= 0) {
      this.language = language;
      localStorage.setItem("language", language);
    } else {
      this.language = this.defaultLanguage;
      localStorage.setItem("language", language);
    }

    this.store.dispatch("menu/updateTranslatedItems");
  },
  translate(key, prefix) {
    let result = "";
    const itemKey = prefix + "." + key.replace(/-/gi, ".");

    //...
    // temporary test logic
    const dummyResource = {
      languages: [
        {
          name: "tr",
          elements: [
            {
              key: "admin.header.notifications.text",
              value: "Bildirimler",
            },
            {
              key: "admin.header.account.profile.text",
              value: "Profil",
            },
            {
              key: "admin.header.account.credit.text",
              value: "Profil",
            },
            {
              key: "admin.header.account.settings.text",
              value: "Ayarlar",
            },
            {
              key: "admin.header.account.signout.text",
              value: "Çıkış",
            },
            {
              key: "admin.header.menu.products.dropdown.text",
              value: "Ürün",
            },
            {
              key: "admin.header.menu.products.text",
              value: "Ürünler",
            },
            {
              key: "admin.header.menu.products.list.text",
              value: "Ürün Listesi",
            },
            {
              key: "admin.header.menu.products.new.text",
              value: "Yeni Ürün Ekle",
            },
          ],
        },
        {
          name: "en",
          elements: [
            {
              key: "admin.header.notifications.text",
              value: "Notifications",
            },
            {
              key: "admin.header.account.profile.text",
              value: "Profile",
            },
            {
              key: "admin.header.account.credit.text",
              value: "Credit",
            },
            {
              key: "admin.header.account.settings.text",
              value: "Settings",
            },
            {
              key: "admin.header.account.signout.text",
              value: "Sign Out",
            },
          ],
        },
      ],
    };

    function findValue(resource, language, key) {
      let result = "";

      for (let i = 0; i < resource.languages.length; i++) {
        const currentLanguage = resource.languages[i];

        if (currentLanguage.name != language) continue;

        for (let j = 0; j < currentLanguage.elements.length; j++) {
          const element = currentLanguage.elements[j];

          if (element.key == key) {
            result = element.value;
            break;
          }
        }
      }

      return result;
    }

    if (this.language) {
      result = findValue(dummyResource, this.language, itemKey);
    } else {
      result = findValue(dummyResource, this.defaultLanguage, itemKey);
    }

    return result;
  },
};
