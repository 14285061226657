export default {
  new(currencies) {
    if (currencies) {
      return currencies.map((c) => {
        const data = {};
        data.id = c.id;
        data.code = c.code;
        data.currency_buying = c.buying;
        data.currency_selling = c.selling;

        return data;
      });
    }

    return currencies;
  },
};
