export default function metaRequest(meta) {
  let result = {};

  // Meta Title
  if ((meta && meta.title) || (meta && meta.title == "")) {
    result.meta_title = meta.title;
  }

  // Meta Keywords
  if ((meta && meta.keywords) || (meta && meta.keywords == "")) {
    result.meta_keywords = meta.keywords;
  }

  // Meta Description
  if ((meta && meta.description) || (meta && meta.description == "")) {
    result.meta_description = meta.description;
  }

  // Canonical URL
  if ((meta && meta.canonicalUrl) || (meta && meta.canonicalUrl == "")) {
    result.canonical_url = meta.canonicalUrl;
  }

  // result.meta_robots = meta.robots
  //   ? meta.robots.join(", ")
  //   : "";

  return result;
}
