import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    lang: "tr",
    list: [],
  }),
  actions: {
    updateOrCreate({ state }, data) {
      api.post(
        `top-titles?lang=${state.lang}`,
        {
          titles: data.list,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ state }, data) {
      api.get(
        `top-titles?lang=${state.lang}`,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
