import api from "../../shared/api";
import erpRequest from "../../models/request/erp";
import erpResponse from "../../models/response/erp";
import erpJson from "../../models/json/erp";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    setList(state, items = []) {
      if(items && items.length) {
        items = items.map((item) => {
          const erpItem = erpJson.items.find((erp) => erp.name === item.name);
          const newItem = {
            id: item.id,
            is_active: item.is_active,
            is_test: item.is_test,
            is_default: item.is_default,
            ...erpItem};
          
          return newItem;
        });
      }
      state.list = items
    }
  },
  actions: {
    update({}, data) {
      api.put(
        "erp-integration/" + data.erp.id,
        erpRequest.new(data.erp),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "erp-integration/" + data.id,
        (result) => {
          const resultData = result && result.data && result.data.item ? erpResponse.new(result.data.item) : null;
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      api.get(
        "erp-integration",
        (result) => {
          commit("setList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "erp-integration/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
