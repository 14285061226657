import api from "../../shared/api";

export default {
  actions: {
    searchBrand({}, data) {
      const path =
        data.form.marketplace.market.name + "/brands/" + data.form.query;
      api.get(
        path,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
