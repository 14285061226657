import api from "../../shared/node-api";
export default {
  namespaced: true,
  state: () => ({
    products: [],
    competitors: [],
  }),
  mutations: {
    updateProductList(state, data) {
      let result = state.products;

      if (data && data.id) {
        result.push(data);
      } else if (Array.isArray(data)) {
        result = data.map((product) => {
          if (!product.competitors) product.competitors = [];
          product.loading = true;
          return product;
        });
      }

      state.products = result;
    },
  },
  getters: {
    copyProducts: (state) => {
      return helper.clone(state.products);
    },
  },
  actions: {
    /**
     *
     * @param {} param0
     * @param {*} data
     * Sayfa yüklenirken kullanıcı giriş bilgilerini kontrol eder
     * Localstorage'da kullanıcı adı ve şifre yoksa ayarlardan bilgileri kontrol edip login olur
     * Eğer Localstorage'da bilgiler mevcut ise callback function çalıştırır
     */
    load({}, data) {
      const stored = JSON.parse(localStorage.getItem("session"));
      if (stored) {
        if (!stored.priceAnalysis) {
          api.refreshLogin();
          setTimeout(() => {
            if (data && data.onFinish) data.onFinish();
          }, 500);
        } else {
          setTimeout(() => {
            if (data && data.onFinish) data.onFinish();
          }, 500);
        }
      }
    },
    getProducts({ commit }, data) {
      api.get(
        "products",
        (result) => {
          commit("updateProductList", result.data.products);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getSingleProduct({ commit }, data) {
      if (data.skuNo) {
        api.get(
          `products/${data.skuNo}`,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    createProduct({ commit, state }, data) {
      api.post(
        "products",
        {
          name: data.form.name,
          skuNo: data.form.sku_no,
          currency: data.form.currency_code
            ? data.form.currency_code.code.toUpperCase()
            : "TRY",
        },
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            const productData = {};
            productData.id = result.data.id;
            productData.sku_no = data.form.sku_no;
            productData.name = data.form.name;
            productData.price = 0;
            productData.image = null;
            productData.competitors = result.data.competitors || [];
            productData.loading = true;

            commit("updateProductList", productData);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    createProductCompetitor({ commit }, data) {
      api.post(
        "competitors",
        data.requestData,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    removeCompetitor({ state }, data) {
      api.delete(
        "competitors",
        data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    removeProduct({ state }, data) {
      api.delete(
        "products",
        data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.products, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    favoriteProduct({ state }, data) {
      api.put(
        "products",
        {
          id: data.id,
          pinned: data.status,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    updateEmail({ state }, data) {
      if (data && data.email) {
        api.put(
          "auth",
          {
            notificationEmail: data.email,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getPriceList({ commit }, data) {
      if (data.competitor_id) {
        api.get(
          "prices?competitorId=" + data.competitor_id,
          (result) => {
            commit("updateProductList", result.data.products);
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getPriceAgain({ commit }, data) {
      api.post(
        "retry",
        { competitorId: data.id },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
