import axios from "axios";

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

export default {
  namespaced: true,
  state: () => ({
    cache_key:
      "6LADFFXV2VAbwkzFwrlKItSLDGqWAkkhOWxSyOEK08NrqfUFE127dr3GJtm1iR8Jb1cUgMLbz9GMZI5hqvgPFMKxUhDS9pPmYz1x8efZsn4wHwr1we0da2CmOX5kKd3e",
  }),
  actions: {
    clear({}, data) {
      const session = JSON.parse(localStorage.getItem("session"));
      axios
        .post(`${session.config["site.url"]}/api/optimize-clear`)
        .then(() => {
          if (data && data.onSuccess) data.onSuccess();
        });
    },
    async showcaseClear({ state }, data) {
      const session = JSON.parse(localStorage.getItem("session"));
      const keys = ["banner", "block", "category", "all"];
      for (const element of keys) {
        try {
          await axios.post(
            `${session.config["site.url"]}/api/cache-manager/showcase-relaod/${element}`,
            {},
            {
              headers: {
                "x-api-key": state.cache_key,
              },
            }
          );
        } catch (ex) {}
      }
      if (data && data.onSuccess) data.onSuccess();
    },
  },
};
