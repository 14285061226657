import Vue from "vue";
import VueRouter from "vue-router";
import constant from "../shared/constant";
import { i18n } from "../locales/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    component: () => import("../views/NotFound/Index"),
  },
  {
    path: "/health-check",
    component: () => import("../views/HealthCheck/Index"),
  },
  {
    path: "/not-found",
    component: () => import("../views/NotFound/Index"),
  },
  {
    path: "/access-denied",
    component: () => import("../views/Unauthorized/Index"),
  },
  {
    path: "/login",
    component: () => import("../views/Login/Index"),
  },
  {
    path: "/logout",
    component: () => import("../views/Logout/Index"),
  },
  {
    path: "",
    component: () => import("../views/Home/Index"),
    children: [
      {
        path: "/",
        components: {
          home: () => import("../views/Home/views/Dashboard/Index"),
        },
      },
      {
        path: "/brands",
        components: {
          home: () => import("../views/Home/views/Brand/Index"),
        },
        meta: {
          breadCrumb: "brands",
          permission: {
            key: "brand",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              brand: () => import("../views/Home/views/Brand/views/List/Index"),
            },
            meta: {
              breadCrumb: "brands",
              permission: {
                key: "brand",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              brand: () =>
                import("../views/Home/views/Brand/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addNewBrand",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              brand: () =>
                import("../views/Home/views/Brand/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editBrand",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              brand: () =>
                import("../views/Home/views/Brand/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "brand",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/brand-types",
        components: {
          home: () => import("../views/Home/views/BrandType/Index"),
        },
        meta: {
          breadCrumb: "brandTypes",
          permission: {
            key: "brand",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              brand: () =>
                import("../views/Home/views/BrandType/views/List/Index"),
            },
            meta: {
              breadCrumb: "brandTypeList",
              permission: {
                key: "brand",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              brand: () =>
                import("../views/Home/views/BrandType/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addNewBrandType",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              brand: () =>
                import("../views/Home/views/BrandType/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editBrandType",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              brand: () =>
                import("../views/Home/views/BrandType/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "brand",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/supplier-integration",
        components: {
          home: () => import("../views/Home/views/SupplierIntegration/Index"),
        },
        meta: {
          breadCrumb: "supplierIntegration",
          permission: {
            key: "brand",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/List/Index"
                ),
            },
            meta: {
              breadCrumb: "supplierIntegrationList",
              permission: {
                key: "brand",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Detail/Index"
                ),
            },
            meta: {
              breadCrumb: "addNewSupplierIntegration",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "category",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Category/Index"
                ),
            },
            meta: {
              breadCrumb: "supplierCategoryMatching",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "reduction-and-profit",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Reduction/Index"
                ),
            },
            meta: {
              breadCrumb: "supplierReductionAndProfit",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "filters",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Filter/Index"
                ),
            },
            meta: {
              breadCrumb: "supplierFilter",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Detail/Index"
                ),
            },
            meta: {
              breadCrumb: "editSupplierIntegration",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/SupplierIntegration/views/Trash/Index"
                ),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "brand",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/import-data",
        components: {
          home: () => import("../views/Home/views/ImportData/Index"),
        },
        meta: {
          breadCrumb: "imports",
          permission: {
            key: "brand",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              brand: () =>
                import(
                  "../views/Home/views/ImportData/views/Index"
                ),
            },
            meta: {
              breadCrumb: "imports",
              permission: {
                key: "brand",
                code: "v",
              },
            },
          },
        ],
      },
      {
        path: "/xml-template",
        components: {
          home: () => import("../views/Home/views/XmlTemplate/Index"),
        },
        meta: {
          breadCrumb: "xmlTemplate",
          permission: {
            key: "brand",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              brand: () =>
                import("../views/Home/views/XmlTemplate/views/List/Index"),
            },
            meta: {
              breadCrumb: "xmlTemplateList",
              permission: {
                key: "brand",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              brand: () =>
                import("../views/Home/views/XmlTemplate/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addNewXmlTemplate",
              permission: {
                key: "brand",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              brand: () =>
                import("../views/Home/views/XmlTemplate/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editXmlTemplate",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              brand: () =>
                import("../views/Home/views/XmlTemplate/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "brand",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/models",
        components: {
          home: () => import("../views/Home/views/Model/Index"),
        },
        meta: {
          breadCrumb: "models",
          permission: {
            key: "model",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              model: () => import("../views/Home/views/Model/views/List/Index"),
            },
            meta: {
              breadCrumb: "models",
              permission: {
                key: "model",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              model: () =>
                import("../views/Home/views/Model/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addNewModel",
              permission: {
                key: "model",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              model: () =>
                import("../views/Home/views/Model/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editModel",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              model: () =>
                import("../views/Home/views/Model/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "model",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/packages",
        components: {
          home: () => import("../views/Home/views/Package/Index"),
        },
        meta: {
          breadCrumb: "productPackages",
        },
        children: [
          {
            path: "",
            components: {
              package: () =>
                import("../views/Home/views/Package/views/List/Index"),
            },
            meta: {
              breadCrumb: "productPackages",
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              package: () =>
                import("../views/Home/views/Package/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addPackage",
            },
          },
          {
            path: "detail/:id",
            components: {
              package: () =>
                import("../views/Home/views/Package/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editPackage",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              package: () =>
                import("../views/Home/views/Package/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "product",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/equipments",
        components: {
          home: () => import("../views/Home/views/Equipment/Index"),
        },
        meta: {
          breadCrumb: "equipments",
        },
        children: [
          {
            path: "",
            components: {
              equipment: () =>
                import("../views/Home/views/Equipment/views/List/Index"),
            },
            meta: {
              breadCrumb: "equipments",
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              equipment: () =>
                import("../views/Home/views/Equipment/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addEquipment",
            },
          },
          {
            path: "detail/:id",
            components: {
              equipment: () =>
                import("../views/Home/views/Equipment/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editEquipment",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/showcase-management",
        components: {
          home: () => import("../views/Home/views/Showcase/Index"),
        },
        meta: {
          breadCrumb: "showcaseManagement",
        },
        children: [
          {
            path: "",
            meta: {
              breadCrumb: "showcaseManagement",
            },
            components: {
              showcase: () =>
                import("../views/Home/views/Showcase/views/Blocks/Index"),
            },
          },
          {
            path: "category",
            meta: {
              breadCrumb: "categoryList",
            },
            components: {
              showcase: () =>
                import("../views/Home/views/Showcase/views/Category/Index"),
            },
            children: [
              {
                path: "",
                components: {
                  showcaseCategory: () =>
                    import(
                      "../views/Home/views/Showcase/views/Category/views/List/Index"
                    ),
                },
              },
              {
                path: ":id",
                components: {
                  showcaseCategory: () =>
                    import(
                      "../views/Home/views/Showcase/views/Category/views/Detail/Index"
                    ),
                },
              },
            ],
          },
          {
            path: ":id",
            components: {
              showcase: () =>
                import("../views/Home/views/Showcase/views/Edit/Index"),
            },
          },
        ],
      },
      {
        path: "/products",
        components: {
          home: () => import("../views/Home/views/Product/Index"),
        },
        meta: {
          breadCrumb: "products",
          permission: {
            key: "product",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              product: () =>
                import("../views/Home/views/Product/views/List/Index"),
            },
            meta: {
              breadCrumb: "products",
              permission: {
                key: "product",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              product: () =>
                import("../views/Home/views/Product/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addProduct",
              permission: {
                key: "product",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              product: () =>
                import("../views/Home/views/Product/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editProduct",
              edit: true,
            },
          },
          {
            path: "bulk-update",
            components: {
              product: () =>
                import("../views/Home/views/Product/views/BulkUpdate/Index"),
            },
            meta: {
              breadCrumb: "bulkProductUpdate",
              permission: {
                key: "product",
                code: "u",
              },
            },
          },
          {
            path: "trash",
            components: {
              product: () =>
                import("../views/Home/views/Product/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "product",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/bulk-actions",
        components: {
          home: () =>
            import("../views/Home/views/BulkActions/views/Email/Index"),
        },
        meta: {
          breadCrumb: "bulkActions",
        },
        children: [
          {
            path: "email",
            meta: {
              breadCrumb: "sendEmail",
            },
            components: {
              bulkAction: () =>
                import("../views/Home/views/BulkActions/views/Email/Index"),
            },
          },
        ],
      },
      {
        path: "/email-templates",
        components: {
          home: () => import("../views/Home/views/EmailTemplate/Index"),
        },
        meta: {
          breadCrumb: "emailTemplates",
        },
        children: [
          {
            path: "",
            components: {
              emailTemplate: () =>
                import("../views/Home/views/EmailTemplate/views/List/Index"),
            },
            meta: {
              breadCrumb: "customers",
              // permission: {
              //   key: "member",
              //   code: "v",
              // },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              emailTemplate: () =>
                import("../views/Home/views/EmailTemplate/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "newEmailTemplate",
              // permission: {
              //   key: "member",
              //   code: "c",
              // },
            },
          },
          {
            path: "detail/:id",
            components: {
              emailTemplate: () =>
                import("../views/Home/views/EmailTemplate/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editEmailTemplate",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              emailTemplate: () =>
                import("../views/Home/views/EmailTemplate/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              // permission: {
              //   key: "member",
              //   code: ["r", "f"],
              // },
            },
          },
        ],
      },
      {
        path: "/email-lists",
        components: {
          home: () => import("../views/Home/views/EmailList/Index"),
        },
        meta: {
          breadCrumb: "emailList",
        },
        children: [
          {
            path: "",
            components: {
              emailList: () =>
                import("../views/Home/views/EmailList/views/List/Index"),
            },
            meta: {
              breadCrumb: "emailList",
              // permission: {
              //   key: "member",
              //   code: "v",
              // },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              emailList: () =>
                import("../views/Home/views/EmailList/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "newEmailList",
              // permission: {
              //   key: "member",
              //   code: "c",
              // },
            },
          },
          {
            path: "detail/:id",
            components: {
              emailList: () =>
                import("../views/Home/views/EmailList/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editEmailList",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              emailList: () =>
                import("../views/Home/views/EmailList/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              // permission: {
              //   key: "member",
              //   code: ["r", "f"],
              // },
            },
          },
        ],
      },
      {
        path: "/features",
        components: {
          home: () => import("../views/Home/views/Feature/Index"),
        },
        meta: {
          breadCrumb: "featureList",
          permission: {
            key: "feature",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              feature: () =>
                import("../views/Home/views/Feature/views/List/Index"),
            },
            meta: {
              breadCrumb: "featureList",
              permission: {
                key: "feature",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              feature: () =>
                import("../views/Home/views/Feature/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addFeature",
              permission: {
                key: "feature",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              feature: () =>
                import("../views/Home/views/Feature/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editFeature",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              feature: () =>
                import("../views/Home/views/Feature/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "feature",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/categories",
        components: {
          home: () => import("../views/Home/views/Category/Index"),
        },
        meta: {
          breadCrumb: "categories",
          permission: {
            key: "category",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              category: () =>
                import("../views/Home/views/Category/views/List/Index"),
            },
            meta: {
              breadCrumb: "categories",
              permission: {
                key: "category",
                code: "v",
              },
            },
          },
          {
            path: "children/:id",
            components: {
              category: () =>
                import("../views/Home/views/Category/views/List/Index"),
            },
            meta: {
              permission: {
                key: "category",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              category: () =>
                import("../views/Home/views/Category/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addCategory",
              permission: {
                key: "category",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              category: () =>
                import("../views/Home/views/Category/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editCategory",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              category: () =>
                import("../views/Home/views/Category/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "category",
                code: ["r", "f"],
              },
            },
            children: [
              {
                path: "children/:id",
                components: {
                  category: () =>
                    import("../views/Home/views/Category/views/List/Index"),
                },
                meta: {
                  permission: {
                    key: "category",
                    code: "v",
                  },
                },
              },
            ],
          },
        ],
      },
      {
        path: "/orders",
        components: {
          home: () => import("../views/Home/views/Order/Index"),
        },
        meta: {
          breadCrumb: "orders",
          permission: {
            key: "order",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              order: () => import("../views/Home/views/Order/views/List/Index"),
            },
            meta: {
              breadCrumb: "orders",
              permission: {
                key: "order",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              order: () =>
                import("../views/Home/views/Order/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addOrder",
              permission: {
                key: "order",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              order: () =>
                import("../views/Home/views/Order/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editOrder",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/failed-orders",
        components: {
          home: () => import("../views/Home/views/FailedOrder/Index"),
        },
        meta: {
          breadCrumb: "failedOrders",
          permission: {
            key: "order",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              failedOrder: () =>
                import("../views/Home/views/FailedOrder/views/List/Index"),
            },
            meta: {
              breadCrumb: "failedOrders",
              permission: {
                key: "order",
                code: "v",
              },
            },
          },
        ],
      },
      {
        path: "/members",
        components: {
          home: () => import("../views/Home/views/Member/Index"),
        },
        meta: {
          breadCrumb: "customers",
          permission: {
            key: "member",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              member: () =>
                import("../views/Home/views/Member/views/List/Index"),
            },
            meta: {
              breadCrumb: "customers",
              permission: {
                key: "member",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              member: () =>
                import("../views/Home/views/Member/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addCustomer",
              permission: {
                key: "member",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              member: () =>
                import("../views/Home/views/Member/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editCustomer",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              member: () =>
                import("../views/Home/views/Member/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "member",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/member-types",
        components: {
          home: () => import("../views/Home/views/MemberType/Index"),
        },
        meta: {
          breadCrumb: "memberTypes",
          permission: {
            key: "memberType",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              membertype: () =>
                import("../views/Home/views/MemberType/views/List/Index"),
            },
            meta: {
              breadCrumb: "memberTypes",
              permission: {
                key: "memberType",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              membertype: () =>
                import("../views/Home/views/MemberType/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addMemberType",
              permission: {
                key: "memberType",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              membertype: () =>
                import("../views/Home/views/MemberType/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editMemberType",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/member-groups",
        components: {
          home: () => import("../views/Home/views/MemberGroup/Index"),
        },
        meta: {
          breadCrumb: "memberGroups",
          permission: {
            key: "memberGroup",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              membergroup: () =>
                import("../views/Home/views/MemberGroup/views/List/Index"),
            },
            meta: {
              breadCrumb: "memberGroups",
              permission: {
                key: "memberGroup",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              membergroup: () =>
                import("../views/Home/views/MemberGroup/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addMemberGroup",
              permission: {
                key: "memberGroup",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              membergroup: () =>
                import("../views/Home/views/MemberGroup/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editMemberGroup",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/campaigns",
        components: {
          home: () => import("../views/Home/views/Campaign/Index"),
        },
        meta: {
          breadCrumb: "campaigns",
          permission: {
            key: "campaign",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              campaign: () =>
                import("../views/Home/views/Campaign/views/List/Index"),
            },
            meta: {
              breadCrumb: "campaigns",
              permission: {
                key: "campaign",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              campaign: () =>
                import("../views/Home/views/Campaign/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addCampaign",
              permission: {
                key: "campaign",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              campaign: () =>
                import("../views/Home/views/Campaign/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editCampaign",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              campaign: () =>
                import("../views/Home/views/Campaign/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "campaign",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/contracts",
        components: {
          home: () => import("../views/Home/views/Contract/Index"),
        },
        meta: {
          breadCrumb: "contractPages",
          permission: {
            key: "contract",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              contract: () =>
                import("../views/Home/views/Contract/views/List/Index"),
            },
            meta: {
              breadCrumb: "contractPages",
              permission: {
                key: "contract",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              contract: () =>
                import("../views/Home/views/Contract/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addContractPage",
              permission: {
                key: "contract",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              contract: () =>
                import("../views/Home/views/Contract/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editContractPage",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              contract: () =>
                import("../views/Home/views/Contract/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "contract",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/pages",
        components: {
          home: () => import("../views/Home/views/Page/Index"),
        },
        meta: {
          breadCrumb: "pages",
          permission: {
            key: "page",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              page: () => import("../views/Home/views/Page/views/List/Index"),
            },
            meta: {
              breadCrumb: "pages",
              permission: {
                key: "page",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              page: () => import("../views/Home/views/Page/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addPage",
              permission: {
                key: "page",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              page: () => import("../views/Home/views/Page/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editPage",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              page: () => import("../views/Home/views/Page/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "page",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/blogs",
        components: {
          home: () => import("../views/Home/views/Blog/Index"),
        },
        meta: {
          breadCrumb: "blogList",
          permission: {
            key: "blog",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              blog: () => import("../views/Home/views/Blog/views/List/Index"),
            },
            meta: {
              breadCrumb: "blogList",
              permission: {
                key: "blog",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              blog: () => import("../views/Home/views/Blog/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addBlog",
              permission: {
                key: "blog",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              blog: () => import("../views/Home/views/Blog/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editBlog",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              blog: () => import("../views/Home/views/Blog/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "blog",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/marketplaces",
        components: {
          home: () => import("../views/Home/views/Marketplace/Index"),
        },
        meta: {
          breadCrumb: "Pazaryeri Listesi",
        },
        children: [
          {
            path: "",
            components: {
              marketplace: () =>
                import("../views/Home/views/Marketplace/views/List/Index"),
            },
            meta: {
              breadCrumb: "marketplaceList",
            },
          },
          {
            path: "detail/:id",
            components: {
              marketplace: () =>
                import("../views/Home/views/Marketplace/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editMarketplace",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/seo-update",
        components: {
          home: () => import("../views/Home/views/SeoUpdate/Index"),
        },
        meta: {
          breadCrumb: "SEO",
        },
        children: [
          {
            path: "",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Brand/Index"),
            },
            meta: {
              breadCrumb: "bulkBrandSeoUpdate",
            },
          },
          {
            path: "brand",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Brand/Index"),
            },
            meta: {
              breadCrumb: "bulkBrandSeoUpdate",
            },
          },
          {
            path: "equipment",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Equipment/Index"),
            },
            meta: {
              breadCrumb: "bulkEquipSeoUpdate",
            },
          },
          {
            path: "category",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Category/Index"),
            },
            meta: {
              breadCrumb: "bulkCategorySeoUpdate",
            },
          },
          {
            path: "product",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Product/Index"),
            },
            meta: {
              breadCrumb: "bulkProductSeoUpdate",
            },
          },
          {
            path: "page",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Page/Index"),
            },
            meta: {
              breadCrumb: "bulkPageSeoUpdate",
            },
          },
          {
            path: "blog",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Blog/Index"),
            },
            meta: {
              breadCrumb: "bulkBlogSeoUpdate",
            },
          },
          {
            path: "home",
            components: {
              seoUpdate: () =>
                import("../views/Home/views/SeoUpdate/views/Home/Index"),
            },
            meta: {
              breadCrumb: "HomeSeoUpdate",
            },
          },
        ],
      },
      {
        path: "/settings",
        components: {
          home: () => import("../views/Home/views/Setting/Index"),
        },
        meta: {
          breadCrumb: "settings",
          permission: {
            key: "setting",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              setting: () =>
                import("../views/Home/views/Setting/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "settings",
              permission: {
                key: "setting",
                code: "v",
              },
            },
          },
        ],
      },
      {
        path: "/users",
        components: {
          home: () => import("../views/Home/views/User/Index"),
        },
        meta: {
          breadCrumb: "users",
          permission: {
            key: "user",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              user: () => import("../views/Home/views/User/views/List/Index"),
            },
            meta: {
              breadCrumb: "users",
              permission: {
                key: "user",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              user: () => import("../views/Home/views/User/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addUser",
              permission: {
                key: "user",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              user: () => import("../views/Home/views/User/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editUser",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/roles",
        components: {
          home: () => import("../views/Home/views/Role/Index"),
        },
        meta: {
          breadCrumb: "roles",
          permission: {
            key: "roleGroup",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              role: () => import("../views/Home/views/Role/views/List/Index"),
            },
            meta: {
              breadCrumb: "roles",
              permission: {
                key: "roleGroup",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              role: () => import("../views/Home/views/Role/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addRole",
              permission: {
                key: "roleGroup",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              role: () => import("../views/Home/views/Role/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editRole",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/user-activity",
        components: {
          home: () => import("../views/Home/views/UserActivity/Index"),
        },
        meta: {
          breadCrumb: "userActivity",
        },
        children: [
          {
            path: "",
            components: {
              activitylog: () =>
                import("../views/Home/views/UserActivity/views/List/Index"),
            },
            meta: {
              breadCrumb: "userActivity",
            },
          },
        ],
      },
      {
        path: "/sliders",
        components: {
          home: () => import("../views/Home/views/Slider/Index"),
        },
        meta: {
          breadCrumb: "sliderList",
          permission: {
            key: "slider",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              slider: () =>
                import("../views/Home/views/Slider/views/List/Index"),
            },
            meta: {
              breadCrumb: "sliderList",
              permission: {
                key: "slider",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              slider: () =>
                import("../views/Home/views/Slider/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addSlider",
              permission: {
                key: "slider",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              slider: () =>
                import("../views/Home/views/Slider/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editSlider",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              slider: () =>
                import("../views/Home/views/Slider/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "slider",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/coupons",
        components: {
          home: () => import("../views/Home/views/Coupon/Index"),
        },
        meta: {
          breadCrumb: "couponList",
        },
        children: [
          {
            path: "",
            components: {
              coupon: () =>
                import("../views/Home/views/Coupon/views/List/Index"),
            },
            meta: {
              breadCrumb: "addCoupon",
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              coupon: () =>
                import("../views/Home/views/Coupon/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addCoupon",
              permission: {
                key: "coupon",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              coupon: () =>
                import("../views/Home/views/Coupon/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editCoupon",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              coupon: () =>
                import("../views/Home/views/Coupon/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "coupon",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/banners",
        components: {
          home: () => import("../views/Home/views/Banner/Index"),
        },
        meta: {
          breadCrumb: "bannerList",
          permission: {
            key: "banner",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              banner: () =>
                import("../views/Home/views/Banner/views/List/Index"),
            },
            meta: {
              breadCrumb: "bannerList",
              permission: {
                key: "banner",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              banner: () =>
                import("../views/Home/views/Banner/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addBanner",
              permission: {
                key: "banner",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              banner: () =>
                import("../views/Home/views/Banner/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editBanner",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              banner: () =>
                import("../views/Home/views/Banner/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "banner",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/shipping-companies",
        components: {
          home: () => import("../views/Home/views/Cargo/Index"),
        },
        meta: {
          breadCrumb: "cargoCompanies",
        },
        children: [
          {
            path: "",
            components: {
              cargo: () => import("../views/Home/views/Cargo/views/List/Index"),
            },
            meta: {
              breadCrumb: "addCargoCompany",
              permission: {
                key: "cargo",
                code: "c",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              cargo: () =>
                import("../views/Home/views/Cargo/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addCargoCompany",
              permission: {
                key: "cargo",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              cargo: () =>
                import("../views/Home/views/Cargo/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editCargoCompany",
              edit: true,
              permission: {
                key: "cargo",
                code: "v",
              },
            },
          },
          {
            path: "trash",
            components: {
              cargo: () =>
                import("../views/Home/views/Cargo/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "cargo",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/payments",
        components: {
          home: () => import("../views/Home/views/Payment/Index"),
        },
        meta: {
          breadCrumb: "collectionSetings",
        },
        children: [
          {
            path: "",
            components: {
              payment: () =>
                import("../views/Home/views/Payment/views/List/Index"),
            },
            meta: {
              breadCrumb: "collectionSetings",
            },
          },
          {
            path: "pos/:id",
            components: {
              payment: () =>
                import("../views/Home/views/Payment/views/Detail/Pos/Index"),
            },
            meta: {
              breadCrumb: "editVirtualPos",
              edit: true,
            },
          },
          {
            path: "integration-companies/:id",
            components: {
              payment: () =>
                import(
                  "../views/Home/views/Payment/views/Detail/Collection/Index"
                ),
            },
            meta: {
              breadCrumb: "editIntegrationCompany",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/erp",
        components: {
          home: () => import("../views/Home/views/Erp/Index"),
        },
        meta: {
          breadCrumb: "erpIntegrations",
        },
        children: [
          {
            path: "",
            components: {
              erp: () => import("../views/Home/views/Erp/views/List/Index"),
            },
            meta: {
              breadCrumb: "erpIntegrations",
            },
          },
        ],
      },
      {
        path: "/e-invoice-integrations",
        components: {
          home: () => import("../views/Home/views/InvoiceIntegration/Index"),
        },
        meta: {
          breadCrumb: "invoiceIntegration",
        },
        children: [
          {
            path: "",
            components: {
              invoiceIntegration: () =>
                import(
                  "../views/Home/views/InvoiceIntegration/views/List/Index"
                ),
            },
            meta: {
              breadCrumb: "invoiceIntegration",
            },
          },
        ],
      },
      {
        path: "/comments",
        components: {
          home: () => import("../views/Home/views/Comment/Index"),
        },
        meta: {
          breadCrumb: "customerComments",
        },
        children: [
          {
            path: "",
            components: {
              comment: () =>
                import("../views/Home/views/Comment/views/List/Index"),
            },
            meta: {
              breadCrumb: "customerComments",
            },
          },
          {
            path: "trash",
            components: {
              comment: () =>
                import("../views/Home/views/Comment/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
            },
          },
        ],
      },
      {
        path: "/error-pages",
        components: {
          home: () => import("../views/Home/views/ErrorPages/Index"),
        },
        meta: {
          breadCrumb: "linkManagement",
        },
        children: [
          {
            path: "",
            components: {
              errorPages: () => import("../views/Home/views/ErrorPages/Index"),
            },
            meta: {
              breadCrumb: "linkManagement",
            },
          },
        ],
      },
      {
        path: "/daily-order-reports",
        components: {
          home: () => import("../views/Home/views/DailyOrderReports/Index"),
        },
        meta: {
          breadCrumb: "dailyOrderReports",
        },
        children: [
          {
            path: "",
            components: {
              dailyOrderReports: () =>
                import(
                  "../views/Home/views/DailyOrderReports/views/Order/Index"
                ),
            },
            meta: {
              breadCrumb: "dailyOrderReports",
            },
          },
        ],
      },
      {
        path: "/order-reports",
        components: {
          home: () => import("../views/Home/views/OrderReports/Index"),
        },
        meta: {
          breadCrumb: "orderReports",
        },
        children: [
          {
            path: "",
            components: {
              orderReports: () =>
                import("../views/Home/views/OrderReports/views/List/Index"),
            },
            meta: {
              breadCrumb: "orderReports",
            },
          },
        ],
      },
      {
        path: "/product-reports",
        components: {
          home: () => import("../views/Home/views/ProductReports/Index"),
        },
        meta: {
          breadCrumb: "productReports",
        },
        children: [
          {
            path: "",
            components: {
              productReports: () =>
                import("../views/Home/views/ProductReports/views/Sale/Index"),
            },
            meta: {
              breadCrumb: "productSaleReports",
            },
          },
          {
            path: "sale",
            components: {
              productReports: () =>
                import("../views/Home/views/ProductReports/views/Sale/Index"),
            },
            meta: {
              breadCrumb: "saleReports",
            },
          },
          {
            path: "returns-cancels",
            components: {
              productReports: () =>
                import(
                  "../views/Home/views/ProductReports/views/Returns/Index"
                ),
            },
            meta: {
              breadCrumb: "cancelReturnReports",
            },
          },
          {
            path: "stock-notifications",
            components: {
              productReports: () =>
                import(
                  "../views/Home/views/ProductReports/views/StockNotification/Index"
                ),
            },
            meta: {
              breadCrumb: "stockNotificationReports",
            },
          },
        ],
      },
      {
        path: "/campaign-reports",
        components: {
          home: () => import("../views/Home/views/CampaignReports/Index"),
        },
        meta: {
          breadCrumb: "campaignReports",
        },
        children: [
          {
            path: "",
            components: {
              campaignReports: () =>
                import(
                  "../views/Home/views/CampaignReports/views/Campaign/Index"
                ),
            },
            meta: {
              breadCrumb: "campaignReports",
            },
          },
          {
            path: "discount-coupons",
            components: {
              campaignReports: () =>
                import(
                  "../views/Home/views/CampaignReports/views/DiscountCoupon/Index"
                ),
            },
            meta: {
              breadCrumb: "discountCouponReports",
            },
          },
        ],
      },
      {
        path: "/member-reports",
        components: {
          home: () => import("../views/Home/views/MemberReports/Index"),
        },
        meta: {
          breadCrumb: "memberReports",
        },
        children: [
          {
            path: "",
            components: {
              memberReports: () =>
                import("../views/Home/views/MemberReports/views/Member/Index"),
            },
            meta: {
              breadCrumb: "memberReports",
            },
          },
          {
            path: "sale",
            components: {
              memberReports: () =>
                import("../views/Home/views/MemberReports/views/Sale/Index"),
            },
            meta: {
              breadCrumb: "memberSaleReports",
            },
          },
          {
            path: "returns-cancels",
            components: {
              memberReports: () =>
                import("../views/Home/views/MemberReports/views/Returns/Index"),
            },
            meta: {
              breadCrumb: "memberCancelReturnReports",
            },
          },
        ],
      },
      {
        path: "/top-titles",
        components: {
          home: () => import("../views/Home/views/TopTitle/Index"),
        },
        meta: {
          breadCrumb: "siteTopTitles",
        },
        children: [
          {
            path: "",
            components: {
              toptitle: () =>
                import("../views/Home/views/TopTitle/views/List/Index"),
            },
            meta: {
              breadCrumb: "siteTopTitles",
            },
          },
        ],
      },
      {
        path: "/pop-ups",
        components: {
          home: () => import("../views/Home/views/PopUp/Index"),
        },
        meta: {
          breadCrumb: "popUpList",
          permission: {
            key: "popup",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              popup: () => import("../views/Home/views/PopUp/views/List/Index"),
            },
            meta: {
              breadCrumb: "popUpList",
              permission: {
                key: "popup",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              popup: () =>
                import("../views/Home/views/PopUp/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addPopUp",
              permission: {
                key: "popup",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              popup: () =>
                import("../views/Home/views/PopUp/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editPopUp",
              edit: true,
            },
          },
        ],
      },
      {
        path: "/cargo-insurances",
        components: {
          home: () => import("../views/Home/views/CargoInsurance/Index"),
        },
        meta: {
          breadCrumb: "cargoInsuranceCompanies",
        },
        children: [
          {
            path: "",
            components: {
              cargoInsurance: () =>
                import("../views/Home/views/CargoInsurance/views/List/Index"),
            },
            meta: {
              breadCrumb: "cargoInsuranceCompanies",
            },
          },
        ],
      },
      {
        path: "/delivery-zones",
        components: {
          home: () => import("../views/Home/views/DeliveryZone/Index"),
        },
        meta: {
          breadCrumb: "deliveryZones",
        },
        children: [
          {
            path: "",
            components: {
              deliveryZone: () =>
                import("../views/Home/views/DeliveryZone/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "deliveryZones",
            },
          },
        ],
      },
      {
        path: "/service-regions",
        components: {
          home: () => import("../views/Home/views/ServiceRegion/Index"),
        },
        meta: {
          breadCrumb: "serviceRegions",
        },
        children: [
          {
            path: "",
            components: {
              serviceRegion: () =>
                import("../views/Home/views/ServiceRegion/views/List/Index"),
            },
            meta: {
              breadCrumb: "serviceRegions",
            },
          },
        ],
      },
      {
        path: "/price-analysis",
        components: {
          home: () => import("../views/Home/views/PriceAnalysis/Index"),
        },
        meta: {
          breadCrumb: "competitorPriceAnalysis",
        },
        children: [
          {
            path: "",
            components: {
              priceAnalysis: () =>
                import("../views/Home/views/PriceAnalysis/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "competitorPriceAnalysis",
            },
          },
        ],
      },
      {
        path: "/shops",
        components: {
          home: () => import("../views/Home/views/Shop/Index"),
        },
        meta: {
          breadCrumb: "shopList",
          permission: {
            key: "shop",
            code: "v",
          },
        },
        children: [
          {
            path: "",
            components: {
              shop: () => import("../views/Home/views/Shop/views/List/Index"),
            },
            meta: {
              breadCrumb: "shopList",
              permission: {
                key: "shop",
                code: "v",
              },
            },
          },
          {
            path: "detail",
            alias: "new",
            components: {
              shop: () => import("../views/Home/views/Shop/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "addShop",
              permission: {
                key: "shop",
                code: "c",
              },
            },
          },
          {
            path: "detail/:id",
            components: {
              shop: () => import("../views/Home/views/Shop/views/Detail/Index"),
            },
            meta: {
              breadCrumb: "editShop",
              edit: true,
            },
          },
          {
            path: "trash",
            components: {
              shop: () => import("../views/Home/views/Shop/views/Trash/Index"),
            },
            meta: {
              breadCrumb: "trash",
              permission: {
                key: "shop",
                code: ["r", "f"],
              },
            },
          },
        ],
      },
      {
        path: "/translation-settings",
        components: {
          home: () => import("../views/Home/views/TranslationSetting/Index"),
        },
        meta: {
          breadCrumb: "translationSettings",
        },
        children: [
          {
            path: "",
            meta: {
              breadCrumb: "translationSettings",
            },
            components: {
              translationSetting: () =>
                import(
                  "../views/Home/views/TranslationSetting/views/Detail/Index"
                ),
            },
          },
        ],
      },
      {
        path: "/documents",
        components: {
          home: () => import("../views/Home/views/Documents/Index"),
        },
      },
      {
        path: "/themes",
        components: {
          home: () => import("../views/Home/views/Themes/Index"),
        },
        meta: {
          breadCrumb: "Temalar",
        },
        children: [
          {
            path: "",
            meta: {
              breadCrumb: "Temalar",
            },
            components: {
              themes: () =>
                import("../views/Home/views/Themes/views/Detail/Index"),
            },
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: constant.BASE_URL,
  routes: routes,
});

const defaultTitle = `${constant.APP_TITLE} | v${constant.APP_VERSION} (${constant.APP_DATE})`;
router.beforeEach((to, from, next) => {
  document.title =
    to.meta && to.meta.breadCrumb ? i18n.t(to.meta.breadCrumb) : defaultTitle;
  const perm = to.meta ? to.meta.permission : null;

  if (to.path == "/health-check") {
    next();
  } else {
    if (localStorage.getItem("session")) {
      if (to.path == "/login") {
        router.replace("/").catch(() => {});
      } else {
        if (perm) {
          const check = permission.check(perm.key, perm.code);
          if (check) {
            next();
          } else {
            router.replace("/access-denied").catch(() => {});
          }
        } else {
          next();
        }
      }
    } else {
      if (to.path != "/login") {
        router.replace("/login").catch(() => {});
      } else {
        next();
      }
    }
  }
});

export default router;
