import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    changed: false,
    country: null,
    city: null,
    districts: [],
    neighborhoods: [],
    delivery_times: [],
    list: [],
  }),
  mutations: {
    clearDetail(state) {
      state.country = null;
      state.city = null;
      state.districts = [];
      state.neighborhoods = [];
      state.delivery_times = [];
    },
  },
  actions: {
    create({ state }, data) {
      const requestData = {};
      requestData.country_id = state.country.id;
      requestData.city_id = state.city.id;
      requestData.delivery_times = state.delivery_times;

      if (state.neighborhoods.length && state.districts.length == 1) {
        requestData.districts = state.districts;
        requestData.neighborhoods = state.neighborhoods;
      } else if (state.districts.length) {
        requestData.districts = state.districts;
      }

      api.post(
        "delivery-zones",
        requestData,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/delivery-zones";
      
      if(data.filter) {
        let queryString = data.filter;
        routePath += "?" + helper.makeQueryString(queryString);
      }

      api.get(
        routePath,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      let nodeData = data.form;
      let routePath = "delivery-zones/isActive";
        if(nodeData.parents && nodeData.parents.length) {
          nodeData.parents.forEach(element => {
            routePath += "/" + element.id;
          });
        }
        routePath += "/" + nodeData.node.id;

        api.post(
          routePath,
          {
            is_active: nodeData.value,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
    },
    getChildren({ commit }, data) {
      if(data && data.node) {
        let routePath = "all/delivery-zones";
        if(data.parents && data.parents.length) {
          data.parents.forEach(element => {
            routePath += "/" + element.id;
          });
        }
        routePath += "/" + data.node.id;

        api.get(
          routePath,
          (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }  
        );
      }
    },
    getDeliveryTimes({}, data) {
      let routePath = "region-delivery-times";
      if(data.parents && data.parents.length) {
        data.parents.forEach(element => {
          routePath += "/" + element.id;
        });
      }
      routePath += "/" + data.node.id;
      
      api.get(
        routePath,
        (result) => {
        if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }  
      );
    },
    saveDeliveryTimes({}, data) {      
      api.post(
        "delivery-times",
        {
          delivery_zone_district_id: data.node.id,
          delivery_times: data.node.delivery_times
        },
        (result) => {
        if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }  
      );
    }
  },
};
