import imageRequest from "../../models/request/image";

export default {
  new(data) {
    const result = {};
    result.media = imageRequest(data.contents[0].Media,false);
    result.title = data.title.trim();
    result.contents = helper.arrayPluck(data.contents, ["id", "type", "value"]);
    
    return result;
  },
};
