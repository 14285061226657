import api from "../../shared/api";
import requestPackage from "../../models/request/package";
import imageResponse from "../../models/response/image";

import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    // Package -> Detail
    brands: [],

    // Package -> Information
    currencies: [],

    // Package -> Detail
    cargoFirms: [],

    // Package -> Information // Dummy
    stockTypeOptions: [],

    // Package -> Detail
    models: [],

    // Global Currency Symbol
    currencySymbol: "₺",

    // Package -> Information
    info: {
      categories: [],
      selected: {
        stockType: -1,
        currency: -1,
      },

      name: null,
      slug: null,
      normalPrice: helper.setPrice(0),
      discountedPrice: helper.setPrice(0),
      discountRate: 0,

      taxAmount: 0,
      stock: 0,
      stockCode: null,

      image: {
        files: [],
        filesData: null,
      },
      products: [],
      is_active: true,
    },

    // Package -> Detail
    detail: {
      selected: {
        brand: -1,
        model: -1,
      },

      packageBarcode: null,
      parcelCode: null,
      purchasePrice: helper.setPrice(0),
      warranty: null,
      descriptions: [],

      meta: {
        title: null,
        keywords: null,
        description: null,
        robots: ["index", "follow"],
        canonicalUrl: null,
      },

      features: [],
      marketplaces: [],

      recommendedProducts: [],
      compatibleProducts: [],

      cargoPackage: {
        width: null,
        height: null,
        depth: null,
        weight: null,
        desi: null,
        price: helper.setPrice(0),
      },
    },

    list: [],
  }),
  mutations: {
    setInformation(state, data) {
      if (data) {
        const info = state.info;
        info.id = data.id;
        info.name = data.name;
        info.slug = data.slug;
        info.selected.currency = data.currency_code_id;
        info.selected.stockType = data.quantity_type_id;
        info.normalPrice = helper.setPrice(data.price);
        info.discountedPrice = helper.setPrice(data.discount_price);
        info.discountRate = parseFloat(data.package_discount_rate) || 0;
        info.stock = data.quantity;
        info.stockCode = data.sku_no;
        info.is_active = data.is_active === 1;
        info.is_new = data.is_new_product === 1;
        info.is_featured = data.is_featured_product === 1;

        info.products = [];
        data.packageProducts.forEach((product) => {
          product.cross_name = product.cross_code + " - " + product.cross_name;
          info.products.push(product);
        });

        helper.copy(data.categories, info.categories);
        helper.copy(imageResponse.clone(data.media), info.image.files);

        const detail = state.detail;
        detail.selected.brand = data.brand_id ? data.brand_id : -1;
        detail.selected.model = data.model_id ? data.model_id : -1;
        detail.mpn = data.mpn_no;
        detail.gtin = data.gtin;
        detail.packageBarcode = data.package_barcode;
        detail.parcelCode = data.parcel_barcode;
        detail.taxAmount = data.tax;
        detail.purchasePrice = helper.setPrice(data.purchase_price);
        detail.warranty = data.warranty_period;
        detail.descriptions = data.descriptions;
        detail.meta.title = data.meta_title;
        detail.meta.keywords = data.meta_keywords;
        detail.meta.description = data.meta_description;
        detail.meta.robots = ["index", "follow"];
        detail.meta.canonicalUrl = data.canonical_url;
        detail.marketplaces = data.marketplaces;
        // data.meta_robots
        //   ? data.meta_robots.split(", ")
        //   : [];
        detail.cargoPackage.width = data.cargo_width;
        detail.cargoPackage.height = data.cargo_height;
        detail.cargoPackage.depth = data.cargo_depth;
        detail.cargoPackage.weight = data.cargo_weight;
        detail.cargoPackage.desi = data.cargo_desi;
        detail.cargoPackage.price = helper.setPrice(data.cargo_price);
        detail.recommendedProducts = data.recommendedProducts;
        detail.compatibleProducts = data.compatibleProducts;
        detail.cargoCompanies = data.cargoCompanies;
        detail.features = data.features;
      }
    },
    clearInformation(state, config) {
      state.info = {
        categories: [],

        selected: {
          stockType: config["site.stock_type"] || -1,
          currency: config["site.currency_code"] || -1,
        },

        name: null,
        normalPrice: helper.setPrice(0),
        discountedPrice: helper.setPrice(0),
        discountRate: 0,
        taxAmount: config["site.vat"] || 0,
        stock: 0,
        stockCode: null,

        image: {
          files: [],
          filesData: null,
        },
        products: [],
        is_active: true,
        is_new: false,
        is_featured: false,
      };
      state.detail = {
        selected: {
          brand: -1,
          model: -1,
        },

        packageBarcode: null,
        parcelCode: null,
        purchasePrice: helper.setPrice(0),
        warranty: null,
        descriptions: [],

        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },

        features: [],
        marketplaces: [],
        recommendedProducts: [],
        compatibleProducts: [],

        cargoPackage: {
          width: null,
          height: null,
          depth: null,
          weight: null,
          desi: null,
          price: helper.setPrice(0),
        },
      };
    },

    changeCurrencySymbol(state) {
      let selected = state.info.selected.currency;
      let currencies = state.currencies;

      let index = helper.arrayFind(currencies, "id", selected);
      if (index !== -1) {
        state.currencySymbol = currencies[index].symbol;
      }
    },

    updateList(state, result) {
      let items = result.data ? result.data.items : [];

      if (items && items.length) {
        items.map((item) => {
          item.category = item.category ? item.category.name : null;
          item.quantity = item.quantity + " " + item.quantity_type;

          return item;
        });
      }

      state.list = items;
    },
  },
  actions: {
    load({ commit, rootState }) {
      this.dispatch("packages/getBrandList");
      this.dispatch("packages/getCurrencyList");
      this.dispatch("packages/getQuantityTypes");
      commit("clearInformation", rootState.session.config);
    },
    create({ state }, data) {
      api.post(
        "packages",
        requestPackage.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        "packages/" + data.id,
        requestPackage.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "packages/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "packages/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "packages/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/packages";
      let queryString = { ...data.table, ...data.filter };

      routePath += "?" + helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      if (data.id) {
        api.get(
          "packages/" + data.id,
          (result) => {
            commit("setInformation", result.data.item);
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            setTimeout(() => {
              commit("changeCurrencySymbol");
            }, 1000);
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getCurrencyList({ state, commit }, data) {
      api.get(
        "all/currency-codes",
        (result) => {
          state.currencies = result.data.items;
          commit("changeCurrencySymbol");
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getQuantityTypes({ state }, data) {
      api.get(
        "all/quantity-types",
        (result) => {
          state.stockTypeOptions = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getBrandList({ state }, data) {
      api.get(
        "brands",
        (result) => {
          state.detail.selected.model = -1;
          state.brands = result.data.items;
          if (state.detail.selected.model != -1) {
            this.dispatch("packages/getModelList");
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getModelList({ state }, data) {
      api.get(
        "all/models/" + state.detail.selected.brand,
        (result) => {
          state.models = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({ commit, state }, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "packages/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
