export default {
  items: [
    {
      name: "digital-planet",
      logo:
        "https://www.ideasoft.com.tr/wp-content/uploads/2021/05/digital-planet-1-1024x641.png",
      title: "Digital Planet",
      environments: [
        {
          name: "demo",
          elements: [
            {
              name: "company",
              label: "Firma Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "username",
              label: "Kullanıcı Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "password",
              label: "Şifre",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "earchive",
              label: "E-Arşiv",
              form: "boolean",
              value: null,
              required: true,
            },
            {
              name: "einvoice",
              label: "E-Fatura",
              form: "boolean",
              value: null,
              required: true,
            },
          ],
        },
        {
          name: "canli",
          elements: [
            {
              name: "company",
              label: "Firma Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "username",
              label: "Kullanıcı Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "password",
              label: "Şifre",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "earchive",
              label: "E-Arşiv",
              form: "boolean",
              value: null,
              required: true,
            },
            {
              name: "einvoice",
              label: "E-Fatura",
              form: "boolean",
              value: null,
              required: true,
            },
          ],
        },
      ],
    },
    {
      name: "smart-bill",
      logo:
        "https://cloud.smartbill.ro/media/images/logos/sb_fg_logo_fullcolor.svg",
      title: "Smart Bill",
      environments: [
        {
          name: "demo",
          elements: [
            {
              name: "company",
              label: "Firma Adı(vat Code)",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "username",
              label: "Kullanıcı Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "password",
              label: "Şifre",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "seriesName",
              label: "Series Name",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "taxName",
              label: "Tax Name",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
          ],
        },
        {
          name: "canli",
          elements: [
            {
              name: "company",
              label: "Firma Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "username",
              label: "Kullanıcı Adı",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "password",
              label: "Şifre",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "seriesName",
              label: "Series Name",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
            {
              name: "taxName",
              label: "Tax Name",
              form: "input",
              type: "text",
              value: null,
              required: true,
            },
          ],
        },
      ],
    },
  ],
};
