import api from "../../shared/api";
import requestEmailList from "../../models/request/emailList";
import responseEmailList from "../../models/response/emailList";
import helper from "../../shared/helper";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      const items = result?.data?.items || [];

      state.list = items.map((list) => {
        delete list.members;
        return list;
      });
    },
  },
  actions: {
    create({ state }, data) {
      api.post(
        `email-lists`,
        requestEmailList.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        `email-lists/${data.id}`,
        requestEmailList.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "email-lists/" + data.id,
        (result) => {
          if (result && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "email-lists/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "email-lists/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      let routePath = "all/email-lists";
      let queryString = { ...data?.table };

      routePath += "?" + helper.makeQueryString(queryString);

      if (data && data.deleted) {
        routePath += "&deleted_at=1";
      }

      api.get(
        routePath,
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ state }, data) {
      api.get(
        `email-lists/${data.id}`,
        (result) => {
          let resultData = responseEmailList.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
