import api from "../../shared/api";
import requestCoupon from "../../models/request/coupon";
import responseCoupon from "../../models/response/coupon";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      let result = [];

      if (payload && payload.length) {
        result = payload.map((item) => {
          if (item.discount_amount) {
            let result = "";

            if (item.discount_type) {
              if (item.discount_type.name == "Yüzde") {
                result = "%" + item.discount_amount;
              } else {
                result = localization.getMoneyFormat(
                  item.currency_code ? item.currency_code.code : null,
                  item.discount_amount
                );
              }
            } else {
              result = item.discount_amount;
            }

            item.discount_amount = result;
          }
          return item;
        });
      }

      state.list = result;
    },
  },
  actions: {
    create({}, data) {
      api.post(
        "discount-coupons",
        requestCoupon.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({}, data) {
      api.put(
        "discount-coupons/" + data.id,
        requestCoupon.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({}, data) {
      api.delete(
        "discount-coupons/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "discount-coupons/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "discount-coupons/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      let routePath = "all/discount-coupons";
      let queryString = { ...data.filter, ...data.table };

      routePath += "?" + helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "discount-coupons/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            result = responseCoupon.new(result.data.item);
          }

          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "discount-coupons/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
