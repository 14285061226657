export default {
  new(data) {
    const result = {};

    result.title = data.title;
    result.contents = data.contents || [];

    return result;
  },
};
