import api from "../../shared/api";
import bank from "./bank";
import search from "./search";
import searchProduct from "./search.product";
import feature from "./feature.index";
import category from "./category.index";
import currencyCodes from "./currency.index";
import paymentTypes from "./payment.index";
import quantityTypes from "./quantity.index";
import promotionTypes from "./promotion.index";
import discountTypes from "./discount.index";
import role from "./role";
import slug from "./slug";
import limitTypes from "./limit-type";
import cargoCompanies from "./cargo";

export default {
  namespaced: true,
  modules: {
    bank,
    search,
    searchProduct,
    feature,
    category,
    currencyCodes,
    paymentTypes,
    quantityTypes,
    promotionTypes,
    discountTypes,
    role,
    slug,
    limitTypes,
    cargoCompanies,
  },
  state: () => ({
    // New Address => Add New City Arrays
    countries: [],
    cities: [],
    districts: [],
    neighborhoods: [],

    // New Address => Default Object
    newAddress: {
      contact_type_id: null,
      name: null,
      country: null,
      city: null,
      district: null,
      neighborhood: null,
      postal_code: null,
      contactable_id: null,
      contactable_type: null,
    },

    // Alert Message
    message: {
      title: null,
      html: "",
      icon: null,
    },
  }),
  mutations: {
    clearMessage(state) {
      state.message = {
        title: null,
        html: "",
        icon: null,
      };
    },
    setMessage(state, result) {
      const data = result.data;
      const message = data.message;
      const icon = data && data.status ? data.status : null;
      let errors = data && data.status == "error" ? data.errors : null;

      let text = "";
      if (errors) {
        const errorKeys = Object.keys(errors);

        text += `<div class="card alert-danger">`;
        text += `<div class="card-body" style="max-height: 350px; overflow: auto;">`;
        for (let i = 0; i < errorKeys.length; i++) {
          let element = errorKeys[i];
          const error = errors[element];
          const data = Array.isArray(error) ? error[0] : error;

          let marketplaceKey = "";
          if (element.includes("n11.")) {
            marketplaceKey = `<b>N11 : </b>`;
          } else if (element.includes("trendyol.")) {
            marketplaceKey = `<b>Trendyol : </b>`;
          } else if (element.includes("gittigidiyor.")) {
            marketplaceKey = `<b>GittiGidiyor : </b>`;
          } else if (element.includes("hepsiburada.")) {
            marketplaceKey = `<b>Hepsiburada : </b>`;
          } else if (element.includes("pazarama.")) {
            marketplaceKey = `<b>Pazarama : </b>`;
          } else if (element.includes("epttavm.")) {
            marketplaceKey = `<b>PttAVM : </b>`;
          }

          if (!data) continue;

          let spanClass = "d-block font-14";
          if (i !== errorKeys.length - 1) spanClass += " mb-2";

          text += `<span class="${spanClass}">
                    <i class="far fa-dot-circle mr-1"></i> ${marketplaceKey} ${data}
                  </span>`;
        }
        text += "</div>"; // ./Card Body
        text += "</div>"; // ./Card
      }

      if (message) {
        state.message = {
          title: message,
          html: text,
          icon: icon,
        };
      }
    },
    updateContactable(state, contactable) {
      let newAddress = state.newAddress;
      if (contactable) {
        newAddress.contactable_id = contactable.id;
        newAddress.contactable_type = contactable.type || 0;
      } else {
        newAddress.contactable_id = null;
        newAddress.contactable_type = null;
      }
    },
    clearAddress(state) {
      state.newAddress = {
        name: null,
        country: null,
        city: null,
        district: null,
        neighborhood: null,
        postal_code: null,
        contactable_id: null,
        contactable_type: null,
      };
    },
  },
  actions: {
    // New Address => getCountries
    getCountries({ state }, data) {
      state.countries = [];
      api.get(
        "region/countries",
        (result) => {
          helper.copy(result.data.items, state.countries);
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },

    // New Address => getCities
    getCities({ state }, data) {
      state.cities = [];
      const countryId = state.newAddress.country.id;
      if (countryId) {
        api.get(
          "region/cities/" + countryId,
          (result) => {
            helper.copy(result.data.items, state.cities);
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },

    // New Address => getDistricts
    getDistricts({ state }, data) {
      state.districts = [];
      const cityId = state.newAddress.city.id;
      if (cityId) {
        api.get(
          "region/districts/" + cityId,
          (result) => {
            helper.copy(result.data.items, state.districts);
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },

    // New Address => getNeighborhoods
    getNeighborhoods({ state }, data) {
      state.neighborhoods = [];
      const districtId = state.newAddress.district.id;
      if (districtId) {
        api.get(
          "region/neighborhoods/" + districtId,
          (result) => {
            helper.copy(result.data.items, state.neighborhoods);
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },

    createAddress({ state }, data) {
      const address = state.newAddress;
      const requestData = {
        contact_type_id: address.contact_type_id,
        contactable_id: address.contactable_id,
        contactable_type: address.contactable_type || 0,
        name: address.name,
        country_id: address.country.id,
        city_id: address.city.id,
        district_id: address.district.id,
        neighborhood_id: address.neighborhood.id,
        postal_code: address.country.id,
        address: address.address,
      };
      api.post(
        "contacts",
        requestData,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
