export default {
  new(state) {
    const info = state.info;
    this.id = info.id;
    this.manufacturer = info.manufacturer;
    this.sku_no = info.sku_no;

    this.packageProducts = info.products;
    this.engine_brand = info.engine.brand;
    this.engine_model = info.engine.model;
    this.engine_year = info.engine.year;

    const detail = state.detail;
    this.description = detail.description.text;
    this.meta_title = detail.meta.title;
    this.meta_description = detail.meta.description;
    this.meta_keywords = detail.meta.keywords;
    this.canonical_url = detail.meta.canonicalUrl;
    return this;
  },

  id: null,
  manufacturer: null,
  engine_brand: null,
  engine_model: null,
  engine_year: null,
  sku_no: null,

  description: null,
  meta_title: null,
  meta_description: null,
  meta_keywords: null,
  canonical_url: null,
};
