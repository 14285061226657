export default {
  new(item) {
    const result = {};
    if (item.image) result.image = item.image.files[0];

    if (item.title || item.title == "") result.title = item.title;
    if (item.description) result.description = item.description;
    if (item.link) result.link = item.link;
    if (item.hasOwnProperty("new_tab"))
      result.new_tab = item.new_tab === true ? 1 : 0;
    if (item.hasOwnProperty("is_active"))
      result.is_active = item.is_active === true ? 1 : 0;
    return result;
  },
};
