
export default {
    new(item) {
      this.name = item.name;
      this.type = item.type;
      this.address = item.address;
      this.username = item.username;
      this.stock_quantity = item.stock_quantity;
      this.password = item.password;
      this.template = item.template;
      this.currency = item.currency;
      this.is_save_category = item.is_save_category;
      this.is_stock_track = item.is_stock_track;
      this.categories = item.categories;
      this.username = item.username;
      this.is_active = item.is_active === 1;
      this.is_main = item.is_main === 1;
      this.logs = item.logs;
      return this;
    },
  };
  