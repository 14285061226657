import api from "../../shared/api";

export default {
  namespaced: true,
  state: () => ({
    list: []
  }),
  mutations: {
    updateList(state, data) {
      if(data) {
        state.list = data.map((item) => {
          item.global_id = item.id;
          return item;
        })
      } else {  
        state.list = [];
      }
    }
  },
  actions: {
    createOrUpdate({ state }, data) {
      api.post(
        "delivery-times",
        {
          delivery_times: state.list
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "delivery-times",
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  }
}