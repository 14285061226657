import api from "../../shared/api";

export default {
  state: () => ({
    routes: {
      customer: {
        route: "search/customers",
        key: "name",
      },
      banner: {
        route: "search/banners",
        key: "description",
      },
      brand: {
        route: "search/brands",
        key: "name",
      },
      campaign: {
        route: "search/campaigns",
        key: "name",
      },
      discountCoupon: {
        route: "search/discount-coupons",
        key: "name",
      },
      manufacturer: {
        route: "search/equipment-manu",
        key: "name",
      },
      category: {
        route: "search/categories",
        key: "name",
      },
      categoryProduct: {
        route: "search/category-products",
        key: "name",
        category_id: null,
      },
      product: {
        route: "search/products",
        key: "query",
      },
      district: {
        route: "search/districts",
        key: "query",
      },
    },
  }),
  actions: {
    search({ state }, data) {
      api.post(
        data.route,
        data.form,
        (result) => {
          state.list = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
