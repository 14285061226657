import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    types: [],
    templates: [],
  }),
  actions: {
    getProductTypes({ state }, data) {
      api.get(
        "all/product-types",
        (result) => {
          state.types = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getProductTemplates({ state }, data) {
      api.get(
        "all/product-templates",
        (result) => {
          state.templates = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
