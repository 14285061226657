import api from "../../shared/api";
import { saveAs } from "file-saver";

export default {
  namespaced: true,
  actions: {
    exportData({ rootState }, data) {
      let routePath = data.route;
      let count = data.count ?? 0;
      let queryString = helper.makeQueryString(data.filter);
      const siteTitle = rootState.session.config['site.title'];
      
      let fileName = '';
      if(siteTitle) {
        fileName += siteTitle + ' - ';
      }

      fileName += data.fileName;

      if (queryString && queryString.length) {
        routePath += "?" + queryString;
      }

      if (data && data.hasOwnProperty("fields") && data.fields.length) {
        data.fields.forEach((element) => {
          routePath += "&fields[]=" + element.key;
        });
      }

      const apiConfig = helper.exportSettings(data.exportable.name);
      if (apiConfig && !data.exportable.loading) {
        data.exportable.loading = true;
        if(routePath.includes('/product')) {
          api.get(
            routePath,
            (result) => {
              if (data && data.onSuccess) data.onSuccess(result.data);
            },
            (error) => {
              if (data && data.onError) data.onError(error);
            },
            () => {
              if (data && data.onFinish) data.onFinish();
            }
          );
        } else {
          api.download(
            routePath,
            apiConfig,
            (result) => {
              let blob = new Blob([result.data], {
                encoding: "UTF-8",
                type: apiConfig.responseType,
              });
  
              saveAs(blob, `${fileName}.${data.exportable.extension}`);
  
              if (data && data.onSuccess) data.onSuccess(result);
            },
            (error) => {
              if (data && data.onError) data.onError(error);
            },
            () => {
              data.exportable.loading = false;
              if (data && data.onFinish) data.onFinish();
            }
          );
        }
      
      }
    },
  },
};
