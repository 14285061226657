import api from "../../shared/api";

export default {
  namespaced: true,
  state: () => ({
    countries: [],
  }),
  actions: {
    getList({ state }, data) {
      let route = "service-regions";

      api.get(
        route,
        (result) => {
          state.countries = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      api.post(
        "service-regions/isActive/" + data.id,
        {
          is_active: data.is_active,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
