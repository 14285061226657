export default {
  new(item) {
    let result = {};
    if (item.title) result.title = item.title;
    if (item.description) result.description = item.description;
    if (item.hasOwnProperty("is_active"))
      result.is_active = item.is_active ? 1 : 0;
    result = { ...result };

    return result;
  },
};
