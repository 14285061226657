import api from "../../shared/api";
import requestExchange from "../../models/request/widget/exchange-rate";
import responseExchange from "../../models/response/widget/exchange-rate";

export default {
  state: () => {},
  mutations: {},
  actions: {
    updateExchangeRates({}, data) {
      const requestData =
        data && data.currencies ? requestExchange.new(data.currencies) : null;
      if (requestData) {
        let counter = 0;
        for (let i = 0; i < requestData.length; i++) {
          const element = requestData[i];
          const postData = {
            code: element.code,
            currency_buying: element.currency_buying,
            currency_selling: element.currency_selling,
          };

          api.post(
            "exchange-rates/" + element.id,
            postData,
            (result) => {
              if (data && data.onSuccess) data.onSuccess(result);
            },
            (error) => {
              if (data && data.onError) data.onError(error);
            },
            () => {
              counter++;
              callFinish();
            }
          );
        }
        function callFinish() {
          if (counter == requestData.length - 1) {
            data.onFinish();
          }
        }
      }
    },
    getExchangeRates({ commit }, data) {
      api.get(
        "all/exchange-rates",
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            result = responseExchange.clone(result.data.items);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
