import api from "../../shared/api";

export default {
  actions: {
    searchFeature({}, data) {
      let lang = "tr";
      if (data.hasOwnProperty("lang")) {
        lang = data.lang;
      }

      api.post(
        `search/features?lang=${lang}`,
        data,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    searchFeatureValue({}, data) {
      let lang = "tr";
      if (data.hasOwnProperty("lang")) {
        lang = data.lang;
      }

      api.post(
        `search/features?lang=${lang}`,
        {
          feature_id: data.feature.feature_id,
          query: data.feature.value,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getFeatureById({ state }, data) {
      if (data.hasOwnProperty("id")) {
        let route = `features/${data.id}?lang=${state.lang}`;
        api.get(
          route,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
