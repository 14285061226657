export default {
  new(data) {
    this.name = data.name; // Kargo Adı
    this.cargo_company_id =
      data.cargo_company_id === "-1" ? null : data.cargo_company_id; // Kargo Şirketi

    this.currency_code_id = data.currency_code?.id;
    
    // Kargo Sigorta Şirketi
    this.cargo_insurance_id = data.cargo_insurance
      ? data.cargo_insurance.id
      : null;
    // this.cargo_payment_id = data.cargo_payment_id; // Ödeme Tipi

    if (data.cash_delivery_setting) {
      const directPayment = data.direct_payment;
      this.min_cash_limit = directPayment.min; // Kapıda Ödeme Min
      this.max_cash_limit = directPayment.max; // Kapıda Ödeme Max
      this.cash_status = directPayment.cash ? 1 : 0; // Kapıda Nakit Ödeme
      this.cash_price = directPayment.cash_price; // Kapıda Nakit Tutar

      this.credit_card_status = directPayment.credit_card ? 1 : 0; // Kapıda Kredi Kartı Ödeme
      this.credit_card_price = directPayment.credit_card_price; // Kapıda Kredi Kartı Tutar
    }

    this.is_free = data.is_free == true ? 1 : 0;
    if (!data.is_free) {
      const dataRates = data.rates;
      this.fixed_price = dataRates.fixed_price.unmask || 0; // Sabit Ücret
      this.free_cargo = dataRates.min_free_shipping.unmask || 0; // Ücretsiz Kargo
      this.free_desi = dataRates.max_desi_limit || 0; // Ücretsiz Desi Limit

      const desiInfo =
        data.desi && data.desi.length
          ? data.desi.map((desi) => {
              delete desi.temp_id;
              delete desi.order_no;
              delete desi.selected;
              delete desi.loading;

              return desi;
            })
          : [];
      this.desi_info = desiInfo;

      if (data.over_desi_price) {
        this.over_desi = data.over_desi_price.unmask || 0;
      }
    }

    if (data.cargoConfig) {
      this.config = data.cargoConfig;
    }

    // Teslimat Bölgesi
    this.delivery_zones = [];
    if (data.delivery_zones && data.delivery_zones.length) {
      this.delivery_zones = helper.arrayPluck(data.delivery_zones, "id");
    }

    this.is_active = 1;

    return this;
  },
};
