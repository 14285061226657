/**
 * @param array files
 * @param boolean multiple
 */
export default function imageRequest(files, multiple = true) {
  const notAllowed = ["fileData", "isImage", "isVideo", "fileSize", "temp_id"];

  let result = multiple ? [] : {}; // Array | Object (Multiple | Single)

  if (files && files.length) {
    result = files.map((file) => {
      Object.keys(file).forEach((key) => {
        // Check if key is allowed
        if (notAllowed.includes(key)) {
          delete file[key];
        }
      });

      if(multiple && file.isMain === null) file.isMain = 0;

      return file;
    });

    if (!multiple) result = result[0];
  } else {
    result = null;
  }

  return result;
}
