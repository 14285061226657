export default {
  new(item) {
    if (item.accountNumber) this.current_code = item.accountNumber;
    if (item.firstName) this.first_name = item.firstName;
    if (item.lastName) this.last_name = item.lastName;
    if (item.gender) this.gender = item.gender;
    if (item.hasOwnProperty("birthdate")) this.birth = item.birthdate;
    if (item.hasOwnProperty("phoneNumber")) this.phone = item.phoneNumber;
    if (item.hasOwnProperty("gsm")) this.gsm = item.gsm;
    if (item.hasOwnProperty("identifier")) {
      this.tcn = !item.firm ? item.identifier : null;
    }

    if (item.firm && item.firm.hasOwnProperty("taxNumber")) {
      this.vkn = item.firm.taxNumber;
    }
    if (item.hasOwnProperty("notCitizen")) {
      this.is_citizen = item.notCitizen === false ? 1 : 0;
    }
    if (item.hasOwnProperty("is_open_account")) {
      this.is_open_account = item.is_open_account === true ? 1 : 0;
    }
    if (item.hasOwnProperty("is_active")) {
      this.is_active = item.is_active === true ? 1 : 0;
    }

    if (item.login) {
      const login = item.login;
      if (login.email) this.email = login.email;
      if (login.password) {
        this.password = login.password;
      }
      if (login.passwordConfirmation) {
        this.password_confirmation = login.passwordConfirmation;
      }
    }
    if (item.firm) {
      if (item.firm.hasOwnProperty("name")) this.firm_name = item.firm.name;
      if (item.firm.hasOwnProperty("taxOffice"))
        this.tax_office = item.firm.taxOffice;
    }

    if (
      item.hasOwnProperty("type") &&
      item.memberTypeList.hasOwnProperty(item.type)
    ) {
      let type_id = item.memberTypeList[item.type].id;
      this.member_type_id = type_id;
    }
    // else {
    //   this.member_type_id = 0;
    // }

    if (item.group) {
      this.member_group_id = item.group == "-1" ? null : item.group;
    }
    if (item.notification) {
      const notification = item.notification;
      if (notification.hasOwnProperty("email")) {
        this.notification_email = notification.email === true ? 1 : 0;
      }
      if (notification.hasOwnProperty("sms")) {
        this.notification_sms = notification.sms === true ? 1 : 0;
      }
    }
    if (item.addressList) {
      this.contacts = item.addressList.map((address) => {
        let data = {
          name: address.name,
          contact_type_id:
            address.contact_type && address.contact_type.id
              ? address.contact_type.id
              : item.contactTypes[1].id,
          country_id: address.country.id,
          city_id: address.city.id,
          district_id: address.district.id,
          neighborhood_id: address.neighborhood?.id || null,
          postal_code: address.postal_code,
          address: address.address,
          first_name: address.first_name,
          last_name: address.last_name,
          email: address.email,
          phone: address.phone,
        };

        if (address.id && typeof address.id == "number") {
          delete address.id;
        } else {
          Object.assign(data, { id: address.id });
        }

        return data;
      });
    }

    return this;
  },
};
