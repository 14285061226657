import erpJson from "../../models/json/erp";
export default {
  new(data) {
    let source = {};

    if (data) {
      const erpName = data.name;
      const config = data.config;

      let erp = erpJson.items.find((erp) => erp.name === erpName);

      if (erp) {
        source = helper.clone(erp);
        source.id = data.id;
        source.is_active = data.is_active ? 1 : 0;
        source.is_default = data.is_default ? 1 : 0;
        source.is_test = data.is_test ? 1 : 0;

        // source.logo = data.logo;

        if (config && Object.keys(config).length) {
          Object.entries(config).forEach(([configKey, configValue]) => {
            let sourceElement = source.elements.findIndex(
              (elem) => elem.name === configKey
            );
            if (sourceElement !== -1) {
              source.elements[sourceElement].value = configValue;
            }
          });
        }
      }
    }

    return source;
  },
};
