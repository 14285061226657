import api from "../../shared/api";

export default {
  state: () => ({
    list: [],
  }),
  actions: {
    getPaymentTypes({ state }, data) {
      state.list = [];
      api.get(
        "all/payment-types",
        (result) => {
          helper.copy(result.data.items, state.list);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
