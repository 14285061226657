import api from "../../shared/api";
export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, result) {
      let items = [];

      if (result && result.data && result.data.items) {
        items = result.data.items;
      }

      state.list = items;
    },
  },
  actions: {
    update({ state }, data) {
      api.post(
        "cargo-insurances/" + data.id,
        data.form,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "cargo-insurances",
        (result) => {
          commit("updateList", result);
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      // let result = {
      //   data: {
      //     item: {
      //       id: 1,
      //       code: "brinks",
      //       name: "Brinks",
      //       config: {},
      //     },
      //   },
      // };
      // if (data && data.onSuccess) data.onSuccess(result);

      api.get(
        "cargo-insurances/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "cargo-insurances/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
  },
};
