export default {
  new(item, source, template = null) {
    let conditions = [];
    let dataConditions = item.conditions;

    if (dataConditions) {
      if (Array.isArray(dataConditions) && !template) {
        for (let i = 0; i < dataConditions.length; i++) {
          // Member itself in array
          const member = dataConditions[i];

          // Check if code whether exists
          const conditionIndex = conditions.findIndex(
            (condition) => condition.code === member.code
          );

          // Get Element From Source By Index
          const sourceIndex = source.findIndex(
            (source) => source.code === member.code
          );

          // Data of the founded source by index
          let sourceData =
            conditionIndex !== -1
              ? conditions[conditionIndex]
              : helper.clone(source[sourceIndex]); // Clean the reactivity from source -- Source is a fixed array

          this.setSourceData(sourceData, member);

          if (conditionIndex < 0) {
            conditions.push(sourceData);
          }
        }
      } else {
        // Set Conditions
        let conditionData = source;
        for (let i = 0; i < dataConditions.length; i++) {
          this.setSourceData(conditionData, dataConditions[i]);
        }

        // Set result data
        helper.copy(item.operations[0], conditionData.result);

        // Set v-select data
        if (conditionData.result && conditionData.result.type_options) {
          const resultType = helper.clone(conditionData.result.type);
          conditionData.result.type = conditionData.result.type_options.find(
            (item) => item.id === resultType
          );
        }

        // conditionData.result = item.operations[0];
        conditions = [conditionData];
      }
    }
    return conditions;
  },
  result(data, resource) {
    if (data && data.length) {
      const resultData = data[0]; // api response
      const discountTypeIndex = resource.discountTypes.findIndex(
        (type) => type.id === resultData.type
      );
      const optionIndex = resource.options.findIndex(
        (option) => option.id === resultData.code
      );

      let result = helper.clone(resource);
      result.value = resource.options[optionIndex];
      result.discount.value = resource.discountTypes[discountTypeIndex];

      if (resultData.hasOwnProperty("products")) {
        result.discount.products = resultData.products;
      }

      if (resultData.hasOwnProperty("value")) {
        result.discount.amount = resultData.value;
      }

      return result;
    } else {
      return resource;
    }
  },
  setSourceData(sourceData, member) {
    if (sourceData && sourceData.attributes) {
      sourceData.attributes.forEach((attr) => {
        if (attr.name === member.attribute) {
          const index = this.operators.findIndex(
            (o) => o.id == member.operator
          );
          if (index >= 0) {
            attr.operator = this.operators[index];
          } else {
            attr.operator = member.operator;
          }
          if (attr.name == "gender") {
            let genderObj = {
              id: member.value,
              name: member.value == "E" ? "Erkek" : "Kadın",
            };
            attr.value = genderObj;
          } else {
            attr.value = member.value;
          }
          attr.active = true;
        }
      });
    }
  },
  operators: [
    {
      id: "equal",
      name: "Eşittir",
    },
    {
      id: "many",
      name: "Büyüktür",
    },
    {
      id: "less",
      name: "Küçüktür",
    },
    {
      id: "less_or_equal",
      name: "Küçük veya Eşittir",
    },
    {
      id: "many_or_equal",
      name: "Büyük veya Eşittir",
    },
  ],
};
