import api from "../../shared/api";
import requestPos from "../../models/request/pos";
import responsePos from "../../models/response/pos";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    setList(state, result) {
      const items = result && result.data ? result.data.items : [];

      if (items && items.length) {
        state.list = items.map((item) => {
          item.logo = item.logo;

          item.bank_name = item.payment_bank ? item.payment_bank.name : null;

          return item;
        });
      }

      state.list = items;
    },
  },
  actions: {
    update({}, data) {
      api.put(
        "payment-pos/" + data.id,
        requestPos.new(data.form),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, rootState }, data) {
      commit("setList");
      api.get(
        "all/payment-pos",
        (result) => {
          result.config = rootState.session.config;
          commit("setList", result);
          if (data && data.onSuccess) data.onSuccess(items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit }, data) {
      api.get(
        "payment-pos/" + data.id,
        (result) => {
          const resultData = responsePos.new(result.data.item);
          if (data && data.onSuccess) data.onSuccess(resultData);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "payment-pos/isActive/" + requestData.id,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    isDefault({}, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          "payment-pos/isDefault/" + requestData.id,
          {
            is_default: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    fetchOption({}, data) {
      api.get(
        data.route,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
