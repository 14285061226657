import { cart } from "./campaign/cart";
const __TYPE_OPTIONS__ = [
  {
    id: "free",
    name: "bedava",
  },
  {
    id: "discount",
    name: "indirimli",
  },
];
const __DEFAULT_TYPE_OPTION__ = {
  id: "free",
  name: "bedava",
};
export default {
  wizard: {
    source: [
      {
        code: "product",
        title: "Ürün",
        filterList: true,
        icon: "fas fa-cube",
        attributes: [
          {
            active: true,
            name: "id",
            title: "Ürünler",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: [],
            options: [],
            route: null, //"campaign/condition/products",
          },
          {
            name: "quantity",
            title: "Ürün Stok Miktarı",
            operator: {
              id: "equal",
              name: "Eşittir",
            },
            operators: [
              "equal",
              "many",
              "less",
              "less_or_equal",
              "many_or_equal",
            ],
            type: "input",
            multiple: false,
            value: null,
            options: [],
            route: null,
          },
        ],
      },
      {
        code: "member",
        title: "Üye",
        filterList: true,
        icon: "fas fa-user",
        attributes: [
          {
            name: "id",
            title: "Üyeler",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search: true,
            route: "campaign/condition/members",
          },
          {
            name: "email",
            title: "Üye E-Posta Adresi",
            operator: null,
            operators: [],
            type: "input",
            multiple: false,
            value: null,
            options: [],
            route: null,
          },
          {
            name: "gender",
            title: "Üye Cinsiyeti",
            operator: null,
            operators: [],
            type: "select",
            value: null,
            multiple: false,
            options: [
              {
                id: "E",
                name: "Erkek",
              },
              {
                id: "K",
                name: "Kadın",
              },
            ],
            route: null,
          },
          {
            name: "birth",
            title: "Doğum Tarihi",
            operator: null,
            operators: [],
            type: "input",
            multiple: false,
            value: null,
            options: [],
            route: null,
          },
          {
            name: "member_group_id",
            title: "Üye Grubu",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/member-groups",
          },
          {
            name: "country",
            title: "Teslimat Ülkeleri",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/countries",
          },
          {
            name: "city",
            title: "Teslimat Şehirleri",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/cities/{id}",
          },
          {
            name: "district",
            title: "Teslimat İlçeleri",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/districts/{id}",
          },
          {
            name: "neighborhood",
            title: "Teslimat Mahalleleri",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/neighborhoods/{id}",
          },
        ],
      },
      {
        code: "brand",
        title: "Marka",
        filterList: true,
        icon: "far fa-copyright",
        attributes: [
          {
            name: "id",
            title: "Markalar",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search: true,
            route: "search/brands",
          },
          {
            name: "model",
            title: "Modeller",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "campaign/condition/models/{id}",
          },
        ],
      },
      {
        code: "category",
        title: "Kategori",
        filterList: false,
        icon: "fas fa-stream",
        attributes: [
          {
            active: true,
            name: "id",
            title: "Kategoriler",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search: true,
            route: "search/categories", //"campaign/condition/categories",
          },
        ],
      },
      // {
      //   code: "paymentType",
      //   title: "Ödeme Türü",
      //   filterList: false,
      //   icon: "fas fa-credit-card",
      //   attributes: [
      //     {
      //       active: true,
      //       name: "id",
      //       title: "Ödeme Türü",
      //       operator: null,
      //       operators: [],
      //       type: "select",
      //       multiple: true,
      //       value: null,
      //       options: [],
      //       route: "campaign/condition/payment-types",
      //     },
      //   ],
      // },
      {
        code: "cargoCompany",
        title: "Kargo Firması",
        filterList: false,
        icon: "fas fa-truck",
        attributes: [
          {
            active: true,
            name: "id",
            title: "Kargo Firması",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: "all/cargos",
          },
        ],
      },
      {
        code: "cart",
        title: "Sepet Toplamı",
        filterList: false,
        icon: "fas fa-shopping-cart",
        attributes: [
          {
            active: true,
            name: "total_price",
            title: "Sepet Toplamı",
            operator: null,
            operators: [],
            type: "input",
            multiple: false,
            value: null,
            options: [],
            route: null,
          },
        ],
      },
    ],
    result: {
      code: "operation",
      title: "Kampanya Türü",
      icon: "fas fa-gift",
      filterList: false,
      value: {
        // Default value
        id: "discount",
        name: "İndirim",
      },
      discount: {
        value: null,
        products: [],
        amount: 0,
      },
      discountTypes: [
        {
          id: "price",
          name: "Tutar",
        },
        {
          id: "percent",
          name: "Yüzde",
        },
        {
          id: "free",
          name: "Ücretsiz",
        },
      ],
      options: [
        {
          id: "cargo",
          name: "Kargo İndirimi",
        },
        {
          id: "promotion",
          name: "Seçili Ürünlerde Promosyon",
        },
        {
          id: "discount",
          name: "İndirim",
        },
      ],
    },
  },
  templates: {
    product: [
      {
        template_id: "product-1",
        template_based: "product",
        template_description: "Seçili Ürünler için X adet al Y adet öde",
        attributes: [
          {
            active: true,
            name: "product", // X Kategorisinden
            placeholder: "Seçili Ürünler",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search_route: "search/products",
            after_text: "için",
          },
          {
            active: true,
            name: "quantity", // X adet
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet al",
          },
        ],
        result: {
          code: "quantity",
          value: null,
        },
      },
      {
        template_id: "product-2",
        template_based: "product",
        template_description: "Seçili Ürünlerden alana 2.si X TL",
        attributes: [
          {
            active: true,
            name: "product", // Seçili Ürünlerden
            placeholder: "Seçili Ürünlerden",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            route: null, //"campaign/condition/products",
            search_route: "search/products",
            after_text: "alana 2.si",
          },
        ],
        result: {
          code: "discount",
          type: "price",
          value: null, // %X indirimli
        },
      },
      {
        template_id: "product-3",
        template_based: "product",
        template_description: "A ürününü alana B ürününde %X veya X TL indirim",
        attributes: [
          {
            active: true,
            name: "product", // A Ürünü alana
            placeholder: "A",
            operator: "and",
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/products",
            after_text: "ürününü alana",
          },
        ],
        result: {
          code: "discount",
          product: null, // B ürünü
          value: null, // X TL
          search_route: "search/products",
          options: [],
          type: {
            id: "percent",
            name: "%",
          },
          type_options: [
            {
              id: "price",
              name: "₺",
            },
            {
              id: "percent",
              name: "%",
            },
          ],
        },
      },
      {
        template_id: "product-4",
        template_based: "product",
        template_description: "A ürününden X adet alana %X veya X TL indirim",
        attributes: [
          {
            active: true,
            name: "product", // A ve B ürünlerini alana
            placeholder: "A",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/products",
            after_text: "ürününden",
          },
          {
            active: true,
            name: "quantity", // X TL'lik alışverişe
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet alana",
          },
        ],
        result: {
          code: "discount",
          value: null, // X TL
          type: {
            id: "percent",
            name: "%",
          },
          type_options: [
            {
              id: "price",
              name: "₺",
            },
            {
              id: "percent",
              name: "%",
            },
          ],
        },
      },
      {
        template_id: "product-5",
        template_based: "product",
        template_description:
          "A ve B ürünlerini alana C ürünü indirimli/bedava",
        attributes: [
          {
            active: true,
            name: "product", // A ve B ürünlerini alana
            placeholder: "A ve B",
            operator: "and",
            operators: [],
            type: "select",
            multiple: true,
            max_value: 2,
            value: null,
            options: [],
            search_route: "search/products",
            after_text: "ürünlerini alana",
          },
        ],
        result: {
          code: "discount",
          product: null, // B ürünü
          value: null, // X TL
          search_route: "search/products",
          options: [],
          type: {
            id: "free",
            name: "bedava",
          },
          type_options: __TYPE_OPTIONS__,
        },
      },
    ],
    category: [
      {
        template_id: "category-1", //5
        template_based: "category",
        template_description: "Seçili Kategorilerden X adet ürün al X adet öde",
        attributes: [
          {
            active: true,
            name: "category", // X kategorisinden
            placeholder: "Seçili",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search_route: "search/categories",
            after_text: "kategorilerden",
          },
          {
            active: true,
            name: "quantity", // A tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet ürün al",
          },
        ],
        result: {
          code: "quantity",
          value: null,
        },
      },
      {
        template_id: "category-2",
        template_based: "category",
        template_description:
          "Seçili Kategorilerden X adet ürün alana A ürünü %X / X TL indirimli veya bedava",
        attributes: [
          {
            active: true,
            name: "category", // Seçili Kategorilerden
            placeholder: "Seçili",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search_route: "search/categories",
            after_text: "kategorilerden",
          },
          {
            active: true,
            name: "quantity", // X adet
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet ürün alana",
          },
        ],
        result: {
          code: "discount",
          product: null, // B ürünü
          value: null, // indirimli/bedava
          search_route: "search/products",
          options: [],
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
            {
              id: "free",
              name: "Bedava",
            },
          ],
        },
      },
      {
        template_id: "category-3",
        template_based: "category",
        template_description:
          "Seçili Kategorilerden X marka ürün alana %X indirim",
        attributes: [
          {
            active: true,
            name: "category", // Seçili Kategorilerden
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search_route: "search/categories",
            after_text: "kategorilerden",
          },
          {
            active: true,
            name: "brand", // Y markalı
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/brands",
            after_text: "marka ürün alana",
          },
        ],
        result: {
          code: "discount",
          type: "percent",
          value: null, // %X indirimli
        },
      },
      {
        template_id: "category-4",
        template_based: "category",
        template_description:
          "Seçili Kategorilerden X marka ürün alana A ürünü %X / X TL indirimli veya bedava",
        attributes: [
          {
            active: true,
            name: "category", // Seçili Kategorilerden
            placeholder: "Seçili",
            operator: null,
            operators: [],
            type: "select",
            multiple: true,
            value: null,
            options: [],
            search_route: "search/categories",
            after_text: "kategorilerden",
          },
          {
            active: true,
            name: "brand", // Y markalı
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/brands",
            after_text: "marka ürün alana",
          },
        ],
        result: {
          code: "discount",
          product: null, // B ürünü
          value: null, // indirimli/bedava
          search_route: "search/products",
          options: [],
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
            {
              id: "free",
              name: "Bedava",
            },
          ],
        },
      },
    ],
    brand: [
      {
        template_id: "brand-1",
        template_based: "brand",
        template_description:
          "X markalı X adet ürün alana A ürünü %X / X TL indirimli veya bedava",
        attributes: [
          {
            active: true,
            name: "brand", // X Markalı
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/brands",
            after_text: "markalı",
          },
          {
            active: true,
            name: "quantity", // X adet
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet ürün alana",
          },
        ],
        result: {
          code: "discount",
          product: null, // B ürünü
          value: null, // X TL
          search_route: "search/products",
          options: [],
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
            {
              id: "free",
              name: "Bedava",
            },
          ],
        },
      },
      {
        template_id: "brand-2",
        template_based: "brand",
        template_description:
          "X markalı ürünlerden X TL ve üzeri alışveriş yapana A ürünü %X / X TL indirimli veya bedava",
        attributes: [
          {
            active: true,
            name: "brand", // Y Markalı
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/brands",
            after_text: "markalı ürünlerden",
          },
          {
            active: true,
            name: "price", // X tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "price",
            value: null,
            after_text: "TL ve üzeri alışveriş yapana",
          },
        ],
        result: {
          code: "discount",
          product: null, // A ürünü
          value: null, // X TL
          search_route: "search/products",
          options: [],
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
            {
              id: "free",
              name: "Bedava",
            },
          ],
        },
      },
      {
        template_id: "brand-3",
        template_based: "brand",
        template_description: "X markalı ürünlerden X adet al Y adet öde",
        attributes: [
          {
            active: true,
            name: "brand", // X Kategorisinden
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/brands",
            after_text: "markalı ürünlerden",
          },
          {
            active: true,
            name: "quantity", // X adet
            placeholder: "X",
            operator: null,
            operators: [],
            type: "quantity",
            value: null,
            after_text: "adet al",
          },
        ],
        result: {
          code: "quantity",
          value: null,
        },
      },
    ],
    member: [
      {
        template_id: "member-1",
        template_based: "member",
        template_description:
          "X tarihinden itibaren üye olanlara sepette %X indirim",
        attributes: [
          {
            active: true,
            name: "date",
            placeholder: "X",
            operator: "equal",
            operators: [],
            type: "date",
            value: null,
            after_text: "tarihinden itibaren üye olanlara sepette",
          },
        ],
        result: {
          code: "discount",
          value: null,
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
          ],
        },
      },
      {
        template_id: "member-2",
        template_based: "member",
        template_description:
          "İlk alışverişe özel X TL üzerine %X veya X TL indirim",
        attributes: [
          {
            active: true,
            name: "price", // X tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "price",
            value: null,
            before_text: "İlk alışverişe özel",
            after_text: "TL üzerine",
          },
        ],
        result: {
          code: "discount",
          value: null,
          options: [],
          type: {
            id: "percent",
            name: "Yüzde",
          },
          type_options: [
            {
              id: "price",
              name: "Tutar",
            },
            {
              id: "percent",
              name: "Yüzde",
            },
          ],
        },
      },
      {
        template_id: "member-3",
        template_based: "member",
        template_description: "X grubu üyelere sepette %X indirim",
        attributes: [
          {
            active: true,
            name: "member_group_id", // X tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/member-groups",
            after_text: "grubu üyelere sepette",
          },
        ],
        result: {
          code: "discount",
          value: null,
        },
      },
      {
        template_id: "member-4",
        template_based: "member",
        template_description:
          "X grubu üyelere X TL ve üzeri alışverişlerinde sepette %X indirim",
        attributes: [
          {
            active: true,
            name: "member_group_id", // X tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "select",
            multiple: false,
            value: null,
            options: [],
            search_route: "search/member-groups",
            after_text: "grubu üyelere",
          },
          {
            active: true,
            name: "price", // X tane
            placeholder: "X",
            operator: null,
            operators: [],
            type: "price",
            value: null,
            after_text: "TL ve üzeri alışverişlerinde sepette",
          },
        ],
        result: {
          code: "discount",
          value: null,
        },
      },
    ],
    cart: cart,
  },
};
