import api from "../../shared/api";
import helper from "../../shared/helper";
import imageResponse from "../../models/response/image";
import requestProduct from "../../models/request/product";

export default {
  namespaced: true,
  state: () => ({
    lang: "tr",

    // Product -> Information
    currencies: [],

    // Product -> Information
    stockTypeOptions: [],

    currencySymbol: "₺",

    // Product -> Information
    info: {
      id: null,
      categoryKeyword: "",
      selected: {
        stockType: -1,
        currency: -1,
      },
      name: null,
      slug: null,
      stockCode: null,
      normalPrice: helper.setPrice(0),
      discountedPrice: helper.setPrice(0),

      taxAmount: 0,
      stock: 0,
      categories: [],

      image: {
        files: [],
        filesData: null,
      },
      mobile_image: {
        files: [],
        filesData: null,
      },
      setting: {
        new: false,
        featured: false,
        is_active: true,
        is_store_product: false,
        sell_out_stock: false,
        show_in_merchant_xml: true,
        is_free_cargo: false,
        use_installment: false,
      },
      marketplacePrices: [],
    },

    // Product -> Detail
    brands: [],
    suppliers: [],
    models: [],

    // Product -> Detail
    cargoFirms: [],

    // Product -> Detail
    filteredFeatureList: [],

    // Product -> Detail
    detail: {
      featureKeyword: "",
      selected: {
        brand: -1,
        model: -1,
      },

      mpn: null,
      gtin: null,
      packageBarcode: null,
      parcelCode: null,
      purchasePrice: 0,
      productApiCode: null,
      workmanshipApiCode: null,
      insuranceMultiplier: null,
      milyem: 0,
      gram: 0,
      profit: 0,
      reduction: 0,
      warranty: null,
      preparation_time: null,
      descriptions: [],

      marketplaces: [],
      marketplace_initial: [],

      meta: {
        title: null,
        keywords: null,
        description: null,
        robots: ["index", "follow"],
        canonicalUrl: null,
      },

      features: [],
      crossProducts: [],
      removedCrossProducts: [],
      recommendedProducts: [],
      compatibleProducts: [],
      combineProducts: [],
      brands: [],
      suppliers: [],
      attachment: {
        files: [],
        filesData: null,
      },

      cargoPackage: {
        width: null,
        height: null,
        depth: null,
        weight: null,
        desi: null,
        price: helper.setPrice(0),
      },

      product_type: null,
      product_template: null,
    },

    list: [],

    variantFeatures: [],
    variants: [],
    removedVariants: [],
    availableVariants: [],
    variantMedia: {
      updated: [],
      deleted: [],
    },
  }),
  mutations: {
    setModels(state, result) {
      state.models = result?.data.items || [];
    },

    updateList(state, items) {
      if (items && items.length) {
        items.forEach((product) => {
          product.category = product.category ? product.category.name : null;
          product._quantity = product.quantity + " " + product.quantity_type;

          const productImage = product.image;
          if (typeof productImage == "string") {
            product.image = {
              path: {
                medium: productImage,
              },
            };
          }
        });
      } else {
        items = [];
      }

      state.list = items;
    },

    changeCurrencySymbol(state) {
      let selected = state.info.selected.currency;
      let currencies = state.currencies;

      let index = helper.arrayFind(currencies, "id", selected);
      if (index !== -1) {
        state.currencySymbol = currencies[index].symbol;
      }
    },

    // Fill product states
    setInformation(state, data) {
      if (data) {
        const info = state.info;
        info.id = data.id;
        info.name = data.name;
        info.slug = data.slug;
        info.selected.currency = data.currency_code_id;
        info.selected.stockType = data.quantity_type_id;
        info.stockCode = data.sku_no;
        info.normalPrice = helper.setPrice(data.price);
        info.discountedPrice = helper.setPrice(data.discount_price);
        info.taxAmount = data.tax;
        info.stock = data.quantity;
        info.setting.new = data.is_new_product === 1;
        info.setting.featured = data.is_featured_product === 1;
        info.setting.is_active = data.is_active === 1;
        info.setting.is_store_product = data.is_store_product === 1;
        info.setting.sell_out_stock = data.sell_out_stock === 1;
        info.setting.show_in_merchant_xml = data.show_in_merchant_xml === 1;
        info.setting.is_free_cargo = data.is_free_cargo === 1;
        info.setting.use_installment = data.use_installment === 1;
        info.marketplacePrices = data.marketplace_prices || [];
        helper.copy(data.categories, info.categories);
        helper.copy(imageResponse.clone(data.media), info.image.files);
        helper.copy(imageResponse.clone(data.mobileMedia), info.mobile_image.files);

        const detail = state.detail;
        detail.selected.brand = data.brand_id ? data.brand_id : -1;
        detail.selected.supplier = data.supplier_id ? data.supplier_id : -1;
        detail.selected.model = data.model_id ? data.model_id : -1;
        detail.mpn = data.mpn_no;
        detail.gtin = data.gtin_no;
        detail.packageBarcode = data.package_barcode;
        detail.parcelCode = data.parcel_barcode;
        detail.productApiCode = data.product_api_code;
        detail.workmanshipApiCode = data.workmanship_api_code;
        detail.insuranceMultiplier = data.insurance_multiplier;
        detail.milyem = data.milyem;
        detail.gram = data.gram;
        detail.profit = data.profit;
        detail.reduction = data.reduction;
        detail.purchasePrice = data.purchase_price
          ? helper.setPrice(data.purchase_price)
          : 0;
        detail.warranty = data.warranty_period;
        detail.preparation_time = data.preparation_time;
        detail.descriptions = data.descriptions || [];
        detail.meta.title = data.meta_title;
        detail.meta.keywords = data.meta_keywords;
        detail.meta.description = data.meta_description;
        detail.meta.robots = ["index", "follow"];
        detail.meta.canonicalUrl = data.canonical_url;

        // data.meta_robots
        //   ? data.meta_robots.split(", ")
        //   : [];
        detail.cargoPackage.width = data.cargo_width;
        detail.cargoPackage.height = data.cargo_height;
        detail.cargoPackage.depth = data.cargo_depth;
        detail.cargoPackage.weight = data.cargo_weight;
        detail.cargoPackage.desi = data.cargo_desi;
        detail.cargoPackage.price = helper.setPrice(data.cargo_price);

        detail.product_type = data.product_type_id;
        detail.product_template = data.product_template_id;

        detail.recommendedProducts = data.recommendedProducts;
        detail.compatibleProducts = data.compatibleProducts;
        detail.brands = data.brands;
        detail.suppliers = data.suppliers;

        helper.copy(
          imageResponse.clone(data.attachment),
          detail.attachment.files
        );

        detail.combineProducts = data.combineProducts;
        detail.crossProducts = data.crossProducts.map((cp) => {
          cp.deleted = 0;
          return cp;
        });
        detail.cargoCompanies = data.cargoCompanies;
        detail.features = data.features;
        detail.marketplaces = data.marketplaces || [];
        detail.marketplace_initial = [];

        state.variants = [];
        state.variantFeatures = data.variantFeatures;
        state.variantMedia = {
          updated: [],
          deleted: [],
        };
      }
    },

    clearInformation(state, config) {
      state.info = {
        id: null,

        selected: {
          stockType: config["site.stock_type"] || -1,
          currency: config["site.currency_code"] || -1,
        },

        name: null,
        slug: null,
        normalPrice: helper.setPrice(0),
        discountedPrice: helper.setPrice(0),
        taxAmount: config["site.vat"] || 0,
        stock: 0,
        stockCode: null,
        categories: [],

        image: {
          files: [],
          filesData: null,
        },

        mobile_image: {
          files: [],
          filesData: null,
        },
        
        setting: {
          new: false,
          featured: false,
          is_active: true,
          is_store_product: false,
          sell_out_stock: false,
          show_in_merchant_xml: true,
          is_free_cargo: false,
          use_installment: false,
        },
        marketplacePrices: [],
      };
      state.detail = {
        featureKeyword: "",
        selected: {
          brand: -1,
          model: -1,
        },

        mpn: null,
        gtin: null,
        packageBarcode: null,
        parcelCode: null,
        purchasePrice: helper.setPrice(0),
        productApiCode: null,
        workmanshipApiCode: null,
        insuranceMultiplier: null,
        milyem: 0,
        gram: 0,
        profit: 0,
        reduction: 0,
        warranty: null,
        preparation_time: null,
        descriptions: [],

        meta: {
          title: null,
          keywords: null,
          description: null,
          robots: ["index", "follow"],
          canonicalUrl: null,
        },

        features: [],
        crossProducts: [],
        removedCrossProducts: [],
        recommendedProducts: [],
        compatibleProducts: [],
        combineProducts: [],

        attachment: {
          files: [],
          filesData: null,
        },

        cargoPackage: {
          width: null,
          height: null,
          depth: null,
          weight: null,
          desi: null,
          price: helper.setPrice(0),
        },
        product_type: null,
        product_template: null,

        marketplaces: [],
        marketplace_initial: [],
      };
      state.variants = [];
      state.variantFeatures = [];
      state.availableVariants = [];
      state.removedVariants = [];
      state.variantMedia = {
        updated: [],
        deleted: [],
      };
    },

    clearList(state, type) {
      switch (type) {
        case "product":
          state.filteredProductList = [];
          break;

        default:
          state.filteredFeatureList = [];
          break;
      }
    },
    setVariants(state, data) {
      let result = [];
      if (data) {
        result = data.variants.map((variant) => {
          for (let i = 0; i < variant.features.length; i++) {
            const feature = variant.features[i];
            variant[feature.feature_id] = feature.name;
          }
          const media = imageResponse.clone(variant.media);
          variant.media = {
            files: media,
            filesData: null,
          };
          variant.price = {
            price: variant.price,
            unmask: null,
          };
          variant.discount_price = {
            price: variant.discount_price,
            unmask: null,
          };
          variant.exist = true;
          return variant;
        });
      }
      state.variants = result;
    },
    setAvailableVariants(state, data) {
      state.availableVariants = [];

      if (data) {
        state.availableVariants = data;
      }
    },
  },
  actions: {
    load({ commit, rootState }, data) {
      this.dispatch("productType/getProductTypes");
      this.dispatch("productType/getProductTemplates");
      this.dispatch("product/getCurrencyList");
      this.dispatch("product/getQuantityTypes");
      this.dispatch("product/getBrandList");
      this.dispatch("product/getSupplierList");
      this.dispatch("deliveryTime/getList");
      commit("clearInformation", rootState.session.config);
    },
    create({ state }, data) {
      api.post(
        `products?lang=${state.lang}`,
        requestProduct.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    update({ state }, data) {
      api.put(
        `products/${data.id}?lang=${state.lang}`,
        requestProduct.new(state),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    delete({ state }, data) {
      api.delete(
        "products/" + data.id,
        (result) => {
          if (result && result.data && result.data.status !== "error") {
            helper.arrayRemove(state.list, "id", data.id);
          }
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    restore({}, data) {
      api.restore(
        "products/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    remove({}, data) {
      api.remove(
        "products/" + data.id,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit, state }, data) {
      let routePath = "all/products";
      let queryString = { lang: state.lang, ...data.filter, ...data.table };

      routePath += "?" + helper.makeQueryString(queryString);

      api.get(
        routePath,
        (result) => {
          commit("updateList", result.data.items);
          if (data && data.onSuccess) data.onSuccess(result.data);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getById({ commit, state }, data) {
      api.get(
        `products/${data.id}?lang=${state.lang}`,
        (result) => {
          commit("setInformation", result.data.item);
          commit("setVariants", result.data.item);
          this.dispatch("product/getModelList");
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          setTimeout(() => {
            commit("changeCurrencySymbol");
          }, 1000);
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    resetForm({ commit }) {
      commit("clearForm");
    },
    getCurrencyList({ state, commit }, data) {
      api.get(
        "all/currency-codes",
        (result) => {
          state.currencies = result.data.items;
          commit("changeCurrencySymbol");
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getQuantityTypes({ state }, data) {
      api.get(
        "all/quantity-types",
        (result) => {
          state.stockTypeOptions = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getBrandList({ state }, data) {
      api.get(
        `all/brand-list?lang=${data?.lang ?? state.lang}`,
        (result) => {
          if (result.data.items.length > 0) {
            state.brands = result.data.items.filter((A) => A.types.length <= 0);
          } else {
            state.brands = result.data.items;
          }

          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getSupplierList({ state }, data) {
      api.get(
        `all/supplier-integration?lang=${state.lang}`,
        (result) => {
          state.suppliers = result.data.items;
          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getModelList({ commit, state }, data) {
      const brand = state.detail.selected.brand;
      if (brand != -1) {
        api.get(
          "all/models/" + brand,
          (result) => {
            commit("setModels", result);
            if (data && data.onSuccess) data.onSuccess(result);
          },

          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    search({ commit, state }, data) {
      let dataType = data.type;
      let path = "features";
      let request = {
        query: data.query,
      };

      commit("clearList", dataType);

      api.post(
        "search/" + path,
        request,
        (result) => {
          if (dataType == "product") {
            state.filteredProductList = result.data;
          } else {
            state.filteredFeatureList = result.data.items;
          }

          if (data && data.onSuccess) data.onSuccess(result);
        },

        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    isActive({ commit, state }, data) {
      const requestData = data ? data.form : null;
      if (requestData) {
        api.post(
          `products/isActive/${requestData.id}?lang=${state.lang}`,
          {
            is_active: requestData.is_active,
          },
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    bulkUpdate({}, data) {
      api.post(
        "bulk-update/products",
        data.request,
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
