import posJson from "../../models/json/pos";
export default {
  new(data) {
    let result = {};

    if (data) {
      const posIndex = helper.arrayFind(
        posJson.items,
        "name",
        data.payment_bank.prefix
      );

      const config = data.config;

      if (posIndex >= 0) {
        let pos = posJson.items[posIndex];

        if (pos) {
          result = helper.clone(pos);
          result.rates = this.getRates(data.rates);
          result.bank = data.payment_bank;

          if (data.extra_installment) {
            result.installment = data.extra_installment;
          }

          result.min_installment = data.min_installment;
          result.is_active = data.is_active === 1;
          result.is_test = data.is_test === 1;
          result.is_default = data.is_default === 1;
          result.use_installment = data.use_installment === 1;
          result.min_installment_amount =
            (data.use_installment && data.min_installment_amount) || null;

          if (config && Object.keys(config).length) {
            Object.entries(config).forEach(([key, value]) => {
              let resultElement = pos.elements.findIndex(
                (elem) => elem.name === key
              );
              if (resultElement !== -1) {
                result.elements[resultElement].value = value;
              }
            });
          }
        }
      }
    }

    return result;
  },
  getRates(data) {
    let result = this.defaultRates();

    if (data && data.length) {
      data.forEach((item) => {
        const index = result.findIndex(
          (rd) => rd.installment === item.installment
        );

        if (index >= 0) {
          result[index] = item;
        }
      });
    }

    return result;
  },
  defaultRates() {
    let result = [];
    for (let i = 1; i <= 12; i++) {
      const data = {
        installment: helper.zeroPad(i),
        rate: '-', //0,
      };
      result.push(data);
    }

    return result;
  },
};
