import api from "../../shared/api";
export default {
  actions: {
    getShipmentTemplates({}, data) {
      api.get(
        "n11/shipment-templates",
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
