export default {
  new(data) {
    let result = {};
    result.config = {};
    result.is_active = data.is_active ? 1 : 0;
    result.is_test = data.is_test ? 1 : 0;
    result.is_default = data.is_default ? 1 : 0;

    if (data && data.elements) {
      data.elements.map((element) => {
        result.config[element.name] = element.value;
      });
    }
    
    return result;
  },
};
