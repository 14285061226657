import imageResponse from "../../models/response/image";

export default {
  new(result) {
    this.pictures = result.images
      .map((item) => {
        item.imageData = {
          desktop:
            (item.desktop && item.desktop.path && item.desktop.path.thumb) ||
            null,
          mobile:
            (item.mobile && item.mobile.path && item.desktop.path.thumb) ||
            null,
        };
        item.desktop = {
          files:
            item.desktop && item.desktop.path && item.desktop.path.original
              ? [imageResponse.clone(item.desktop, false)]
              : [],
          filesData: null,
        };
        item.mobile = {
          files:
            item.mobile && item.mobile.path && item.mobile.path.original
              ? [imageResponse.clone(item.mobile, false)]
              : [],
          filesData: null,
        };
        item.newTab = item.newTab === 1;
        item.active = item.active === 1;

        return item;
      })
      .sort((a, b) => a.orderNumber - b.orderNumber);

    this.title = result.title;
    this.show.title = result.show_title === 1;
    this.show.description = result.show_description === 1;
    this.show.buttons = result.show_buttons === 1;
    this.auto = result.auto === 1;
    this.showTime = result.showTime;
    this.categories = result.categories;
    this.brands = result.brands;
    this.changeTime = result.changeTime;
    return this;
  },

  pictures: [],
  title: null,
  imageData: {},
  categories: [],
  brands: [],
  show: {
    title: false,
    description: false,
    buttons: false,
  },
  auto: false,
  showTime: 4000,
  changeTime: 1000,
};
