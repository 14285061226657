import api from "../../shared/api";
import request from "../../models/request/translationSetting";

export default {
  namespaced: true,
  state: () => ({
    list: [],
  }),
  mutations: {
    updateList(state, payload) {
      let items = [];

      if (payload && payload.length) {
        payload.forEach((value) => {
          const data = {};
          data.id = value.id;

          const language = value.text;
          data.var = value.description || language.tr;
          
          // tr,en dil keyleri
          const languages = Object.keys(language);
          if(languages.length) {
            languages.forEach((el) => {
              data[el] = language[el]; // tekli dildeki value çekme işlemi
            });
            data.languages = languages;
          }

          items.push(data);
        });
      }

      state.list = items;
    },
  },
  actions: {
    update({ state }, data) {
      api.post(
        "language-line",
        request.new(state.list),
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    getList({ commit }, data) {
      commit("updateList");
      api.get(
        "language-line",
        (result) => {
          const items =
            result && result.data && result.data.items ? result.data.items : [];
          commit("updateList", items);
          if (data && data.onSuccess) data.onSuccess(items);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
