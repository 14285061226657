import imageResponse from "../../models/response/image";

export default {
  new(item) {
    this.name = item.name;
    this.slug = item.slug;
    this.taxAmount = item.tax;

    this.description = {
      text: item.description,
      show: item.show_description === 1 ? true : false,
      place: item.description_place ? item.description_place : "before",
    };

    this.meta = {
      title: item.meta_title,
      keywords: item.meta_keywords,
      description: item.meta_description,
      robots: ["index", "follow"], //item.meta_robots ? item.meta_robots.split(", ") : [],
      canonicalUrl: null,
    };

    this.is_active = item.is_active === 1 ? true : false;
    this.showcase = item.is_showcase === 1 ? true : false;
    this.showmenu = item.is_showmenu === 1 ? true : false;

    this.image.files = [imageResponse.clone(item.image, false)];
    this.icon.files = [imageResponse.clone(item.icon, false)];
    if (item.category_id) {
      this.parentCategory.push(item.category_id);
    } else {
      this.parentCategory = [];
    }
    if (item.features) {
      item.features.forEach((element) => {
        element.feature_name = element.name;
        element.feature_id = element.id;
      });
      helper.copy(item.features, this.features);
    } else {
      item.features = [];
    }

    this.marketplaces = item.marketplaces;
    this.google_category = item.google_category;
    return this;
  },

  image: {
    files: [],
    filesData: null,
  },
  features: [],
  parentCategory: [],
  icon: {
    files: [],
    filesData: null,
  },
};
