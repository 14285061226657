import api from "../../shared/api";
export default {
  state: () => ({
    period: "today",
    periodDate: null,
    periodFilters: [
      {
        id: "today",
        name: "Bugün",
      },
      {
        id: "yesterday",
        name: "Dün",
      },
      {
        id: "week",
        name: "Bu Hafta",
      },
      {
        id: "month",
        name: "Bu Ay",
      },
      {
        id: "six_month",
        name: "Son 6 Ay",
      },
      {
        id: "lifetime",
        name: "Tüm Zamanlar",
      },
      {
        id: "custom_date",
        name: "<i class='fas fa-calendar-alt'></i>",
      },
    ],
    requestData: [
      {
        name: "newMembers",
        route: "members",
      },
      {
        name: "bulkReport",
        route: "bulk-report",
      },
      {
        name: "totalOrdersPending",
        route: "orders",
        params: {
          status: "pending",
        },
      },
      {
        name: "totalOrders",
        route: "orders",
        params: {
          status: "all",
        },
      },
      {
        name: "totalSalePrice",
        route: "total-sale-price",
      },
      {
        name: "choropleth",
        route: "city-orders",
      },
      {
        name: "cartAverage",
        route: "order-average",
      },
      {
        name: "singleOrderAverage",
        route: "single-order-average",
      },
      {
        name: "bestSellerProducts",
        route: "best-seller-products",
        params: {
          type: "quantity",
        },
      },
      {
        name: "cancelledReturnsQuantity",
        route: "cancelled-returns-quantity",
      },
      {
        name: "cancelledReturnsPrice",
        route: "cancelled-returns-price",
      },
      {
        name: "bestSellerCategories",
        route: "best-seller-categories",
      },
      {
        name: "marketplaceOrders",
        route: "marketplace-orders",
      },
      {
        name: "marketplaceReturns",
        route: "marketplace-returns",
      },
    ],
  }),
  actions: {
    refreshReport({ state }, data) {
      let requestData = state.requestData.find(
        (item) => item.name === data.name
      );

      requestData.params = {
        ...requestData.params,
        ...data.params,
        period: state.periodDate ? state.periodDate : state.period,
      };

      if (requestData) {
        api.post(
          "dashboard-report/" + requestData.route,
          requestData.params || null,
          (result) => {
            if (data && data.onSuccess) data.onSuccess(result);
          },
          (error) => {
            if (data && data.onError) data.onError(error);
          },
          () => {
            if (data && data.onFinish) data.onFinish();
          }
        );
      }
    },
    getFailedOrder({}, data) {
      api.post(
        "dashboard-report/failed-orders-quantity",
        {
          date: data.date
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      )
    }
  },
};
