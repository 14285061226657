import imageRequest from "../../models/request/image";

export default {
  new(state) {
    const info = state.info;
    this.id = info.id;
    this.name = info.name;
    this.slug = info.slug;
    this.currency_code_id = info.selected.currency;
    this.price = info.normalPrice.unmask;
    this.discount_price = info.discountedPrice.unmask;
    this.package_discount_rate = info.discountRate;
    this.tax = info.taxAmount;
    this.quantity = info.stock;
    this.sku_no = info.stockCode;
    this.quantity_type_id = info.selected.stockType;
    this.categories = helper.arrayPluck(info.categories, "id");
    this.media = imageRequest(info.image.files);
    this.packageProducts = helper.arrayPluck(info.products, ["id", "quantity"]);
    this.is_active = info.is_active === true ? 1 : 0;
    this.is_new_product = info.is_new === true ? 1 : 0;
    this.is_featured_product = info.is_featured === true ? 1 : 0;

    // helper.copy(info.image.files, this.media);
    // helper.copy(info.products, this.packageProducts);

    const detail = state.detail;
    if (detail.selected.brand && detail.selected.brand != -1) {
      this.brand_id = detail.selected.brand;
    }
    if (detail.selected.model && detail.selected.model != -1) {
      this.model_id = detail.selected.model;
    }

    this.package_barcode = detail.packageBarcode;
    this.parcel_barcode = detail.parcelCode;
    this.purchase_price = detail.purchasePrice.unmask;
    this.warranty_period = detail.warranty;
    this.cargo_width = detail.cargoPackage.width;
    this.cargo_height = detail.cargoPackage.height;
    this.cargo_depth = detail.cargoPackage.depth;
    this.cargo_weight = detail.cargoPackage.weight;
    this.cargo_desi = detail.cargoPackage.desi;
    this.cargo_price = detail.cargoPackage.price.unmask;
    this.descriptions = detail.descriptions;
    this.meta_title = detail.meta.title;
    this.meta_description = detail.meta.description;
    this.meta_keywords = detail.meta.keywords;
    this.canonical_url = detail.meta.canonicalUrl;

    const activeMarketplaces = detail.marketplaces.filter(
      (item) =>
        item.market &&
        item.market.is_active &&
        (item.selectedCategory || item.category)
    );
    const packageMarketplaces = activeMarketplaces.map((item) => {
      const data = {};
      data.id = item.market.id; // Marketplace id
      data.active = item.active ? 1 : 0; // Active set to integer

      data.attributes = item.category_attributes; // Categories
      data.category_id = item.category ? item.category.id : null; // Panel Category ID

      // Selected Marketplac Category
      data.category_code = item.selectedCategory
        ? item.selectedCategory.id
        : null;
      data.category_name = item.selectedCategory
        ? item.selectedCategory.name
        : null;

      if (item.brand) {
        data.brand_id = item.brand.id;
        data.brand_name = item.brand.name;
      }
      if(item.delivery_type) {
        data.delivery_type = item.delivery_type;
      }
      if(item.delivery_message) {
        data.delivery_message = item.delivery_message;
      }
      if (item.market.name == "n11") {
        data.shipmentTemplate =
          item.shipment_template ||
          (item.shipment_templates && item.shipment_templates.length)
            ? item.shipment_templates[0]
            : null;
        data.n11CatalogId = item.catalog_id || null;
        data.deliveryDay = item.n11_deliveryday || null;
      }

      if (item.market.name == "hepsiburada") {
        data.hepsiburadaSku = item.sku_no;
        data.deliveryDay = item.hepsiburada_deliveryday;
      }

      if (item.market.name == "vodafone") {
        data.deliveryDay = item.vodafone_deliveryday;
      }

      // Category Filter & Map
      if (data.attributes) {
        const filteredCategories = data.attributes.filter(
          (category) => category.value
        );
        data.attributes = filteredCategories.map((cat) => {
          const categoryResult = {};
          categoryResult.code = cat.code;
          categoryResult.value = cat.value ? cat.value.code : null;

          return categoryResult;
        });
      }

      if (item.percent_type !== "-1" && item.price_type !== "-1") {
        data.price_type = item.price_type;
        data.percent_type = item.percent_type;

        if (item.percent_type == "equal") {
          data.percent = 0;
        } else {
          data.percent = item.percent;
        }

        if (data.active) {
          data.listing_price = null;
          data.price = null;
        }
      } else {
        data.price_type = null;
        data.percent_type = null;
        data.percent = null;

        if (data.active) {
          data.listing_price = item.listing_price_unmask;
          data.price = item.price_unmask;
        }
      }

      if (data.active) {
        data.quantity = item.quantity;
      }

      return data;
    });
    if (packageMarketplaces && packageMarketplaces.length) {
      this.marketplaces = packageMarketplaces;
    }
    // this.meta_robots = detail.meta.robots ? detail.meta.robots.join(", ") : "";
    this.features = detail.features;
    this.recommendedProducts = helper.arrayPluck(
      detail.recommendedProducts,
      "id"
    );
    this.compatibleProducts = helper.arrayPluck(
      detail.compatibleProducts,
      "id"
    );
    this.cargoCompanies = [];
    return this;
  },

  id: null,
  name: null,
  slug: null,
  currency_code_id: null,
  price: null,
  discount_price: null,
  package_discount_rate: 0,
  quantity: null,
  quantity_type_id: null,
  categories: [],
  media: [],
  is_active: 1,

  sku_no: null,
  package_barcode: null,
  parcel_barcode: null,
  tax: null,
  purchase_price: null,
  warranty_period: null,

  cargo_width: null,
  cargo_height: null,
  cargo_depth: null,
  cargo_weight: null,
  cargo_desi: null,
  cargo_price: 0,

  descriptions: null,
  meta_title: null,
  meta_description: null,
  meta_keywords: null,
  canonical_url: null,
  // meta_robots: null,
  features: null,
  marketplaces: [],

  packageProducts: [],
  recommendedProducts: [],
  compatibleProducts: [],
  cargoCompanies: [],
};
