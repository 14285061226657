export default {
  locale: {
    // code: "en-US",
    // currency: "USD",

    code: "tr-TR",
    currency: "TRY",

    // code: "ar-SA",
    // currency: "SAR",

    // code: "de-DE",
    // currency: "EUR",

    // code: "ja-JP",
    // currency: "JPY",

    // code: "zh-CN",
    // currency: "CNY",

    // code: "en-US",
    // currency: "USD",
    // digits: 4,
    // dayNames: "true",
    // monthNames: "true",
    // seconds: "true",
  },
  toLowerCase(value) {
    let result = value;

    if (typeof value == "string") {
      result = value.toLocaleLowerCase(this.locale.code);
    }

    return result;
  },
  toUpperCase(value) {
    let result = value;

    if (typeof value == "string") {
      result = value.toLocaleUpperCase(this.locale.code);
    }

    return result;
  },
  localize(value, type, list, iconMode, currencyCode) {
    let result = value;
    if (!value) return result;

    let options = null;
    const code = this.locale.code ? this.locale.code : "tr-TR";
    const currency = this.locale.currency ? this.locale.currency : "TRY";
    const digits = this.locale.digits ? this.locale.digits : 2;
    const dayNames = this.locale.dayNames;
    const monthNames = this.locale.monthNames;
    const seconds = this.locale.seconds;

    switch (type) {
      case "boolean":
        result = value ? true : false;

        if (iconMode) {
          result = value
            ? "fas fa-play-circle fa-lg text-success"
            : "fas fa-stop-circle fa-lg text-danger";
        }
        break;

      case "integer":
        options = {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        };

        result = Math.round(parseFloat(result)).toLocaleString(code, options);
        break;

      case "float":
        options = {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        };

        result = parseFloat(result).toLocaleString(code, options);
        break;

      case "currency":
        options = {
          style: "currency",
          currency: currencyCode ? currencyCode : currency,
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        };

        // result = result.toLocaleString(code, options);
        result = parseFloat(result).toLocaleString(code, options);
        break;

      case "date":
        result = new Date(Date.parse(result));
        options = {
          year: "numeric",
          month: monthNames ? "long" : "numeric",
          day: "numeric",
        };
        if (dayNames) {
          options.weekday = "long";
        }

        result = result.toLocaleDateString(code, options);
        break;

      case "time":
        result = new Date(Date.parse(result));
        options = {
          hour: "2-digit",
          minute: "2-digit",
        };
        if (seconds) {
          options.second = "2-digit";
        }

        result = result.toLocaleTimeString(code, options);
        break;

      case "datetime":
        result = new Date(Date.parse(result));
        options = {
          year: "numeric",
          month: monthNames ? "long" : "numeric",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        if (dayNames) {
          options.weekday = "long";
        }
        if (seconds) {
          options.second = "2-digit";
        }

        result = result.toLocaleDateString(code, options);
        break;

      case "list":
        if (list && list.length) {
          list = list.filter((x) => x.key == value);
          if (list && list.length) {
            result = list[0].value;
          }
        }
        break;

      case "image":
      case "image_upload":
        return;

      default:
        break;
    }

    return result;
  },
  compare(a, b) {
    let result = 0;

    if (localization.toLowerCase(a) > localization.toLowerCase(b)) {
      result = 1;
    } else {
      result = -1;
    }

    return result;
  },
  getMoneyFormat(code, price) {
    return new Intl.NumberFormat(localization.locale.code, {
      style: "currency",
      currency: code ? code.toUpperCase() : localization.locale.currency,
    }).format(price);
  },
};
