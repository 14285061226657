export default {
  new(data) {
    const result = {};

    result.title = data.title;
    result.members = data.members;

    return result;
  },
};
