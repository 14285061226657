import api from "../../shared/api";
import imageRequest from "../../models/request/image";

export default {
  namespaced: true,
  state: () => ({
    fields: {
      product: [
        {
          key: "image",
          value: "Görsel Adresleri (Maks. 5)",
        },
        {
          key: "sku_no",
          value: "Stok Kodu",
        },
        {
          key: "name",
          value: "Ürün Adı",
        },
        {
          key: "category",
          value: "Kategori",
        },
        {
          key: "brand",
          value: "Marka",
        },
        {
          key: "model",
          value: "Model",
        },
        {
          key: "description",
          value: "Ürün Açıklamaları (Maks. 3)",
        },
        {
          key: "quantity",
          value: "Miktar",
        },
        {
          key: "price",
          value: "Satış Fiyatı",
        },
        {
          key: "purchase_price",
          value: "Alış Fiyatı",
        },
        {
          key: "discount_price",
          value: "İndirimli Fiyat",
        },
        {
          key: "currency_code",
          value: "Para Birimi",
        },
        {
          key: "profit",
          value: "Kâr",
        },
        {
          key: "tax",
          value: "KDV",
        },
        {
          key: "package_barcode",
          value: "Paket Barkodu (GTIN)",
        },
        {
          key: "is_active",
          value: "Durum",
        },
        {
          key: "cargo_desi",
          value: "Kargo - Desi",
        },
        {
          key: "cargo_weight",
          value: "Kargo - Ağırlık",
        },
        {
          key: "warranty_period",
          value: "Garanti Süresi (Yıl)",
        },
        {
          key: "variant_price",
          value: "Varyasyon Satış Fiyatı",
        },
        {
          key: "variant_discount_price",
          value: "Varyasyon İndirimli Fiyat",
        },
        {
          key: "supplier",
          value: "Tedarikçi",
        },
        {
          key: "reduction",
          value: "İskonto",
        },
      ],
    },
  }),
  actions: {
    importData({}, data) {
      let routePath = "import/" + data.route;

      if (data.hasOwnProperty("lang")) {
        routePath += `?lang=${data.lang}`;
      }

      api.post(
        routePath,
        { fields: data.fields, file: data.file },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    importEquipmentOrCross({}, data) {
      let routePath = "import-data/equipment-cross";
      api.post(
        routePath,
        {
          file: data.file,
          type: data.type
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    importDataUgurSaat({}, data) {
      let routePath = "import-data";
      api.post(
        routePath,
        {
          ...data.fields,
          file: data.file,
          tr_desktop: data.tr_desktop,
          tr_mobile: data.tr_mobile,
          en_desktop: data.en_desktop,
          en_mobile: data.en_mobile,
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
    importDataSaveImage({}, data) {
      console.log(data)
      let routePath = "import-data/save-image/" + data.dataType;
      api.post(
        routePath,
        {
          media:  imageRequest(data.media.files),
        },
        (result) => {
          if (data && data.onSuccess) data.onSuccess(result);
        },
        (error) => {
          if (data && data.onError) data.onError(error);
        },
        () => {
          if (data && data.onFinish) data.onFinish();
        }
      );
    },
  },
};
