export default {
  new(item) {
    this.name = item.name;
    this.rootElement = item.rootElement
    this.template = JSON.parse(item.template);
    this.template.forEach(element => {
      this.brace[element.key] = element.brace
      this.default[element.key] = element.default
      if(element.data.length > 0) {
        this.attribute[element.key] = element.data
      }
    });
    this.address = item.address;
    this.is_active = item.is_active === 1;
    return this;
  },
  attribute: [],
  brace: [],
  default: []
};
