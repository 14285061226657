// import { __API_URL__ } from "./api-url";
import { __NODE_API_URL__ } from "./node-api-url";

const version = {
  major: 1,
  minor: 46, // Updates & New Features
  bugFixes: 43, // Bug Fixes
};
const __APP_TITLE__ = "Algoritim";
const __APP_DESCRIPTION__ = "...";
const __APP_VERSION__ = `${version.major}.${version.minor}.${version.bugFixes}`;
const __APP_DATE__ = "07.10.2021";
const __APP_COPYRIGHT__ = "Algoritim © 2020";
const __APP_VENDOR_URL__ = "https://www.algoritim.com/";
const __APP_VENDOR_URL_TITLE__ = "algoritim.com";

const __BASE_URL__ = "/";

export default {
  APP_TITLE: __APP_TITLE__,
  APP_DESCRIPTION: __APP_DESCRIPTION__,
  APP_VERSION: __APP_VERSION__,
  APP_DATE: __APP_DATE__,
  APP_COPYRIGHT: __APP_COPYRIGHT__,
  APP_VENDOR_URL: __APP_VENDOR_URL__,
  APP_VENDOR_URL_TITLE: __APP_VENDOR_URL_TITLE__,

  API_URL: process.env.VUE_APP_API_URL,
  NODE_API_URL: __NODE_API_URL__,
  BASE_URL: __BASE_URL__,
};
