import exchangeRate from "./exchange-rate";
import reports from "./reports";

export default {
  namespaced: true,
  modules: {
    exchangeRate,
    reports,
  },
};
