import responseImage from "../../models/response/image";
export default {
  new(data) {
    this.image.files[0] = responseImage.clone(data.image, false);
    this.title = data.title;
    this.description = data.description;
    this.link = data.link;
    this.new_tab = data.new_tab === 1;
    this.is_active = data.is_active === 1;
    return this;
  },
  image: {
    files: [],
  },
  title: null,
  description: null,
  link: null,
  new_tab: false,
  is_active: false,
};
