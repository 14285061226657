export default {
  new(data) {
    const result = {
      items: []
    };

    if (data && data.length) {
      data.forEach((element) => {
        const languages = element.languages;
        const languageData = {};

        if (languages.length) {
          languages.forEach((lang) => {
            languageData[lang] = element[lang];
          });
        }

        const newItem = {};
        newItem.id = element.id;
        newItem.text = JSON.stringify(languageData);

        result.items.push(newItem);
      });
    }

    return result;
  },
};
