import Vue from "vue";
import App from "./App";
import { i18n } from "./locales";
import router from "./router/index";
import store from "./store/index";
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// algoritim custom modules
window.constant = require("./shared/constant/index").default;
window.helper = require("./shared/helper/index").default;
window.localization = require("./shared/localization/index").default;
window.permission = require("./shared/permission/index").default;

// ------- Custom Packages ------- //
import VTooltip from "v-tooltip";
Vue.use(VTooltip);

// SweetAlert2
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
const swalOptions = {
  confirmButtonColor: "#41b882",
  confirmButtonText: "Tamam",
  cancelButtonColor: "#ff7674",
  cancelButtonText: "İptal",
  heightAuto: false,
};
Vue.use(VueSweetalert2, swalOptions);

// Vuelidate
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// CKEditor
import CKEditor from "@ckeditor/ckeditor5-vue2";
Vue.use(CKEditor);

// Toast Notification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
const toastOptions = {
  timeout: 2000,
  position: "bottom-center",
  maxToasts: 5,
};
Vue.use(Toast, toastOptions);

// Print
import Print from "vue-print-nb";
Vue.use(Print);

// Export Excel
import excel from "vue-excel-export";
Vue.use(excel);

// Socket IO
import VueSocketIO from "vue-socket.io";
import SocketIO from "socket.io-client";
import { __NODE_BASE_URL__ } from "./shared/constant/node-api-url";

const stored = localStorage.getItem("session");
if (stored) {
  const parsedStore = JSON.parse(stored);
  if (parsedStore.priceAnalysis && parsedStore.priceAnalysis.token) {
    Vue.use(
      new VueSocketIO({
        debug: process.env.NODE_ENV !== "production",
        connection: SocketIO(__NODE_BASE_URL__, {
          query: {
            token: parsedStore.priceAnalysis.token,
          },
        }),
      })
    );
  }
}
// Socket IO

// Container JS
import Algoritim from "./plugins/index";
Vue.use(Algoritim);

// Lodash
import _ from "lodash";
Vue.use(_);

Vue.config.productionTip = false;

Sentry.init({
  Vue,
  dsn: "https://10f875521f8549acac43dedb09f71a40@sentry.uniqyl.dev/5",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

new Vue({
  router,
  store,
  render: (h) => h(App),
  i18n,
}).$mount("#app");
