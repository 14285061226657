import { email } from "vuelidate/lib/validators";
import helper from "../../shared/helper";

export default {
  new(item, memberType) {
    this.accountNumber = item.current_code;
    this.firstName = item.first_name;
    this.lastName = item.last_name;
    this.gender = item.gender;
    this.birthdate = item.birth;
    this.phoneNumber = item.phone;
    this.gsm = item.gsm;

    this.identifier = item.tcn;
    this.vkn = item.vkn;
    this.notCitizen = !item.is_citizen;
    this.is_open_account = item.is_open_account;

    this.address.name = null;
    this.address.country = null;
    this.address.city = null;
    this.address.district = null;
    this.address.neighborhood = null;
    this.address.postal_code = null;
    this.address.address = null;
    this.address.first_name = null;
    this.address.last_name = null;
    this.address.email = null;
    this.address.phone = null;
    
    this.firm = {
      name: item.firm_name,
      taxOffice: item.tax_office,
      taxNumber: item.vkn,
    };

    this.login = {
      email: item.email,
      password: null,
      passwordConfirmation: null,
    };

    if (item.member_type) {
      this.type = helper.arrayFind(memberType.list, "id", item.member_type.id);
    } else {
      this.type = -1;
    }

    this.group = item.member_group ? item.member_group.id : "-1";

    this.is_active = item.is_active;
    this.notification = {
      email: item.notification_email === 1,
      sms: item.notification_sms === 1,
    };

    this.contacts = item.contacts;
    this.addressList = item.contacts;
    // helper.copy(item.contacts, this.addressList);
    return this;
  },

  accountNumber: null,
  firstName: null,
  lastName: null,
  gender: "E",
  birthdate: null,
  phoneNumber: null,
  gsm: null,
  identifier: null,
  notCitizen: false,
  is_open_account: false,
  is_active: null,
  // Firm Information
  firm: {
    name: null,
    taxOffice: null,
    taxNumber: null,
  },

  // Login credentials
  login: {
    email: null,
    password: null,
    passwordConfirmation: null,
    generatedPassword: null,
  },

  // Member Type & Group
  type: -1,
  group: "-1",

  addressList: [],

  // Address'
  address: {
    id: 0,
    contact_type: null,
    name: null,
    country: null,
    city: null,
    district: null,
    neighborhood: null,
    postal_code: null,
    address: null,
    first_name: null,
    last_name: null,
    email: null,
    phone: null
  },

  // Notification
  notification: {
    email: true,
    sms: false,
  },
};
